import { Suspense, useEffect, useState } from "react";
import { useNavigate, useRoutes } from "react-router-dom";
import "./App.css";
import routes from "./routes";
import { Box, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { validateTokenAndFetchPermissions } from "./store/action/auth";
import { routePathMap } from "./helpers/enums";
function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let permissions = useSelector((state) => state.auth.permissions);
  const [initialRedirect, setInitialRedirect] = useState("");
  useEffect(() => {
    if (permissions) {
      const keys = Object.keys(permissions);
      for (let i = 0; i < keys.length; i++) {
        if (permissions[keys[i]].VIEW) {
          setInitialRedirect(routePathMap[keys[i]]);
          break;
        }
      }
    } else {
      dispatch(validateTokenAndFetchPermissions(navigate));
    }
  }, [permissions]);
  const isAuthenticated = window.localStorage.getItem("token") ? true : false;
  const renderContent = useRoutes(routes(isAuthenticated, initialRedirect));

  return (
    <>
      <Suspense
        fallback={
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              height: "100vh",
              width: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        }
      >
        {renderContent}
      </Suspense>
    </>
  );
}

export default App;
