import {
  GET_INSURER_IDS_FAILURE,
  GET_INSURER_IDS_SUCCESS,
  INSURER_IDS_LOADING,
} from "../action/insurers";

const inititalState = {
  insurerList: [],
  insurerIdList: [],
  addOnsList: [],
};

export default function insurerReducer(state = inititalState, action) {
  switch (action.type) {
    case "GET_INSURER_LIST":
      return {
        ...state,
        insurerList: action.data.insurerList,
        addOnsList: action.data.addOnsList,
      };
    case GET_INSURER_IDS_SUCCESS:
      return {
        ...state,
        insurerIdList: action.payload,
      };
    case GET_INSURER_IDS_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case INSURER_IDS_LOADING:
      return {
        ...state,
        loading: action.data,
      };
    default:
      return state;
  }
}
