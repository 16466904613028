import React, { useState } from "react";
import CustomButton from "../Button/CustomButton/CustomButton";
import VehicleForm from "./VehicleForm";
import { Box, Chip } from "@mui/material";
import { ReactComponent as DoneIcon } from "../../assets/icons/DoneIcon.svg";

const NewQuoteForm = ({
  vehicleState,
  setVehicleState,
  handleSubmit,
  handleToSubmit,
  errors,
  control,
  reset,
  resetField,
  vehicleType,
  setVehicleType,
  previousPolicyFlag,
  setPreviousPolicyFlag,
  claimPolicyFlag,
  setClaimPolicyFlag,
  activeHeader,
  onClose,
}) => {
  const [selectedChip, setSelectedChip] = useState(
    vehicleState === "Vehicle is New" ? "new" : "old",
  );

  const handleChipClick = (type) => {
    reset();

    setSelectedChip(type);
    setVehicleState(type === "new" ? "Vehicle is New" : "Vehicle is Old");
    resetField("rtocode", "");
    resetField("registrationNumber", "");
    resetField("idv", "");
  };

  return (
    <div className="newQuoteRequestFormContainer ">
      <form className="min_height_form" onSubmit={handleSubmit(handleToSubmit)}>
        <Box
          sx={{
            maxHeight: "700px",
            overflowY: "scroll",
            height: "calc(100% - 160px)",
          }}
        >
          <Box
            sx={{
              paddingY: "16px",
              display: "flex",
              gap: "12px",
              marginBottom: "20px",
            }}
          >
            <Chip
              sx={{
                fontSize: "12px",

                border:
                  selectedChip === "new"
                    ? "1px solid #0691FC"
                    : "1px solid #d0d0d0",
                backgroundColor: selectedChip === "new" ? "#E8F3FC" : "#f2f2f2",
                color: selectedChip === "new" ? "#0691FC" : "#000",
                "&:hover": {
                  backgroundColor: "#E8F3FC", // Ensure this color appears on hover
                },
                fontWeight: selectedChip === "new" ? "600" : "400",
              }}
              label="Vehicle is New"
              clickable
              icon={
                selectedChip === "new" ? (
                  <DoneIcon sx={{ color: "#0691FC" }} />
                ) : null
              }
              onClick={() => handleChipClick("new")}
            />
            <Chip
              sx={{
                fontSize: "12px",

                border:
                  selectedChip === "old"
                    ? "1px solid #0691FC"
                    : "1px solid #d0d0d0",
                backgroundColor: selectedChip === "old" ? "#E8F3FC" : "#f2f2f2",
                color: selectedChip === "old" ? "#0691FC" : "#000",
                "&:hover": {
                  backgroundColor: "#E8F3FC", // Ensure this color appears on hover
                },
                fontWeight: selectedChip === "old" ? "600" : "400",
              }}
              label="Vehicle is Old"
              clickable
              icon={
                selectedChip === "old" ? (
                  <DoneIcon sx={{ color: "#0691FC" }} />
                ) : null
              }
              color={selectedChip === "old" ? "primary" : "default"}
              variant={selectedChip === "old" ? "filled" : "outlined"}
              onClick={() => handleChipClick("old")}
            />
          </Box>

          {/* Use the generic vehicleForm component */}
          <VehicleForm
            errors={errors}
            control={control}
            vehicleState={vehicleState}
            setVehicleType={setVehicleType}
            vehicleType={vehicleType}
            previousPolicyFlag={previousPolicyFlag}
            setPreviousPolicyFlag={setPreviousPolicyFlag}
            claimPolicyFlag={claimPolicyFlag}
            setClaimPolicyFlag={setClaimPolicyFlag}
            activeHeader={activeHeader}
            resetField={resetField}
            isNewVehicle={vehicleState}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <CustomButton
            buttonName="CANCEL"
            btnNameClass="cancel_btn_name"
            className="cancel_btn"
            handleClick={() => {
              reset();
              onClose();
            }}
            alignCenter
          />
          <CustomButton
            buttonName="SUBMIT"
            className="submit_btn button"
            type="submit"
            alignCenter
          />
        </Box>
      </form>
    </div>
  );
};

export default NewQuoteForm;
