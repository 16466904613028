import React from "react";
import icon from "../../assets/icons/searchicon.gif";
import { Grid } from "@mui/material";
import Heading from "../../components/generic/Heading/Heading";

const SalesVerifyDocs = () => {
  return (
    <>
      <Heading text="Shared Quotes" type="withborder" />{" "}
      <Grid
        sx={{
          margin: " 30px auto",
          width: "616px",
          height: "246px",
          borderRadius: "16px",
          backgroundColor: "white ",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            flexDirection: "column",
          }}
        >
          <div style={{ marginTop: "30px" }}>
            <span>
              <img
                src={icon}
                alt="Searchicon"
                width={"100px"}
                height={"100px"}
              />
            </span>
          </div>{" "}
          <div style={{ textAlign: "center" }}>
            <span
              style={{
                fontSize: "18px",
                fontWeight: "600",
                lineHeight: "27px",
                color: "black",
              }}
            >
              {" "}
              No Quotes Shared Yet!
            </span>
            <br />
            <span
              style={{
                fontSize: "14px",
                color: "#666666",
                lineHeight: "21px",
                fontWeight: "400",
              }}
            >
              Please relax while we verify the documents
            </span>
          </div>
        </div>
      </Grid>
    </>
  );
};

export default SalesVerifyDocs;
