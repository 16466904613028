import React from "react";
import Heading from "../Heading/Heading";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import { logout } from "../../../store/action/auth";
import { useNavigate } from "react-router-dom";

const Header = ({ title, chip }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    dispatch(logout(navigate));
    setAnchorEl(null);
  };
  const data = useSelector((state) => state.partner?.partnerList);
  const userData = JSON.parse(window.localStorage.getItem("user"));
  const dispatch = useDispatch();
  return (
    <header className="header">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <Heading type={"withborder large"} text={title} />
          {chip && (
            <Chip
              sx={{
                background: "var(--color-white)",
              }}
              avatar={
                <Avatar
                  sx={{
                    background: "var(--color-red)",
                    color: "var(--color-white) !important",
                  }}
                >
                  {data?.data?.length}
                </Avatar>
              }
              label="pending"
              className="withCount"
            />
          )}
        </Stack>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Tooltip title="Account settings">
            <Box
              onClick={handleClick}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                cursor: "pointer",
              }}
            >
              <IconButton
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <Avatar sx={{ width: 32, height: 32 }}>
                  {userData?.name.charAt(0)}
                </Avatar>
              </IconButton>
              <Box>
                <Typography
                  sx={{ fontSize: 12, color: "#4D4D4D", fontWeight: "600" }}
                >
                  {userData?.name}
                </Typography>
                <Typography sx={{ fontSize: 10, color: "#7A7A7A" }}>
                  {userData?.userType}
                </Typography>
              </Box>
              <KeyboardArrowDownIcon
                size="small"
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                sx={{ color: "#4D4D4D" }}
              />
            </Box>
          </Tooltip>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem onClick={handleClose}>
            <Avatar /> Profile
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Avatar /> My account
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </Stack>
    </header>
  );
};

export default Header;
