import React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, Typography } from "@mui/material";

// eslint-disable-next-line no-unused-vars
const AdditionalDocsModal = ({
  data,
  title,
  setOpen,
  setValue,
  placeholder,
}) => {
  // const [val, setVal] = useState("");

  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
      }}
    >
      <Typography
        sx={{
          fontWeight: "600",
          fontSize: 24,
          textAlign: "start",
        }}
      >
        {title}
      </Typography>
      <Box>
        <Select
          IconComponent={ExpandMoreIcon}
          displayEmpty
          multiple
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={(selected) =>
            selected.length === 0 ? <em>{placeholder}</em> : selected.join(", ")
          }
          sx={{
            boxShadow: "none",
            ".MuiOutlinedInput-notchedOutline": { border: 0 },
            height: 44,
            fontSize: 14,
            border: "1px solid #8B8B8B",
            width: "100%",
            background: "#fff",
          }}
        >
          {data?.map((item, idx) => (
            <MenuItem
              sx={{ fontSize: 14 }}
              key={item + "-" + idx}
              value={item}
              placeholder="Select Document Type"
            >
              <Checkbox checked={personName.indexOf(item) > -1} />
              {/* Use the custom renderOption function if provided, else display the item */}
              {item}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button
          onClick={() => {
            setOpen(false);
            setValue(personName);
          }}
        >
          SUBMIT
        </Button>
      </Box>
    </Box>
  );
};

export default AdditionalDocsModal;
