export const IconPercentange = (props) => {
  return (
    <svg
      width={props.width || "16"}
      height={props.height || "18"}
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={props.color || "currentColor"}
        id="Vector"
        d="M2 0H14C15.1046 0 16 0.929829 16 2.07692V15.9231C16 17.0702 15.1046 18 14 18H2C0.895391 18 0 17.0702 0 15.9231V2.07692C0 0.929829 0.895391 0 2 0ZM5 8.30769C6.28869 8.30769 7.33333 7.22287 7.33333 5.88462C7.33333 4.54637 6.28869 3.46154 5 3.46154C3.71131 3.46154 2.66667 4.54637 2.66667 5.88462C2.66667 7.22287 3.71131 8.30769 5 8.30769ZM5 6.92308C4.44777 6.92308 4 6.45808 4 5.88462C4 5.31115 4.44777 4.84615 5 4.84615C5.55223 4.84615 6 5.31115 6 5.88462C6 6.45808 5.55223 6.92308 5 6.92308ZM11 14.5385C12.2887 14.5385 13.3333 13.4536 13.3333 12.1154C13.3333 10.7771 12.2887 9.69231 11 9.69231C9.71132 9.69231 8.66667 10.7771 8.66667 12.1154C8.66667 13.4536 9.71132 14.5385 11 14.5385ZM11 13.1538C10.4478 13.1538 10 12.6889 10 12.1154C10 11.5419 10.4478 11.0769 11 11.0769C11.5522 11.0769 12 11.5419 12 12.1154C12 12.6889 11.5522 13.1538 11 13.1538ZM3.83505 14.9943L13.1684 3.91739C13.4108 3.62965 13.3827 3.19233 13.1056 2.94045C12.8285 2.68871 12.4074 2.71792 12.1648 3.00566L2.83149 14.0826C2.58908 14.3703 2.6172 14.8076 2.89429 15.0595C3.17137 15.3113 3.5925 15.2821 3.83505 14.9943Z"
      />
    </svg>
  );
};
