import axios from "axios";
import { loginURL, getUserPermissionsURL, getSalesPartnersListURL } from "../api";
import { message } from "../../components/generic/SnakeAlert/SnakeAlert";

export const loginUserSuccess = (token) => async (dispatch) => {
  dispatch({ type: "USER_LOGIN_SUCCESS", data: token });
};

// Action for fetching permissions successfully
export const fetchPermissionsSuccess = (permissions) => ({
  type: "FETCH_PERMISSIONS_SUCCESS",
  data: permissions,
});

// Action for fetching partnerIds successfully
export const fetchParterIdsSuccess = (partnerIds) => ({
  type: "FETCH_PARTNER_IDS",
  data: partnerIds,
});

export const UNAUTHORIZED = "UNAUTHORIZED";

export const unauthorizedError = () => ({
  type: UNAUTHORIZED,
});

export const login = (data, navigate) => async (dispatch) => {
  try {
    let response = await axios.post(loginURL, data);
    if (response?.status === 200) {
      const token = response?.data?.data?.accessToken;
      dispatch(loginUserSuccess(token));
      window.localStorage.setItem("user", JSON.stringify(response?.data?.data));
      message.success("You have been logged in successfully.");
      dispatch(fetchUserPermissions(token));
      navigate("/");
      // window.location.reload();
    }
  } catch (error) {
    dispatch({ type: "LOGIN_FAILED", data: "login failed" });
    message.error("Wrong user name or password.");
  }
};

// --employe-state

export const fetchUserPermissions = (token) => async (dispatch) => {
  try {
    const permissionsResponse = await axios.get(getUserPermissionsURL, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (
      permissionsResponse?.status === 200 &&
      permissionsResponse?.data?.success
    ) {
      dispatch(fetchPermissionsSuccess(permissionsResponse?.data?.data?.perms));
    } else {
      message.error("Failed to fetch permissions.");
    }
  } catch (error) {
    message.error("Error fetching permissions.");
    dispatch(unauthorizedError());
  }
};

export const fetchParterIdsList = () => async (dispatch) => {
  try {
    const token = localStorage.getItem("token");
    const permissionsResponse = await axios.get(getSalesPartnersListURL, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (
      permissionsResponse?.status === 200 &&
      permissionsResponse?.data?.success
    ) {
      dispatch(fetchParterIdsSuccess(permissionsResponse?.data?.data?.partnerIds));
    } else {
      message.error("Failed to fetch permissions.");
    }
  } catch (error) {
    message.error("Error fetching permissions.");
    dispatch(unauthorizedError());
  }
};

export const validateTokenAndFetchPermissions =
  (navigate) => async (dispatch) => {
    const token = localStorage.getItem("token");

    if (token) {
      try {
        // Directly fetching permissions if token exists
        await dispatch(fetchUserPermissions(token));
      } catch (error) {
        dispatch(unauthorizedError());
        navigate("/login");
      }
    } else {
      dispatch(unauthorizedError());
      navigate("/login");
    }
  };
export const logout = (navigate) => (dispatch) => {
  dispatch({ type: "LOGOUT_SUCCESSFULL" });
  navigate("/login");
  message.success("You have been logged out successfully.");
  // window.location.reload()
};

export default {
  login,
  logout,
};
