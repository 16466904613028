import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../store/api/axios";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import CustomButton from "../../components/Button/CustomButton/CustomButton";
import FormInput from "../../components/FormInput/FormInput";
import { filteredData } from "../../assets/constant";
import styles from "./QuoteShared.module.css";
import "./style.css";
import { ReactComponent as InfoIcon } from "../../assets/icons/info.svg";

import { declineReasonOptions } from "../../assets/constant";
import { InsurerLogoMap } from "../../helpers/InsurerLogoMap";
import { quoteStatusList } from "../../helpers/enums";
import Heading from "../../components/generic/Heading/Heading";
import { Box, Grid, Typography } from "@mui/material";
import Addons from "../../components/QuoteComponent/Addons";
import { Link } from "react-router-dom";
import NewAutoComplete from "../../components/generic/NewAutoComplete/NewAutoComplete";
import CloseIcon from "@mui/icons-material/Close";
import CreateIcon from "@mui/icons-material/Create";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  salesPostAcceptSingleQuoteSharedURL,
  salesPostDeclineSingleQuoteSharedURL,
  salesPostUpdateSingleQuoteSharedURL,
} from "../../store/api";
import CheckIcon from "@mui/icons-material/Check";
import { message } from "../../components/generic/SnakeAlert/SnakeAlert";
import { getSalesQuoteDetailAPI } from "../../store/action/salesQuote";
import SalesDeclineAllModal from "./SalesDeclineAllModal";
import LottiePlayer from "../../components/Lottie/LottiePlayer";
import Looking from "../../assets/Lottie/looking.json";
const SalesQuoteShared = () => {
  const dispatch = useDispatch();

  const details = useSelector((state) => state.salesQuote.quoteDetails);
  const data = details?.data;

  const {
    offlineQuotes = [],
    quoteState = null,
    isQuoteChangeRequested = false,
    reuploadDocument = [],
    chequeDetails = [],
  } = data || {};

  const isChangeRequestInProcess = offlineQuotes?.some(
    (item) => item.quoteStatus === quoteStatusList.CHANGE_REQUESTED,
  );
  const [offlineQuotesData, setOfflineQuotesData] = useState([]);

  useEffect(() => {
    if (offlineQuotes?.length > 0) {
      let temp = [];
      for (let i = 0; i < offlineQuotes?.length; i++) {
        let obj = { ...offlineQuotes[i] };
        obj.showOptions = false;
        temp.push(obj);
      }
      setOfflineQuotesData(temp);
    }
  }, [offlineQuotes, quoteState, reuploadDocument, chequeDetails]);

  const [openFormIndices, setOpenFormIndices] = useState([]);
  const [acceptQuoteFlag, setAcceptQuoteFlag] = useState(false);
  const [declineQuoteFlag, setDeclineQuoteFlag] = useState(false);
  const [selectedQuote, setSelectedQuote] = useState(null);

  // Function for accept / decline
  const handleToClickQuoteDetails = (index, name) => {
    setOpenFormIndices([index]); // Set only the clicked form to be open
    setAcceptQuoteFlag(name === "changes");
    setDeclineQuoteFlag(name === "decline");

    let itemData = offlineQuotesData[index];
    setValue("addOns", itemData.addOns || []);
    reset();
  };

  const changeQuoteSchema = yup.object().shape({
    idv: yup
      .string()
      .required("IDV is required")
      .min(0, "IDV should be greater than or equal to 0"),
    ncb: yup
      .number("NCB should be a number")
      .transform((value, originalValue) =>
        originalValue.trim() === "" ? null : value,
      )
      .min(0, "NCB should be greater than or equal to 0")
      .max(100, "NCB should be less than or equal to 100")
      .required("NCB is required"),
    discount: yup
      .number("Discount should be a number")
      .transform((value, originalValue) =>
        originalValue.trim() === "" ? null : value,
      )
      .min(0, "Discount should be greater than or equal to 0")
      .max(100, "Discount should be less than or equal to 100")
      .required("Discount is required"),
  });

  const declineQuoteSchema = yup.object().shape({
    declineReason: yup.string().required("Decline reason is required"),
  });

  // eslint-disable-next-line no-unused-vars
  const [openSuccess, setOpensuccess] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(
      acceptQuoteFlag ? changeQuoteSchema : declineQuoteSchema,
    ),
    defaultValues: {
      idv: "",
      ncb: "",
      addOns: "",
      discount: "",
      declineReason: "",
    },
  });

  // Handle to reset
  const handleReset = () => {
    reset();
    setOpenFormIndices([]);
    setAcceptQuoteFlag(false);
    setDeclineQuoteFlag(false);
    setSelectedQuote(null);
  };

  // Handle to submit Accept one quote
  const handleToSubmitAcceptSingleQuote = async (paramData) => {
    try {
      const payload = { quoteRequestId: paramData?.quoteRequestId };
      const response = await axios.post(
        salesPostAcceptSingleQuoteSharedURL,
        payload,
      );
      if (response?.data?.success) {
        message.success(response?.data?.msg);
        dispatch(getSalesQuoteDetailAPI(data.header.ticketId));
        handleReset();
      } else {
        message.error(response?.data?.msg);
      }
    } catch (error) {
      message.error("Accept Quote failed");
    }
  };

  // Handle to submit decline one quote
  const handleToSubmitDeclineSingleQuote = async (paramData) => {
    try {
      const payload = {
        quoteRequestId: selectedQuote?.quoteRequestId,
        ...paramData,
      };

      const response = await axios.post(
        salesPostDeclineSingleQuoteSharedURL,
        payload,
      );

      if (response?.data?.success) {
        message.success(response?.data?.msg);
        // dispatch(fetchPartnerQuoteMainModalData({ axiosGet: axiosGet2, params }));
        dispatch(getSalesQuoteDetailAPI(data.header.ticketId));
      } else {
        message.error(response?.data?.msg);
      }
    } catch (error) {
      message.error("Decline Quote failed");
    }
  };

  // Handle to submit update quote
  const handleToSubmitUpdateQuote = async (paramData) => {
    // to be removed later ------------------
    const falseData = {};
    falseData.declineReason = paramData.declineReason;
    falseData.idv = paramData.idv.replace(/,/g, "");
    falseData.ncb = paramData.ncb;
    falseData.discount = paramData.discount;
    if (paramData.addOns) falseData.addOns = [paramData.addOns];
    if (paramData.remarks) falseData.remarks = paramData.remarks;
    // to be removed later ------------------
    try {
      const payload = {
        quoteRequestId: selectedQuote?.quoteRequestId,
        ...falseData,
      };
      const response = await axios.post(
        salesPostUpdateSingleQuoteSharedURL,
        payload,
      );
      if (response?.data?.success) {
        message.success(response?.msg);
        handleReset();
        dispatch(getSalesQuoteDetailAPI(data.header.ticketId));
      }
    } catch (error) {
      message.error("Change Quote failed");
    }
  };

  const handleOptionsRender = (item, index) => {
    let temp = [...offlineQuotesData];
    temp[index].showOptions = true;
    setOfflineQuotesData(temp);
  };

  const onSubmit = (data) => {
    const finalData = filteredData(data);

    if (declineQuoteFlag && selectedQuote !== null)
      handleToSubmitDeclineSingleQuote(finalData);

    if (acceptQuoteFlag && selectedQuote !== null)
      handleToSubmitUpdateQuote(finalData);
  };

  return (
    <div className="quote_modal_container">
      <div className="quote_request_shared_quote_container">
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Heading text="Shared Quotes" type="withborder" />
          {quoteState === "QUOTE_SHARED" && <SalesDeclineAllModal />}
        </Box>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "12px",
            marginTop: "16px",
          }}
        >
          {offlineQuotesData?.map((i, index) => {
            const isFormOpen = openFormIndices.includes(index);
            return (
              <div
                key={`quote-${index}`}
                style={{
                  display: "flex",
                  borderRadius: "8px",
                  boxShadow: "0px 2px 4px 0px #0000001A",
                  flexDirection: "column",
                  overflow: "hidden",
                }}
                className="quote_card_container"
              >
                <div
                  style={{
                    width: "100%",
                    padding: "12px 16px",
                    // boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.10)",
                  }}
                  className={`quote_card
                    ${i.quoteStatus === quoteStatusList.ACCEPTED || i.quoteStatus === quoteStatusList.ACCEPTED_WITH_INSPECTION ? "quote_accepted_card" : ""}
                                  ${i.quoteStatus === quoteStatusList.DECLINED ? "quote_declined_card" : ""}
                                  ${i.quoteStatus === quoteStatusList.UNAVAILABLE ? "quote_unavailable_card" : ""}
                                  ${i.quoteStatus === quoteStatusList.CHANGE_REQUESTED ? "quote_change_requested_card" : ""}
                                  ${isFormOpen && acceptQuoteFlag ? "is_change_open" : ""}
                                  ${isFormOpen && declineQuoteFlag ? "quote_declined_card" : ""}
                                  `}
                >
                  {i?.quoteStatus === "ACCEPTED" ||
                  i?.quoteStatus === "ACCEPTED_WITH_INSPECTION" ? (
                    <>
                      {openSuccess ? (
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          flexDirection={"row"}
                          justifyContent={"space-between"}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                              gap: 12,
                              marginTop: 4,
                            }}
                          >
                            {i?.insurerId && InsurerLogoMap[i?.insurerId] ? (
                              <img
                                src={InsurerLogoMap[i?.insurerId]?.Logo}
                                alt="insurer"
                              />
                            ) : (
                              <Typography
                                sx={{
                                  fontSize: 12,
                                  color: "#666666",
                                  fontWeight: 500,
                                }}
                              >
                                {i?.insurerName}
                              </Typography>
                            )}

                            <div
                              style={{
                                backgroundColor: "#09CE1D",
                                padding: "4px 10px",
                                borderRadius: "4px",
                                display: "flex",
                                gap: "5px",
                              }}
                            >
                              <h3
                                style={{
                                  color: "#fff",
                                  fontSize: "10px",
                                  fontWeight: 500,
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "4px",
                                }}
                              >
                                <CheckIcon />
                                {i?.quoteStatusName}
                              </h3>
                            </div>
                          </div>
                          <Box
                            display="flex"
                            flexDirection="row"
                            sx={{
                              borderRadius: "8px",
                              height: "46px",
                              width: "max-content",
                              border: "none",
                              color: "#0691fc",
                              textAlign: "center",
                              padding: "12px 8px",
                              alignItems: "center",
                              justifyContent: "center",
                              fontSize: "12px",
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "normal",
                              textTransform: "capitalize",
                              "&:hover": {
                                backgroundColor: "#e8f3fc",
                              },
                            }}
                          >
                            <a
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                              href={i?.quoteUrl}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <RemoveRedEyeIcon fontSize="small" />
                              &nbsp; View Quote
                            </a>
                          </Box>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 12,
                            }}
                          >
                            {i?.insurerId && InsurerLogoMap[i?.insurerId] ? (
                              <img
                                src={InsurerLogoMap[i?.insurerId]?.Logo}
                                alt="insurer"
                              />
                            ) : (
                              <Typography
                                sx={{
                                  fontSize: 12,
                                  color: "#666666",
                                  fontWeight: 500,
                                }}
                              >
                                {i?.insurerName}
                              </Typography>
                            )}
                            <div
                              style={{
                                backgroundColor: "#09CE1D",
                                padding: "4px 10px",
                                // borderRadius: "4px",
                                display: "flex",
                                gap: "5px",
                              }}
                            >
                              <h3
                                style={{
                                  color: "#fff",
                                  fontSize: "10px",
                                  fontWeight: 500,
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "4px",
                                }}
                              >
                                <CheckIcon />
                                {i?.quoteStatusName}
                              </h3>
                            </div>
                          </div>
                          <Box
                            display="flex"
                            flexDirection="row"
                            sx={{
                              borderRadius: "8px",
                              height: "46px",
                              width: "max-content",
                              border: "none",
                              color: "#0691fc",
                              textAlign: "center",
                              padding: "12px 8px",
                              alignItems: "center",
                              justifyContent: "center",
                              fontSize: "12px",
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "normal",
                              textTransform: "capitalize",
                              "&:hover": {
                                backgroundColor: "#e8f3fc",
                              },
                            }}
                          >
                            <a
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                              href={i?.quoteUrl}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <RemoveRedEyeIcon fontSize="small" />
                              &nbsp; View Quote
                            </a>
                          </Box>
                        </Box>
                      )}
                    </>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 12,
                        }}
                      >
                        {i?.insurerId && InsurerLogoMap[i?.insurerId] ? (
                          <img
                            src={InsurerLogoMap[i?.insurerId]?.Logo}
                            alt="insurer"
                          />
                        ) : (
                          <Typography
                            sx={{
                              fontSize: 12,
                              color: "#666666",
                              fontWeight: 500,
                            }}
                          >
                            {i?.insurerName}
                          </Typography>
                        )}
                      </div>
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        gap={2}
                        alignItems={"center"}
                      >
                        {[
                          quoteStatusList.CHANGE_REQUESTED,
                          quoteStatusList.UNAVAILABLE,
                          quoteStatusList.DECLINED,
                        ].includes(i?.quoteStatus) ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                            className={
                              i?.quoteStatus === quoteStatusList.UNAVAILABLE
                                ? "quote_not_found_btn"
                                : i?.quoteStatus === quoteStatusList.DECLINED
                                  ? "quote_card_badge quote_rejected_badge"
                                  : "quote_change_requested_btn"
                            }
                          >
                            <CloseIcon />
                            {i?.quoteStatusName}
                          </div>
                        ) : (
                          <Box className="view_quote_btn">
                            <Link
                              to={i?.quoteUrl}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              {" "}
                              {/* <RemoveRedEyeOutlined /> */}
                              <RemoveRedEyeIcon fontSize="small" />
                              <h2
                                style={{
                                  fontSize: "12px",
                                  fontWeight: 500,
                                }}
                              >
                                View Quote{" "}
                              </h2>
                            </Link>
                          </Box>
                        )}

                        {!isFormOpen &&
                          i?.quoteStatus === quoteStatusList.SHARED && (
                            <button
                              className="option_btn accept_btn"
                              onClick={() => handleToSubmitAcceptSingleQuote(i)}
                              disabled={isChangeRequestInProcess}
                            >
                              {/* <Check size={16} />  */}
                              Accept
                              {i?.inspectionRequired ? " with Inspection" : ""}
                            </button>
                          )}
                        {!isFormOpen &&
                          i?.quoteStatus === quoteStatusList.SHARED && (
                            <>
                              {!i.showOptions ? (
                                <div
                                  className={styles.multiple_options_btn}
                                  onClick={() => handleOptionsRender(i, index)}
                                  role="button"
                                  tabIndex={0}
                                >
                                  {/* <ThreeDotsVertical size={16} /> */}
                                  <MoreVertIcon fontSize="small" />
                                  <div className={styles.options_text}>
                                    Options
                                  </div>
                                </div>
                              ) : (
                                <>
                                  {!isQuoteChangeRequested && (
                                    <button
                                      className="option_btn changes_btn"
                                      onClick={() => {
                                        handleToClickQuoteDetails(
                                          index,
                                          "changes",
                                        );
                                        setSelectedQuote(i);
                                      }}
                                    >
                                      {/* <Edit2 size={16} /> */}
                                      <CreateIcon fontSize="small" />
                                      Changes
                                    </button>
                                  )}
                                  <button
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                    className="option_btn changes_btn"
                                    onClick={() => {
                                      handleToClickQuoteDetails(
                                        index,
                                        "decline",
                                      );
                                      setSelectedQuote(i);
                                    }}
                                    disabled={isChangeRequestInProcess}
                                  >
                                    {/* <X size={16} /> */}
                                    <CloseIcon fontSize="small" />
                                    Decline
                                  </button>
                                </>
                              )}
                            </>
                          )}
                      </Box>
                    </Box>
                  )}
                  {isFormOpen && (
                    <form
                      className="quote_form_container"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      {acceptQuoteFlag && openFormIndices.includes(index) && (
                        <>
                          <p className="heading">Request Changes</p>
                          <Grid
                            className="quote_form row"
                            container
                            spacing={2}
                          >
                            <Grid item xs={6}>
                              <Controller
                                control={control}
                                name={`idv`}
                                render={({ field }) => (
                                  <FormInput
                                    field={field}
                                    inputMode="currency"
                                    maxLength={10}
                                    placeholder="Enter IDV"
                                  />
                                )}
                              />
                              {errors.idv && (
                                <div className="error_class">
                                  {errors.idv.message}
                                </div>
                              )}
                            </Grid>

                            <Grid item xs={6}>
                              <Controller
                                control={control}
                                name={`ncb`}
                                render={({ field }) => (
                                  <FormInput
                                    field={field}
                                    inputMode="numeric"
                                    type="number"
                                    inputProps={{
                                      min: "0",
                                      max: "100",
                                    }}
                                    placeholder="Enter NCB %"
                                  />
                                )}
                              />
                              {errors.ncb && (
                                <div className="error_class">
                                  {errors.ncb.message}
                                </div>
                              )}
                            </Grid>

                            <Grid item xs={6}>
                              <Controller
                                control={control}
                                name={`addOns`}
                                render={({ field }) => {
                                  return (
                                    <NewAutoComplete
                                      {...field}
                                      onChange={(value) => {
                                        field.onChange(value);
                                      }}
                                      placeholder="Select Add Ons"
                                      isSearchable
                                      isMulti
                                      options={
                                        (i.totalAddOns &&
                                          i.totalAddOns.map((item) => ({
                                            label: item,
                                            value: item,
                                          }))) ||
                                        []
                                      }
                                      className="react_select"
                                      value={field.value}
                                    />
                                  );
                                }}
                              />
                              {errors.addOns && (
                                <div className="error_class">
                                  {errors.addOns.message}
                                </div>
                              )}
                            </Grid>

                            <Grid item xs={6}>
                              <Controller
                                control={control}
                                name={`discount`}
                                render={({ field }) => (
                                  <FormInput
                                    field={field}
                                    inputMode="decimal"
                                    type="number"
                                    inputProps={{
                                      step: "0.01",
                                      min: "0",
                                      max: "100",
                                    }}
                                    placeholder="Enter Discount"
                                  />
                                )}
                              />
                              {errors.discount && (
                                <div className="error_class">
                                  {errors.discount.message}
                                </div>
                              )}
                            </Grid>
                          </Grid>
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            mt={2}
                          >
                            <CustomButton
                              buttonName="CANCEL"
                              handleClick={handleReset}
                              alignCenter
                              className="cancel_btn small"
                              btnNameClass="cancel_btn_name"
                            />

                            <div className="change_only_once_text">
                              <InfoIcon
                                id="TooltipExample"
                                width={16}
                                height={16}
                              />
                              You can request changes only once for all quotes
                            </div>
                            <CustomButton
                              className="small"
                              type="submit"
                              alignCenter
                            />
                          </Box>
                        </>
                      )}

                      {declineQuoteFlag && openFormIndices.includes(index) && (
                        <div className="quote_form">
                          <p className="heading">Decline Quote</p>
                          <div className="my-3">
                            <Controller
                              control={control}
                              name={`declineReason`}
                              render={({ field }) => (
                                <NewAutoComplete
                                  {...field}
                                  onChange={(value) => {
                                    field.onChange(value);
                                  }}
                                  placeholder="Select Decline Reason"
                                  isSearchable
                                  isMulti={false}
                                  options={declineReasonOptions}
                                  className="react_select"
                                  value={field.value}
                                />
                              )}
                            />
                            {errors.declineReason && (
                              <div className="error_class">
                                {errors.declineReason.message}
                              </div>
                            )}
                          </div>

                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            mt={2}
                          >
                            <CustomButton
                              buttonName="CANCEL"
                              handleClick={handleReset}
                              alignCenter
                              className="cancel_btn"
                              btnNameClass="cancel_btn_name"
                            />
                            <CustomButton
                              type="submit"
                              alignCenter
                              buttonName="Decline"
                              className="decline_btn"
                              btnNameClass="decline_btn_name"
                            />
                          </Box>
                        </div>
                      )}
                    </form>
                  )}

                  {i?.quoteStatus == quoteStatusList.CHANGE_REQUESTED && (
                    <div
                      style={{
                        width: "100%",
                        borderRadius: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "16px",
                        padding: "40px",
                        marginTop: 16,
                      }}
                    >
                      <LottiePlayer height={126} width={126} image={Looking} />
                      <Typography
                        sx={{
                          fontWeight: "600",
                          textAlign: "center",
                          fontSize: 16,
                          color: "#000",
                        }}
                        variant="h3"
                        textTransform={"capitalize"}
                      >
                        Our Team is working on the requested changes
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "400",
                          textAlign: "center",
                          fontSize: 12,
                          color: "#666666",
                        }}
                      >
                        We will update you shortly on the status
                      </Typography>
                    </div>
                  )}
                </div>
                {i?.addOns?.length > 0 && <Addons addOns={i?.addOns} />}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SalesQuoteShared;
