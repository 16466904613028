import React from "react";
import Chip from "@mui/material/Chip";
import { Box } from "@mui/material";
import { ReactComponent as DoneIcon } from "../../../assets/icons/DoneIcon.svg";

// CustomField component with Material-UI Chips
const CustomField = ({ options, setvalue, value, ismulti }) => {
  // For single select
  const handleSingleChecked = (id) => setvalue([id]);

  // For multi-select
  const handleClick = (id) => {
    if (value?.includes(id)) {
      setvalue(value?.filter((item) => item !== id));
    } else {
      setvalue([...value, id]);
    }
  };

  return (
    <Box sx={{ display: "flex", gap: "16px" }}>
      {options?.map((item, index) => {
        const isSelected = value?.includes(item);

        return (
          <Chip
            key={index}
            label={item}
            clickable
            onClick={() =>
              ismulti ? handleClick(item) : handleSingleChecked(item)
            }
            icon={isSelected ? <DoneIcon /> : null}
            sx={{
              border: isSelected ? "1px solid #0691FC" : "1px solid #d0d0d0",
              backgroundColor: isSelected ? "#E8F3FC" : "#f2f2f2",
              color: isSelected ? "#0691FC" : "#000",
              "& .MuiChip-icon": {
                color: isSelected ? "#0691FC" : "transparent", // DoneIcon color
              },
              "&:hover": {
                backgroundColor: "#E8F3FC", // Hover style
              },
              fontWeight: isSelected ? "600" : "400",
              fontSize: "12px",
            }}
          />
        );
      })}
    </Box>
  );
};

export default CustomField;
