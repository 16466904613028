import { Grid } from "@mui/material";
import React from "react";
import Document from "../../assets/icons/Document.svg";
import Heading from "../../components/generic/Heading/Heading";

const SalesCaseClosed = () => {
  return (
    <>
      <Heading text="Case Closed" type="withborder" />
      <Grid
        sx={{
          margin: " 30px auto",
          width: "616px",
          height: "246px",
          borderRadius: "16px",
          backgroundColor: "white ",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            flexDirection: "column",
          }}
        >
          <div style={{ marginTop: "30px" }}>
            <span>
              <img
                src={Document}
                alt="Document"
                width={"67px"}
                height={"78px"}
              />
            </span>
          </div>{" "}
          <div  style={{ textAlign: "center" }}>
            <span
              style={{
                fontSize: "18px",
                fontWeight: "600",
                lineHeight: "27px",
                color: "black",
              }}
            >
              {" "}
              Case Closed!
            </span>
            <br />
            <span
              style={{
                fontSize: "14px",
                color: "#666666",
                lineHeight: "21px",
                fontWeight: "400",
              }}
            >
              Case Closed! Please Request New Quote
            </span>
          </div>
        </div>
      </Grid>
    </>
  );
};

export default SalesCaseClosed;
