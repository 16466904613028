import React, { useEffect, useState } from "react";
import { Box, Button, Divider, Typography } from "@mui/material";
import Heading from "../../components/generic/Heading/Heading";
import { useDispatch, useSelector } from "react-redux";
import MailIcon from "@mui/icons-material/Mail";
import InfoIcon from "@mui/icons-material/Info";

import { quoteRequestStateList } from "../../helpers/enums";
import LottiePlayer from "../../components/Lottie/LottiePlayer";
import Verified from "../../assets/Lottie/verified3.json";
import Clock from "../../assets/Lottie/clock.json";

import FileSelector from "../../components/generic/FileSelector";
import { message } from "../../components/generic/SnakeAlert/SnakeAlert";
import { salesPostPaymentProofURL } from "../../store/api";
import axios from "../../store/api/axios";
import { getSalesQuoteDetailAPI } from "../../store/action/salesQuote";
const SalesPaymentLinkPending = () => {
  const data = useSelector((state) => state.quote?.quoteDetails);
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [reason, setReason] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  // eslint-disable-next-line
  const [isPaymentDetailsTaken, setIsPaymentDetailsTaken] = useState(false);
  const [paymentProof, setPaymentProof] = useState(null);

  // const handleReason = (selectedReason) => {
  //   setReason(selectedReason);
  // };

  const { premiumAmount, email } = data?.data || {};

  // const handleVerifypayment = (flag) => {
  //   const data1 = {
  //     quoteId: data?.data?.header?.ticketId,
  //     isPaymentDone: flag,
  //   };
  //   dispatch(verifyPayment(data1, setOpenSuccess));
  // };

  // const handleUploadPolicyCopy = (file) => {
  //   const formData = new FormData();
  //   formData.append("quoteId", data?.data?.header?.ticketId);
  //   formData.append("file", file);
  //   dispatch(
  //     uploadPolicyCopy(formData, data?.data?.header?.ticketId, setOpenSuccess),
  //   );
  // };
  const handleToSubmitPayment = async () => {
    if (!paymentProof) return message.error("Please upload payment proof");
    let formData = new FormData();
    formData.append("quoteId", data?.data?.header?.ticketId);
    formData.append("file", paymentProof);

    try {
      const response = await axios.post(salesPostPaymentProofURL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response?.data?.success) {
        message.success(response?.data?.msg);
        // dispatch(fetchPartnerQuoteMainModalData({ axiosGet: axiosGet2, params }));
        dispatch(getSalesQuoteDetailAPI(data?.data?.header?.ticketId));
      } else {
        message.error(response?.data?.msg);
      }
    } catch (error) {
      message.error("Payment submission failed");
    }
  };

  useEffect(() => {
    if (data?.data?.quoteState === "PAYMENT_LINK_SHARED") {
      setOpenSuccess(true);
      setReason(data?.data?.premiumAmount);
    }
    if (data?.data?.quoteState === "PAYMENT_PROOF_TAKEN") {
      setOpenSuccess(true);
      setReason(data?.data?.premiumAmount);
      setIsPaymentDetailsTaken(true);
    }
    if (data?.data?.quoteState === "PAYMENT_DETAILS_APPROVED") {
      setOpenSuccess(true);
      setReason(data?.data?.premiumAmount);
      setIsPaymentDetailsTaken(false);
    }

    if (
      data?.data?.quoteState === quoteRequestStateList.UPLOAD_POLICY_COPY ||
      data?.data?.quoteState === quoteRequestStateList.PAYMENT_LINK_PENDING
    ) {
      setOpenSuccess(true);
    }
  }, [data]);
  return (
    <div
      style={{
        width: "100%",
        // padding: "0 30px 0 0",
      }}
    >
      {openSuccess &&
        data?.data?.quoteState ===
          quoteRequestStateList.PAYMENT_DETAILS_APPROVED && (
          <>
            <Heading text="Payment Details" type="withborder" />
            <div
              style={{
                width: "100%",
                // height: 42,
                borderRadius: 16,
                background: "#00223C",
                padding: "40px",
                marginTop: 12,
                boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.10)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "8px",
                minHeight: "324px",
              }}
            >
              <LottiePlayer height={126} width={126} image={Verified} />
              <Typography
                sx={{
                  fontWeight: "600",
                  textAlign: "center",
                  fontSize: 18,
                  color: "#fff",
                }}
                variant="h3"
              >
                {" "}
                Payment Received Successfully!
              </Typography>
              <Typography
                sx={{
                  fontWeight: "400",
                  textAlign: "center",
                  fontSize: 12,
                  color: "#B3B3B3",
                }}
              >
                {" "}
                Sit and relax while we map your policy.
              </Typography>
            </div>
          </>
        )}
      {openSuccess &&
        data?.data?.quoteState ===
          quoteRequestStateList.PAYMENT_LINK_PENDING && (
          <Box paddingBottom={"80px"}>
            <Heading text="Payment Details" type="withborder" />

            <div
              style={{
                width: "100%",
                // height: 42,
                borderRadius: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "16px",
                background: "#fcefe8",
                padding: "40px 40px 66px 40px",
                marginTop: 16,
                boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.10)",
              }}
            >
              <LottiePlayer height={126} width={126} image={Clock} />
              <Typography
                sx={{
                  fontWeight: "600",
                  textAlign: "center",
                  fontSize: 18,
                  color: "#000",
                }}
                variant="h3"
              >
                {" "}
                Payment Link Is Pending At Our End!
              </Typography>
              <Typography
                sx={{
                  fontWeight: "400",
                  textAlign: "center",
                  fontSize: 12,
                  color: "#666666",
                }}
              >
                {" "}
                Our Team is working on it and will share the payment link with
                you shortly
              </Typography>
            </div>
          </Box>
        )}
      {openSuccess &&
        data?.data?.quoteState ===
          quoteRequestStateList.PAYMENT_LINK_SHARED && (
          <Box mt={3}>
            <Heading text="Payment Details" type="withborder" />

            <Box
              p={3}
              sx={{
                backgroundColor: "#00223C", // Dark blue background color
                borderRadius: "8px", // Rounded corners
                color: "#fff",
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                py={1}
              >
                <Typography fontSize={"12px"}>Amount to be paid</Typography>
                <Typography fontSize={"24px"}>₹ {premiumAmount}</Typography>
              </Box>
              <Divider sx={{ margin: 0, background: "#56758d" }} />

              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                py={1}
                mt={1.5}
              >
                <Typography fontSize={"12px"}>
                  Payment link shared on
                </Typography>
                <Box display="flex" alignItems="center">
                  <MailIcon sx={{ mr: 1 }} />
                  <Typography fontSize={"12px"}>{email}</Typography>
                </Box>
              </Box>

              <Box
                display="flex"
                alignItems="center"
                gap={1}
                py={1}
                mt={1.5}
                backgroundColor={"rgba(248,184,60,.15)"}
                color={"#f8b83c"}
                borderRadius={"8px"}
              >
                <InfoIcon sx={{ fontSize: 14, ml: 1 }} />
                <Typography fontSize={"12px"}>
                  Please make the payment as earliest. The link is valid for 4
                  hours only.
                </Typography>
              </Box>

              <Box
                mt={2}
                backgroundColor={"#013258"}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                borderRadius={"16px"}
                padding={"16px"}
              >
                <Typography fontSize={"16px"}>
                  Have you made the payment?
                </Typography>
                <Typography fontSize={"12px"} py={1} color={"#b3b3b3"}>
                  Please make the payment as earliest. The link is valid for 4
                  hours only.
                </Typography>

                <Box display="flex" alignItems="center" gap={2} mt={1.5}>
                  <FileSelector
                    id="PaymentProof"
                    file={paymentProof}
                    handleFileChange={setPaymentProof}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!paymentProof}
                    // mui disabled color override

                    sx={{
                      height: "34px",
                      "&.Mui-disabled": {
                        backgroundColor: "#b3b3b3",
                        height: "34px",
                        display: "flex",
                        alignItems: "center",
                      },
                    }}
                    onClick={handleToSubmitPayment}
                  >
                    SUBMIT
                  </Button>
                  {/* <CustomButton
                    type="submit"
                    buttonName="SUBMIT"
                    handleClick={handleToSubmitPayment}
                    disabled={!paymentProof}
                  /> */}
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      {openSuccess &&
        data?.data?.quoteState ===
          quoteRequestStateList.PAYMENT_PROOF_TAKEN && (
          <>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              // alignItems={"center"}
            >
              <Heading text="Payment Details" type="withborder" />
              <Box
                sx={{
                  display: "flex",
                  padding: "4px 16px",
                  alignItems: "center",
                  gap: "10px",
                  borderRadius: "27px",
                  background: "#ff9d3b",
                  color: "#fff",
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  height: "26px",
                  margin: "24px 0px 8px",
                }}
              >
                {/* <Check size={15} />  */}
                Validation Pending
              </Box>
            </Box>

            <div
              style={{
                width: "100%",
                // height: 42,
                borderRadius: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "16px",
                background: "#00223c",
                padding: "40px",
                marginTop: 16,
                boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.10)",
              }}
            >
              <LottiePlayer height={126} width={126} image={Clock} />
              <Typography
                sx={{
                  fontWeight: "600",
                  textAlign: "center",
                  fontSize: 18,
                  color: "#fff",
                }}
                variant="h3"
              >
                {" "}
                We are validating your payment
              </Typography>
              <Typography
                sx={{
                  fontWeight: "400",
                  textAlign: "center",
                  fontSize: 12,
                  color: "#b3b3b3",
                }}
              >
                {" "}
                We will update the payment status within 1 hour
              </Typography>
            </div>
          </>
        )}
      {openSuccess &&
        data?.data?.quoteState === quoteRequestStateList.UPLOAD_POLICY_COPY && (
          <>
            <Heading text="Payment Details" type="withborder" />
            <div
              style={{
                width: "100%",
                // height: 42,
                borderRadius: 16,
                background: "#00223C",
                padding: "40px",
                marginTop: 12,
                boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.10)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "8px",
                minHeight: "324px",
              }}
            >
              <LottiePlayer height={126} width={126} image={Verified} />
              <Typography
                sx={{
                  fontWeight: "600",
                  textAlign: "center",
                  fontSize: 18,
                  color: "#fff",
                }}
                variant="h3"
              >
                {" "}
                We will soon upload your policy.
              </Typography>
            </div>
          </>
        )}
    </div>
  );
};

export default SalesPaymentLinkPending;
