import { Navigate, Outlet } from "react-router-dom";
import React, { lazy } from "react";
import Flex from "../components/Flex/Flex";
import SideBar from "../components/generic/SideBar/SideBar";
import AddNewPartner from "../pages/partners/addNewPartner";
import QuoteRequest from "../pages/quoteRequest/quoteRequest";
import VerifyDoc from "../pages/quoteRequest/verifyDoc";
// import RevenueDeals from "../pages/revenueDeals";
import MappingRequests from "../pages/mappingRequests";
import MapPolicyModal from "../components/modals/MapPolicyModal";
import GridPage from "../pages/Grid";
import BookingPage from "../pages/Bookings/index";
import SalesManagerBookings from "../pages/SalesManagerBookings";
import {
  ADD_PARTNER,
  OPS_MAPPING_REQUEST,
  OPS_QUOTE_REQUEST,
  PARTNER_VERIFICATION,
  GRID,
  SALES_PARTNER,
  SALES_MAPPING,
  SALES_BOOKING,
  SALES_QUOTE,
  SALES_DASHBOARD,
  SALES_MANAGER_PRE_ISSUANCE,
  SALES_MANAGER_PARTNERS,
  SALES_MANAGER_BOOKINGS
} from "../helpers/featuresName";
import AccessDenied from "../pages/accessDenied/AccessDenied";
import ProtectedRoute from "./ProtectedRoutes";
import SalesMappingRequest from "../pages/SalesMappingRequest";
import SalesPartners from "../pages/salesPartner";
import SalesQuoteRequest from "../pages/SalesQuoteRequest";
import Dashboard from "../pages/Dashboard";
import SalesQuoteModal from "../pages/SalesQuoteRequest/SalesQuoteModal";
import ManagerDashboard from "../pages/SalesManagerDashboard";
import ManagerPartner from "../pages/SalesManagerPartner";

const Login = lazy(() => import("../components/Login"));
const Partners = lazy(() => import("../pages/partners"));

const MainLayout = () => {
  return <Outlet />;
};

const DashboardLayout = () => {
  return (
    <main className="main">
      <Flex container>
        <SideBar />

        <section className="right-panel">
          <Outlet />
        </section>
      </Flex>
    </main>
  );
};

const routes = (isAuthenticated, initialRedirect) => {
  return [
    {
      path: "/",
      element: isAuthenticated ? (
        <Navigate to={initialRedirect} />
      ) : (
        <MainLayout />
      ),
      children: [
        { path: "/", element: <Navigate to={initialRedirect} /> },
        { path: "/404", element: <h3> 404 Not Found</h3> },
        { path: "/access-denied", element: <AccessDenied /> },
        {
          path: "/login",
          element: !isAuthenticated ? (
            <Login />
          ) : (
            <Navigate to={initialRedirect} />
          ),
        },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "app",
      element: <DashboardLayout />,
      children: [
        {
          path: "partners",
          element: <Outlet />,
          children: [
            {
              path: "verification",
              element: (
                <ProtectedRoute
                  component={Partners}
                  featureName={PARTNER_VERIFICATION}
                />
              ),
            },
            {
              path: "add-new-partner",
              element: (
                <ProtectedRoute
                  component={AddNewPartner}
                  featureName={ADD_PARTNER}
                />
              ),
            },
            { path: "", element: <Navigate to="verification" /> },
          ],
        },
        {
          path: "quote-request",
          element: (
            <ProtectedRoute
              component={QuoteRequest}
              featureName={OPS_QUOTE_REQUEST}
            />
          ),
        },
        {
          path: "quote-modal/:id",
          element: (
            <ProtectedRoute
              component={VerifyDoc}
              featureName={OPS_QUOTE_REQUEST}
            />
          ),
        },
        {
          path: "mapping-requests",
          element: (
            <ProtectedRoute
              component={MappingRequests}
              featureName={OPS_MAPPING_REQUEST}
            />
          ),
        },
        {
          path: "mapping-requests/:id",
          element: (
            <ProtectedRoute
              component={MapPolicyModal}
              featureName={OPS_MAPPING_REQUEST}
            />
          ),
        },
        {
          path: "grid",
          element: <ProtectedRoute component={GridPage} featureName={GRID} />,
        },
        {
          path: "sales/view-partner",
          element: (
            <ProtectedRoute
              component={SalesPartners}
              featureName={SALES_PARTNER}
            />
          ),
        },
        {
          path: "sales/mapping-requests",
          element: (
            <ProtectedRoute
              component={SalesMappingRequest}
              featureName={SALES_MAPPING}
            />
          ),
        },
        {
          path: "sales/bookings",
          element: (
            <ProtectedRoute
              component={BookingPage}
              featureName={SALES_BOOKING}
            />
          ),
        },
        {
          path: "sales/quote-request",
          element: (
            <ProtectedRoute
              component={SalesQuoteRequest}
              featureName={SALES_QUOTE}
            />
          ),
        },
        {
          path: "dashboard",
          element: (
            <ProtectedRoute
              component={Dashboard}
              featureName={SALES_DASHBOARD}
            />
          ),
        },
        {
          path: "sales/quote-modal/:id",
          element: (
            <ProtectedRoute
              component={SalesQuoteModal}
              featureName={SALES_QUOTE}
            />
          ),
        },
        {
          path: "sales/manager/pre-issuance-dashboard",
          element: (
            <ProtectedRoute
              component={ManagerDashboard}
              featureName={SALES_MANAGER_PRE_ISSUANCE}
            />
          ),
        },
        {
          path: "sales/manager/view-partners",
          element: (
            <ProtectedRoute
              component={ManagerPartner}
              featureName={SALES_MANAGER_PARTNERS}
            />
          )
        },
        {
          path: "sales/manager/bookings",
          element: (
            <ProtectedRoute
              component={SalesManagerBookings}
              featureName={SALES_MANAGER_BOOKINGS}
            />
          ),
        },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
  ];
};

export default routes;
