import { Info } from "@mui/icons-material";
import { Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import Heading from "../../components/generic/Heading/Heading";

const SalesAgencyDetials = () => {
  const data = useSelector((state) => state.salesQuote.quoteDetails);
  const inspectionDetails = data.data?.inspectionDetails || [];
  return (
    <div>
      <Typography component={"div"}>
        <Typography
          component={"span"}
          sx={{
            fontSize: "12px",
            lineHeight: "18px",
            fontWeight: "600",
            marginLeft: "10px",
            color: "black",
          }}
        >
          {/* Agency Details */}{" "}
          <Heading text="Agency Details" type="withborder" />
        </Typography>
        <Typography
          sx={{
            margin: "19px 13px",
          }}
        >
          <Typography
            component={"div"}
            sx={{
              width: "100%",
              height: "24px",
              borderRadius: "8px 8px 0px 0px",
              padding: "4px 8px",
              color: "#d79006",
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              backgroundColor: "#FCEFE8",
              gap: "5px",
            }}
          >
            <Info size={12} className="" />
            <Typography
              component={"span"}
              sx={{
                fontSize: "12px",
                fontWeight: "500",
                lineHeight: "14px",
                textAlign: "center",
                color: "#D79006",
              }}
            >
              Pls share the third party inspection details with the customer
            </Typography>
          </Typography>
          <Typography
            component={"div"}
            sx={{
              backgroundColor: "#E8F3FC",
              height: "74px",
              width: "639px",
              //   borderRadius: "16px 16px 0px 0px ",
              display: "flex",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            {inspectionDetails?.map((item, index) => (
              <Typography
                component={"div"}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "584px",
                  gap: "10px",
                  margin: "10px 5px",
                }}
                key={index}
              >
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "12px",
                    lineHeight: "18px",
                    color: "#808080",
                  }}
                >
                  {item?.key === "Agency Mobile Number"
                    ? "Mobile Number"
                    : item?.key}
                </Typography>
                <Typography
                  sx={{
                    color: "#181818",
                    fontSize: "12px",
                    lineHeight: "18px",
                    fontWeight: "400",
                  }}
                >
                  {item?.value || "N/A"}{" "}
                </Typography>
              </Typography>
            ))}
          </Typography>
        </Typography>
      </Typography>
    </div>
  );
};

export default SalesAgencyDetials;
