import { ToggleButton } from "@mui/material";
import React from "react";
import styled from "@mui/material/styles/styled";
const StyledToggleButton = styled(ToggleButton)`
  background-color: var(--color-grey-95) !important;
  color: var(--color-black) !important;
  border-radius: 8px !important;
  border: 1px solid var(--color-grey-95) !important;
  padding: 16px 32px !important;
  &:hover {
    background-color: var(--color-grey-95) !important;
    color: var(--color-black) !important;
  }
  &.Mui-selected {
    background-color: var(--color-primary-light) !important;
    color: var(--color-primary) !important;
    border: 1px solid var(--color-primary) !important;
  }
  &.Mui-selected:hover {
    background-color: var(--color-primary-light) !important;
    color: var(--color-primary) !important;
  }
`;

const CustomToggleButton = ({ value, children, ...rest }) => {
  return (
    <StyledToggleButton value={value} {...rest}>
      {children}
    </StyledToggleButton>
  );
};

export default CustomToggleButton;
