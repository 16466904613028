import React from "react";
import { useSelector } from "react-redux";
import QuoteCardBox from "../../components/QuoteComponent/QuoteCardBox";

const QuoteShared = () => {
  const data = useSelector((state) => state.quote.quoteDetails);
  const quoteState = data?.data?.quoteState;
  return (
    <div
      style={{
        width: "100%",
        padding: "0 30px 0 0",
      }}
    >
      <div style={{ marginTop: "16px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "12px",
          }}
        >
          {data?.data?.offlineQuotes?.map((quoteData, index) => (
            <QuoteCardBox
              quoteData={quoteData}
              quoteState={quoteState}
              key={`req-quote-${index}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default QuoteShared;
