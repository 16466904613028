// ErrorBoundary.js

import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state to indicate an error has occurred
    return { hasError: true, error};
  }

  componentDidCatch(error, errorInfo) {
    // Log the error to an error reporting service
    console.error('Error occurred:', error, errorInfo);
    // You can also perform additional actions, like sending error reports to a server
  }

  render() {
    if (this.state.hasError) {
      // You can render a fallback UI
      return (
        <div className="error-boundary">
          <h1>Something went wrong.</h1>
          <p>Please try again later.</p>
        </div>
      );
    }

    // Render children if there's no error
    return this.props.children;
  }
}

export default ErrorBoundary;
