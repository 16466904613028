import React from "react";
import SuccessModal from "../../components/generic/Modal";
const DocumentReupload = () => {
  return (
    <div>
      <div
        style={{
          width: "100%",
          borderRadius: "16px",
          backgroundColor: "#fff",
          padding: "20px 0 20px 0",
          marginRight: "30px",
          marginTop: "20px",
          height: "65vh",
        }}
      >
        <SuccessModal
          title="Document Re-Upload Request Sent To Partner"
          subTitle="You will be notified once partner uploads the document"
          btnDisabled
        />
      </div>
    </div>
  );
};

export default DocumentReupload;
