import React from "react";
import Heading from "../../components/generic/Heading/Heading";
import LottiePlayer from "../../components/Lottie/LottiePlayer";
import { Typography } from "@mui/material";
import Clock from "../../assets/Lottie/clock.json";

const KycVerification = () => {
  return (
    <>
      <Heading text="Customer KYC Documents" type="withborder" />

      <div
        style={{
          width: "100%",
          // height: 42,
          minHeight: "246px",
          borderRadius: 16,
          display: "flex",
          flexDirection: "column",
          // gap: "16px",
          background: "#fcefe8",
          padding: "40px",
          marginTop: 16,
          boxShadow: "0px 1px 6px 0px rgba(0, 0, 0, 0.25)",
          alignItems: "center",
        }}
      >
        <LottiePlayer height={126} width={126} image={Clock} />
        <Typography
          sx={{
            fontWeight: "600",
            textAlign: "center",
            fontSize: 16,
            color: "#000",
            marginTop: "32px",
          }}
          variant="h3"
        >
          {" "}
          Our Team Is Verifying Your KYC Documents
        </Typography>
        <Typography
          sx={{
            fontWeight: "400",
            textAlign: "center",
            fontSize: 12,
            color: "#666666",
            marginTop: "8px",
            padding: "8px 0",
          }}
        >
          {" "}
          We will update you shortly on the status
        </Typography>
      </div>
    </>
  );
};

export default KycVerification;
