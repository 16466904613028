import {
  GET_MANAGER_LIST_FAILURE,
  GET_MANAGER_LIST_SUCCESS,
  SALES_MANAGER_DATA_LOADING,
} from "../action/salesManagerBooking";

const initialState = {
  salesManagerBookingList: [],
  hierarchyList: [],
  hierarchy: "",
  pagination: { curPage: 1, totalPage: 0 },
  loading: true,
  error: null,
};

export const salesManagerBookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MANAGER_LIST_SUCCESS:
      return {
        ...state,
        salesManagerBookingList: action.payload.salesManagerBookingList || [],
        hierarchyList: action.payload.hierarchyList,
        hierarchy: action.payload.hierarchy,
        pagination: {
          ...state.pagination,
          ...action.payload.pagination,
        },
        loading: false,
        error: null,
      };
    case GET_MANAGER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case SALES_MANAGER_DATA_LOADING:
      return {
        ...state,
        loading: action.data,
      };
    default:
      return state;
  }
};
