import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "./store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import SnackAlert from "./components/generic/SnakeAlert/SnakeAlert";
import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ErrorBoundary>
      <BrowserRouter>
        <SnackAlert />
        <App />
      </BrowserRouter>
    </ErrorBoundary>
  </Provider>,
  // </React.StrictMode>,
);
reportWebVitals();
