const inititalState = {
  quoteList: [],
  quoteDetails: null,
  pagination: {
    curPage: 1,
    totalPage: 0,
  },
  quoteBucket: {
    curBucket: "",
    bucketList: [],
  },
  isLoading: true,
};

export default function quoteReducer(state = inititalState, action) {
  switch (action.type) {
    case "GET_QUOTE_LIST":
      return {
        ...state,
        quoteList: action.data?.quoteList,
        pagination: action.data?.pagination,
        quoteBucket: action.data?.quoteBucket,
        isLoading: false,
      };
    case "GET_QUOTE_DETAILS":
      return {
        ...state,
        quoteDetails: action.data,
      };

    case "QUOTE_LOADING":
      return {
        ...state,
        isLoading: action.data,
      };

    default:
      return state;
  }
}
