import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import RaiseLogo from "../../assets/icons/policyraiselogo.svg";
import RaiseMapping from "../../assets/icons/policymapping.svg";
import { Box, Typography } from "@mui/material";
import SelectPartnerModel from "./SelectPartnerModel";
import { useSelector } from "react-redux";
import { SALES_MAPPING, SALES_QUOTE } from "../../helpers/featuresName";
import QuoteRaiseIcon from "../../assets/icons/QuoteRaiseIcon.svg";

export default function RaiseMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [modalTypeState, setModalTypeState] =
    React.useState("RAISE_NEW_REQUEST");
  const handleOpenModal = (modalType) => {
    setOpenModal(true);
    setModalTypeState(modalType);
    handleCloseMenu();
  };
  const handleCloseModal = () => setOpenModal(false);

  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const permissions = useSelector((state) => state?.auth?.permissions) || {};

  const raiseQuoteRequestPermission = permissions[SALES_QUOTE]?.ADD;
  const raiseMappingRequestPermission = permissions[SALES_MAPPING]?.ADD;

  return (
    <div>
      <Button
        sx={{
          backgroundColor: "#0691FC",
          color: "white",
          width: "220px",
          borderRadius: "8px",
          height: "48px",
          padding: "12px 16px",
          margin: "19px 0px",
          "&:hover": {
            backgroundColor: "#005BB5",
          },
        }}
        id="demo-positioned-button"
        aria-controls={openMenu ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu ? "true" : undefined}
        onClick={handleClick}
      >
        <Typography
          component={"div"}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textTransform: "none",
            gap: "5px",
          }}
        >
          <Typography sx={{ width: "24px", height: "24px" }}>
            <img src={RaiseLogo} alt="raise-logo" />
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "21px",
              alignItems: "center",
              color: "white",
            }}
          >
            Raise New Request
          </Typography>
        </Typography>
      </Button>

      {openMenu && (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.3)", // Semi-transparent gray background
            backdropFilter: "blur(0.5px)", // Adds blur effect
            zIndex: (theme) => theme.zIndex.drawer - 1, // Ensure it sits behind the menu
          }}
          onClick={handleCloseMenu} // Close menu when clicking on the backdrop
        />
      )}

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer,
          top: "50px",
          "& .MuiPaper-root": {
            borderRadius: "10px",
          },
        }}
      >
        {raiseQuoteRequestPermission ? (
          <MenuItem
            sx={{
              width: "216px",
              borderRadius: "13px",
              padding: "8px",
              fontSize: "14px",
              lineHeight: "21px",
              fontWeight: "500",
              color: "black",
              margin: "9px",

              "&:hover": {
                background: "#e8f3fc",
              },
            }}
            // divider
            onClick={() => handleOpenModal("RAISE_NEW_REQUEST")}
          >
            <img
              src={QuoteRaiseIcon}
              alt="raise-logo"
              style={{ width: "24px", height: "24px", margin: "5px" }}
            />
            New Quote Request
          </MenuItem>
        ) : (
          ""
        )}
        {raiseMappingRequestPermission ? (
          <MenuItem
            sx={{
              width: "216px",
              borderRadius: "8px",
              padding: "8px",
              fontSize: "14px",
              lineHeight: "30px",
              fontWeight: "500",
              color: "black",
              margin: "15px 9px",
              "&:hover": {
                background: "#e8f3fc",
              },
            }}
            onClick={() => handleOpenModal("RAISE_POLICY_MAPPING")}
          >
            <img
              src={RaiseMapping}
              alt="raise-logo"
              style={{ width: "24px", height: "24px", margin: "5px" }}
            />
            Raise Policy Mapping
          </MenuItem>
        ) : (
          ""
        )}
        <Typography
          component={"span"}
          sx={{
            border: "1px solid #F2F2F2",
            position: "absolute",
            width: "100%",
            left: "0px",
            top: "73px",
          }}
        ></Typography>
      </Menu>
      <SelectPartnerModel
        openModal={openModal}
        handleClose={handleCloseModal}
        modalType={modalTypeState}
      />
    </div>
  );
}
