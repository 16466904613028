import React from "react";
import VerifyDocument from "../../components/generic/verifyDocument";

const ChequeDetails = ({
  chequeDetail,
  verifyChequeDetails,
  setVerifyChequeDetails,
}) => {
  const chequeMapper = {
    chequeNumber: "Cheque No",
    bankName: "Bank Name",
    issueDate: "Issue Date",
    amount: "Amount",
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          // gap: "16px",
          flexWrap: "wrap",
        }}
      >
        {Object.keys(chequeMapper).map((key) => {
          return (
            <div
              key={`cheque-details-${key}`}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
                width: "20%",
              }}
            >
              <h3 style={{ fontSize: "12px", fontWeight: 400 }}>
                {chequeMapper[key]}
              </h3>
              {chequeDetail[key] && (
                <p style={{ fontSize: "12px", fontWeight: 400 }}>
                  {chequeDetail[key]}
                </p>
              )}
            </div>
          );
        })}
        <div
          key={`cheque-details-url`}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
          }}
        >
          <h3 style={{ fontSize: "12px", fontWeight: 400 }}>Cheque</h3>
          <a href={chequeDetail?.chequeUrl} target="_blank" rel="noreferrer">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                flexDirection: "row",
              }}
            >
              <img src="/eyeIcon.svg" alt="view" target="_blank" />
              <h2
                style={{
                  color: "#0691FC",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                View Doc{" "}
              </h2>
            </div>
          </a>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          width: "fit-content",
          alignSelf: "end",
        }}
      >
        <VerifyDocument
          isCheque={true}
          documentType={chequeDetail.chequeNumber}
          key={`cheque-details-verify`}
          verifyDocuments={verifyChequeDetails}
          setVerifyDocuments={setVerifyChequeDetails}
          data={{
            documentType: chequeDetail.chequeNumber,
            documentName: "Cheque",
            documentUrl: chequeDetail.chequeUrl,
            documentStatus: chequeDetail.chequeStatus,
            documentDeclineReasons: chequeDetail.chequeDeclineReasons,
          }}
        />
      </div>
    </>
  );
};

export default ChequeDetails;
