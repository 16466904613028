import React from "react";
import "./PolicyOfferCard.css";
import { InsurerLogoMap } from "../../helpers/InsurerLogoMap";

const PolicyOfferCard = ({ policyDetails, dealType }) => {
  return (
    <div className="policy-offer-card">
      <div className={`favorite-card ${dealType.toLowerCase()}`}>
        <div className="deal-type">
          {/* {dealType.split("_").join(" ").toLowerCase()} */}
          {dealType === "market_deal"
            ? "Normal Deal"
            : dealType.split("_").join(" ").toLowerCase()}
        </div>
        <div className="item insurer">
          {policyDetails.insurerId &&
          InsurerLogoMap[policyDetails.insurerId] ? (
            <img
              src={InsurerLogoMap[policyDetails.insurerId]?.Logo}
              alt={policyDetails.insurerName}
              className="logo_image"
            />
          ) : (
            <div className="logo_name">{policyDetails.insurerName}</div>
          )}
          <span className="insurer-id">Id - {policyDetails.dealId}</span>
        </div>
        <div className="item business-type">
          {policyDetails.businessType.join(", ")}
        </div>
        <div className="item parameters">
          {policyDetails.otherParams && policyDetails.otherParams.length > 0
            ? policyDetails.otherParams.map((param, ind) => (
                <span key={ind} className="parameter">
                  {param}
                </span>
              ))
            : "-"}
        </div>
        <div className="item deal-percentage">
          {policyDetails.dealPercentages?.map((dealPercentage, index) => (
            <div key={index} className="deal-percentage-item">
              {dealPercentage}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PolicyOfferCard;
