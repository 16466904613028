import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import vector from "../../assets/icons/partnerside.svg";
import right from "../../assets/icons/Vector.svg";
import { formatDate } from "../../utils/utils";
import NoDataFound from "../../components/generic/NoDataFound/NoDataFound";

const SalesPartnerTable = ({ partnerList }) => {
  return (
    <>
      <TableContainer
        sx={{
          borderRadius: "10px ",
          height: "calc(100vh - 330px)",
          overflowY: "auto",
        }}
      >
        {partnerList?.length > 0 ? (
          <Table
            sx={{
              Width: "1063px",
            }}
          >
            <TableHead
              sx={{
                backgroundColor: "#F2F2F2",
                position: "sticky",
                top: 0,
                zIndex: 1,
              }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    fontSize: "12px",
                    fontWeight: "500",
                    lineHeight: "18px",
                    color: "#181818",
                  }}
                  align="start"
                >
                  Partner
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "12px",
                    fontWeight: "500",
                    lineHeight: "18px",
                    color: "#181818",
                  }}
                  align="start"
                >
                  PAN Status
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "12px",
                    fontWeight: "500",
                    lineHeight: "18px",
                    color: "#181818",
                  }}
                  align="start"
                >
                  Payment Status
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "12px",
                    fontWeight: "500",
                    lineHeight: "18px",
                    color: "#181818",
                  }}
                  align="start"
                >
                  Last Update
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ backgroundColor: "white", borderRadius: "10px" }}>
              {
                partnerList.map((value, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        width: "25%",
                        fontWeight: "600",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "Black",
                      }}
                    >
                      {value?.partnerName}

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                          gap: "4px",
                          margin: "10px,0px",
                        }}
                      >
                        {value?.hierarchy?.map((level, i) => (
                          <div
                            key={i}
                            style={{
                              display: "flex",
                              justifyContent: "start",
                              gap: "3px",
                              alignItems: "center",
                              fontSize: "12px",
                              fontWeight: "500",
                              color: "#333333",
                              lineHeight: "18px",
                            }}
                          >
                            {level}

                            {value.hierarchy.length > 1 &&
                              i < value.hierarchy.length - 1 && (
                                <img src={vector} alt="vector" />
                              )}
                          </div>
                        ))}
                      </div>
                    </TableCell>

                    <TableCell align="start" sx={{ width: "20%" }}>
                      <Button
                        variant="contained"
                        sx={{
                          borderRadius: "27px",
                          padding: "4px 16px",
                          backgroundColor: value?.panStatus
                            ? "rgba(1, 176, 18, 1)"
                            : "rgba(255, 168, 0, 1)",
                          fontSize: "12px",
                          fontWeight: "400",
                          lineHeight: "18px",
                          textTransform: "none",
                          "&:hover": {
                            backgroundColor: value?.panStatus
                              ? "rgba(1, 176, 18, 1)"
                              : "rgba(255, 168, 0, 1)",
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "12px",
                            fontWeight: "400",
                            gap: "6px",
                          }}
                        >
                          {value?.panStatus && (
                            <img
                              src={right}
                              alt="icon"
                              style={{ marginRight: "2px" }}
                            />
                          )}

                          {value?.panStatus ? "Verified" : "In Review"}
                        </Typography>
                      </Button>
                    </TableCell>

                    <TableCell align="start" sx={{ width: "20%" }}>
                      <Button
                        variant="contained"
                        sx={{
                          borderRadius: "27px",
                          padding: "4px 16px",
                          backgroundColor: value?.paymentStatus
                            ? "rgba(1, 176, 18, 1)"
                            : "rgba(255, 168, 0, 1)",
                          fontSize: "12px",
                          fontWeight: "400",
                          lineHeight: "18px",
                          textTransform: "none",
                          "&:hover": {
                            backgroundColor: value?.paymentStatus
                              ? "rgba(1, 176, 18, 1)"
                              : "rgba(255, 168, 0, 1)",
                          },
                        }}
                      >
                        <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
                          {value?.paymentStatus && (
                            <img
                              src={right}
                              alt="icon"
                              style={{ marginRight: "2px" }}
                            />
                          )}

                          {value?.paymentStatus ? "Verified" : "In Review "}
                        </Typography>
                      </Button>
                    </TableCell>

                    <TableCell
                      align="start"
                      sx={{
                        width: "20%",
                        fontSize: "12px",
                        fontWeight: "500",
                        lineHeight: "18px",
                        color: "#333333",
                      }}
                    >
                      {" "}
                      {value &&
                        formatDate(value.lastUpdated, "DD-MM-YYYY, hh:mm a")}
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        ) : (
          <div>
            <NoDataFound />
          </div>
        )}
      </TableContainer>
    </>
  );
};

export default SalesPartnerTable;