import React from "react";
import "./DealsListing.css";

const DealsListing = ({ children }) => {
  return (
    <div className="deals-list">
      <div className="deals-list-header">
        <div className="header-data">
          <div className="item">Insurer</div>
          <div className="item">Business Type</div>
          <div className="item">Other Parameters</div>
          <div className="item">Deal On Deal Percentage</div>
        </div>
      </div>
      <div className="deals-list-body">{children}</div>
    </div>
  );
};

export default DealsListing;
