import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "flatpickr/dist/flatpickr.css";
import {
  getOptionsDataAPI,
  getPolicyDealsAPI,
  resetOptionsData,
  resetPolicyDeals,
} from "../../store/action/grid";
import Header from "../../components/generic/Header/Header";
import { newQuoteRequestHeader } from "../../helpers/enums";
import CustomButton from "../../components/Button/CustomButton/CustomButton";
import Search from "../../components/generic/Search/Search";
import { Grid, FormControl } from "@mui/material";
import "./index.css";
import PolicyDeals from "./PolicyDeals/PolicyDeals";
import { formatDate, debounce } from "../../utils/utils";
import Loader from "../../components/Loader/Loader";
import GridSwitch from "../../components/generic/GridSwitch";
import NoDataFound from "../../components/generic/NoDataFound/NoDataFound";
import DatePicker from "../../components/generic/DatePicker/DatePicker";
import NewAutoComplete from "../../components/generic/NewAutoComplete/NewAutoComplete";

//---------------------------------------//

const GridPage = () => {
  const initialData = {
    product: "",
    insurerIds: [],
    rtocode: "",
    businessType: "",
    make: "",
    model: "",
    policyType: "",
    fuelType: "",
    vehicleSubclass: "",
    gvw: "",
    dealCategory: "",
  };

  const isFirstLoad = useSelector((state) => state?.grid?.isFirstLoad);
  const [activeHeader, setActiveHeader] = useState(newQuoteRequestHeader[0]);

  const dispatch = useDispatch();
  const policyDeals = useSelector((state) => state?.grid?.policyDeals);
  const responseData = useSelector((state) => state?.grid?.optionsData);
  const isLoading = useSelector((state) => state?.grid?.policyDeals?.isLoading);
  const searchKeys = ["rtoSubstring", "makeSubstring", "modelSubstring"];

  useEffect(() => {
    dispatch(
      getOptionsDataAPI({
        product: activeHeader?.key,
      }),
    );
  }, [dispatch, activeHeader]);

  const insurerOptions =
    responseData?.insurerData?.map((insurer) => ({
      value: insurer.insurerId,
      label: insurer.insurerName,
    })) || [];

  const transformStringArray = (data) => {
    return data?.map((item) => ({
      value: item,
      label: item,
    }));
  };

  const gvwRangeOptions = [
    { label: "1 - 2.5T", value: "1-2.5" },
    { label: "2.5T - 3.5T", value: "2.5-3.5" },
    { label: "3.5T - 7.5T", value: "3.5-7.5" },
    { label: "7.5T - 12T", value: "7.5-12" },
    { label: "12T - 16T", value: "12-16" },
    { label: "16T - 20T", value: "16-20" },
    { label: "20T - 40T", value: "20-40" },
    { label: "40T+", value: "40-99999" },
  ];

  const handleHeaderChange = (item) => {
    setActiveHeader(item);
    if (item.key === "CAR") {
      resetValues({ ...initialData, vehicleSubclass: null, gvwData: null });
    } else {
      resetValues({ ...initialData, fuelType: null, gvwData: null });
    }
    if (item.key === "GCV") {
      resetValues({ ...initialData, gvwData: "", fuelType: null });
    }
  };

  // Assuming resetValues is a function that resets the values in your form
  const resetValues = (data) => {
    for (const key in data) {
      setValue(key, data[key]);
    }
  };

  const policyDealsForm = yup.object().shape({
    rtocode: yup.string().required("RTO is required"),
    make: yup.string().required("Make is required"),
    model: yup.string().required("Model is required"),
    policyType: yup.string().required("Policy Type is required"),
    businessType: yup.string().required("Business Type is required"),
    fuelType:
      activeHeader?.key === "CAR"
        ? yup.string().required("Fuel Type is required")
        : yup.string().nullable(),
    vehicleSubclass:
      activeHeader?.key !== "CAR"
        ? yup.string().required("Vehicle Subclass is required")
        : yup.string().nullable(),
    // array length > 0
    insurerIds: yup
      .array()
      .min(1, "Insurer is required")
      .required("Insurer is required"),
    gvwData:
      activeHeader?.key === "GCV"
        ? yup.string().required("GVW Range is required")
        : yup.string().nullable(),
    date: yup.date().required("Date is required"),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(policyDealsForm),
    defaultValues: {},
  });

  // useEffect(() => {
  //   const formData = getValues();
  //   let searchParams = {};

  //   if (
  //     searchFields.rtoSubstring.length >= 2 &&
  //     searchFields.rtoSubstring !== formData.rtocode
  //   ) {
  //     searchParams.rtoSubstring = searchFields.rtoSubstring;
  //   }
  //   if (searchFields.makeSubstring.length >= 2) {
  //     searchParams.makeSubstring = searchFields.makeSubstring;
  //   }
  //   if (searchFields.modelSubstring.length >= 2) {
  //     if (!formData.model) {
  //       searchParams.modelSubstring = searchFields.modelSubstring;
  //     }
  //   }

  //   if (
  //     searchFields.rtoSubstring === formData.rtocode &&
  //     searchFields.makeSubstring === formData.make &&
  //     searchFields.modelSubstring === formData.model
  //   ) {
  //     searchParams = {};
  //   }

  //   if (
  //     searchParams.rtoSubstring ||
  //     searchParams.makeSubstring ||
  //     searchParams.modelSubstring
  //   ) {
  //     searchParams.product = activeHeader?.key;
  //     // Fetch data based on search params
  //     // dispatch(getOptionsDataAPI(searchParams));

  //     // fetch data based on search params api call
  //     debouncedDispatch(searchParams);
  //   }
  // }, [searchFields, activeHeader]);

  const handleInputChange = (key, inputValue, reason) => {
    if (!searchKeys.includes(key)) return;
    // setSearchFields((prevState) => ({
    //   ...prevState,
    //   [item]: inputValue,
    // }));
    let value = inputValue.trim();
    let makeCall = false;
    const formData = getValues();

    let searchParams = {};

    // if inputType is rtoSubstring and the length of the input value is greater than or equal to 2

    // searchParams.rtoSubstring = inputValue;
    if (key === "rtoSubstring") {
      let optionsForKey = getOptions("rtoData");
      // check if rtoData already has the value then don't make the call
      if (
        optionsForKey &&
        optionsForKey.some((item) =>
          item.label.toLowerCase().includes(value.toLowerCase()),
        )
      ) {
        return;
      }

      if (value.length >= 2) {
        if (reason === "input") {
          searchParams.rtoSubstring = value;
          makeCall = true;
        }
      }
    }

    if (key === "makeSubstring") {
      let optionsForKey = getOptions("makes");
      if (
        reason === "input" &&
        optionsForKey &&
        optionsForKey.some((item) =>
          item.label.toLowerCase().includes(value.toLowerCase()),
        )
      ) {
        return;
      }
      if (value.length > 2) {
        searchParams.makeSubstring = value;
        makeCall = true;
      }
    }

    if (key === "modelSubstring") {
      let optionsForKey = getOptions("models");
      if (
        optionsForKey &&
        optionsForKey.some((item) =>
          item.label.toLowerCase().includes(value.toLowerCase()),
        )
      ) {
        return;
      }
      if (value.length > 2 && reason === "input") {
        searchParams.modelSubstring = value;
        searchParams.make = formData.make;
        makeCall = true;
      }
    }

    // Fetch data based on search params
    if (makeCall) {
      searchParams.product = activeHeader?.key;
      debouncedDispatch(searchParams, key);
    }
  };

  const getOptions = (item) => {
    switch (item) {
      case "insurerData":
        return insurerOptions;

      case "rtoData":
        return responseData.rtoData;

      case "makes":
        return responseData.makes;

      case "models":
        return responseData.models;
      case "gvwRangeOptions":
        return gvwRangeOptions;
      default:
        return transformStringArray(responseData[item]);
    }
  };

  const fixedOptionKeys = [
    {
      key: "vehicleSubclass",
      label: "Vehicle Subclass",
      placeholder: "Vehicle Subclass",
      isMulti: false,
      optionsKey: "vehicleSubclass",
      formKey: "vehicleSubclass",
      isDisplay: activeHeader?.key !== "CAR",
    },
    {
      key: "rtoSubstring",
      label: "RTO",
      placeholder: "Enter RTO",
      isMulti: false,
      optionsKey: "rtoData",
      formKey: "rtocode",
      isDisplay: true,
    },
    {
      key: "businessType",
      label: "Business Type",
      placeholder: "Select Business Type",
      isMulti: false,
      optionsKey: "businessType",
      formKey: "businessType",
      isDisplay: true,
    },
    {
      key: "policyType",
      label: "Policy Type",
      placeholder: "Select Policy Type",
      isMulti: false,
      optionsKey: "policyType",
      formKey: "policyType",
      isDisplay: true,
    },
    {
      key: "fuelType",
      label: "Fuel Type",
      placeholder: "Select Fuel Type",
      isMulti: false,
      optionsKey: "fuelType",
      formKey: "fuelType",
      isDisplay: activeHeader?.key === "CAR",
    },
    {
      key: "gvw",
      label: "GVW",
      placeholder: "GVW Range",
      isMulti: false,
      optionsKey: "gvwRangeOptions",
      formKey: "gvwData",
      isDisplay: activeHeader?.key === "GCV",
    },
    {
      key: "insurerId",
      label: "Insurer",
      placeholder: "Enter Insurer",
      isMulti: true,
      optionsKey: "insurerData",
      formKey: "insurerIds",
      allowSelectAll: true,
      isDisplay: true,
    },
    {
      key: "makeSubstring",
      label: "Make",
      placeholder: "Enter Make",
      isMulti: false,
      optionsKey: "makes",
      formKey: "make",
      isDisplay: true,
    },
    {
      key: "modelSubstring",
      label: "Model",
      placeholder: "Enter Model",
      isMulti: false,
      optionsKey: "models",
      formKey: "model",
      isDisplay: true,
    },
  ];
  const [isChecked, setIsChecked] = useState(false);
  // policy Deals submit--/
  const handlePolicyDealsSubmit = (data) => {
    let params = `product=${activeHeader?.key}`;
    const checkBoxValue = isChecked ? "PAYIN" : "PAYOUT"; // Use the checkbox state

    params += `&dealCategory=${checkBoxValue}`;

    Object.keys(data).forEach((key) => {
      if (data[key]) {
        if (Array.isArray(data[key])) {
          params += `&${key}=${data[key].join(",")}`;
        } else if (key === "date") {
          let formattedDate = formatDate(data[key], "DD-MM-YY");
          params += `&${key}=${formattedDate}`;
        } else {
          if (key === "gvwData") {
            let gvwRange = data[key].split("-");
            params += `&gvwStartRange=${gvwRange[0]}&gvwEndRange=${gvwRange[1]}`;
          } else {
            params += `&${key}=${data[key]}`;
          }
        }
      }
    });

    dispatch(getPolicyDealsAPI(params)); // Dispatch the API call with the constructed params
  };

  const handleSwitchChange = (event) => {
    setIsChecked(event.target.checked);
  };

  // debouncedDispatch the search //
  const debouncedDispatch = useCallback(
    debounce((searchParams, searchKey) => {
      dispatch(getOptionsDataAPI(searchParams, searchKey));
    }, 500),
    [dispatch],
  );

  // -----------------
  // resetoptionsData
  useEffect(() => {
    dispatch(resetOptionsData());
    dispatch(resetPolicyDeals());
  }, []);

  if (
    policyDeals &&
    (policyDeals.declineDeals.length > 0 ||
      policyDeals.specialDeals.length > 0 ||
      policyDeals.marketDeals.length > 0)
  ) {
    let formData = getValues();

    let policyHeaderData = {
      product: activeHeader?.key,
      insurerIds: insurerOptions?.filter((item) =>
        formData.insurerIds?.includes(item.value),
      ),
      rtocode: formData.rtocode,
      make: formData.make,
      model: formData.model,
      date: formatDate(formData.date),
      policyType: formData.policyType,
      businessType: formData.businessType,
      fuelType: formData.fuelType,
      vehicleSubclass: formData.vehicleSubclass,
      gvw: formData.gvwData,
    };

    return (
      <div>
        <Header title="Grid" />
        <PolicyDeals
          policyHeaderData={policyHeaderData}
          policyOffersData={policyDeals}
        />
      </div>
    );
  }

  return (
    <div>
      <Header title="Grid" />

      <div className="grid-component-container">
        <div className="modal_header">
          <GridSwitch checked={isChecked} onChange={handleSwitchChange} />

          {newQuoteRequestHeader?.map((item, index) => (
            <div
              key={index}
              role="button"
              tabIndex={0}
              className={`d-flex align-items-center justify-content-center flex-column header_items cursor-pointer
                        ${activeHeader?.id === index ? "active_header_item" : ""}`}
              onClick={() => handleHeaderChange(item)}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  handleHeaderChange(item);
                }
              }}
            >
              <item.image />
              <div
                className={
                  activeHeader?.id === index
                    ? "active_veichle_name"
                    : "veichle_name"
                }
              >
                {item.name}
              </div>
            </div>
          ))}
        </div>
        <div className="options-container">
          <form onSubmit={handleSubmit(handlePolicyDealsSubmit)}>
            <Grid container spacing={2.5} className="grid-options">
              {fixedOptionKeys.map(
                (item, index) =>
                  item.isDisplay && (
                    <Grid
                      item
                      xs={3}
                      key={`${item.key}-${index}`}
                      className="grid-options-container"
                    >
                      <FormControl sx={{ width: "220px" }}>
                        <Controller
                          control={control}
                          name={item.formKey}
                          render={({ field }) => {
                            const {
                              value = item.isMulti ? [] : null, // Default value for controlled behavior
                              onChange,
                              ...rest
                            } = field;
                            const options = getOptions(item.optionsKey) || [];

                            const handleChange = (newValue) => {
                              onChange(newValue);
                              if (item.key === "makeSubstring") {
                                setValue("modelSubstring", "");
                                setValue("model", "");
                              }
                            };

                            return (
                              <NewAutoComplete
                                value={
                                  item.isMulti
                                    ? value && Array.isArray(value) // Ensure it's an array for multi-select
                                      ? value
                                      : []
                                    : value || null // Ensure it's null for single-select
                                }
                                options={options}
                                placeholder={item.placeholder}
                                isMulti={item.isMulti}
                                onChange={handleChange}
                                extraStyle={{ height: "50px" }}
                                isSelectAllAllowed={item.allowSelectAll}
                                loading={isLoading}
                                error={errors[item.formKey]?.message}
                                onInputChange={(event, inputValue, reason) =>
                                  handleInputChange(
                                    item.key,
                                    inputValue,
                                    reason,
                                  )
                                }
                                disabled={
                                  item.key === "modelSubstring" &&
                                  !getValues("make")
                                }
                                {...rest}
                              />
                            );
                          }}
                        />
                      </FormControl>
                    </Grid>
                  ),
              )}
              <Grid item xs={3}>
                <Controller
                  control={control}
                  name="date"
                  render={({ field }) => (
                    <div className="" style={{ width: "220px" }}>
                      <DatePicker
                        style={{ width: "100%", height: "50px" }}
                        className="date-container"
                        {...field}
                        options={{ dateFormat: "d-m-y" }}
                        onChange={(date) => field.onChange(date[0])} // Ensure this is correctly calling `field.onChange`
                        error={errors.date}
                      />
                      {errors.date && (
                        <div
                          style={{
                            fontWeight: "500",
                            marginLeft: "14px",
                          }}
                          className="error_class"
                        >
                          {errors.date.message}
                        </div>
                      )}
                    </div>
                  )}
                />
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  marginLeft: "auto",
                }}
              >
                <CustomButton
                  variant="contained"
                  color="secondary"
                  startIcon={<Search />}
                  buttonName="Search"
                  type="Search"
                  className="secondary_button"
                >
                  {" "}
                  Search{" "}
                </CustomButton>
              </Grid>
            </Grid>
          </form>
        </div>
        <div className="policy_deals_container">
          {policyDeals && policyDeals.isLoading && (
            <div>
              <Loader /> <span className="loader_text">Loading...</span>
            </div>
          )}
          {!isFirstLoad &&
            policyDeals &&
            !policyDeals.isLoading &&
            policyDeals.declineDeals.length === 0 &&
            policyDeals.specialDeals.length === 0 &&
            policyDeals.marketDeals.length === 0 && (
              <div>
                <NoDataFound />
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default GridPage;
