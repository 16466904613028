import React, { useState } from "react";
import { Box, Tab, Tabs, Typography, Button } from "@mui/material";

// Function to provide accessibility props to tabs
function a11yProps(index) {
  return {
    id: `custom-tab-${index}`,
    "aria-controls": `custom-tabpanel-${index}`,
  };
}

// CustomTabing component
const CustomTabing = ({ value, tabList, handleChange }) => {
  const fixTabCount = 10;
  const [page, setPage] = useState(0);
  const startRange = fixTabCount * page;
  const endRange = fixTabCount * (page + 1);
  const maxCount = Math.ceil(tabList.length / fixTabCount);

  const handleTabClick = (val) => {
    setPage(page + val);
  };

  return (
    <Box
      style={{
        position: "relative",
      }}
    >
      {page !== 0 && (
        <Button
          style={{
            height: "100%",
            textAlign: "center",
            position: "absolute",
            zIndex: 10,
            background: "#E8F3FC ",
            boxShadow: "2px 0px 5px rgba(0, 0, 0, 0.2)", 
            minWidth: "48px",
            padding: "0px",
          }}
          onClick={() => handleTabClick(-1)}
        >
          {"< +"}
          {page * fixTabCount}
        </Button>
      )}
      <Box
        sx={{
          width: "100%",
          margin: "20px auto",
          backgroundColor: "white",
          borderRadius: "10px",
          // height: "50px",
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={(event, newValue) => {
              handleChange(newValue);
            }}
            aria-label="custom tabs example"
            sx={{ borderRadius: "10px" }}
            variant="fullWidth"
          >
            {tabList.slice(startRange, endRange).map((item, index) => (
              <Tab
                key={index}
                {...a11yProps(index)}
                className="tab-set"
                style={{
                  color: "#0691FC",
                  padding: "13px 5px",
                  width: "100%",
                  background:
                    value === item.bucket
                      ? "linear-gradient(5.64deg, #D4ECFF 5.59%, rgba(255, 255, 255, 0) 60.82%)"
                      : "white",
                }}
                label={
                  <Box sx={{height: "56px" }}>
                    <Typography
                      style={{
                        color: value === item.bucket ? "#0691FC" : "black",
                        fontSize: "16px"
                      }}
                    >
                      {item.count}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "10px",
                        color: "rgba(102, 102, 102, 1)",
                        fontWeight: "500",
                        textTransform: "none",
                      }}
                    >
                      {item.bucketName}
                    </Typography>
                  </Box>
                }
                value={item.bucket}
              />
            ))}
          </Tabs>
        </Box>
      </Box>

      {page < maxCount - 1 && (
        <Button
          style={{
            height: "100%",
            position: "absolute",
            right: "0px",
            zIndex: 10,
            boxShadow: "-2px 0px 5px  rgba(0, 0, 0, 0.2)",
            top: "0%",
            minWidth: "48px",
            padding: "0px",
            background: "#E8F3FC ",
          }}
          onClick={() => handleTabClick(1)}
        >
          {"+"}
          {tabList.length - (page + 1) * fixTabCount}
          {" >"}
        </Button>
      )}
    </Box>
  );
};

export default CustomTabing;
