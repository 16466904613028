import * as React from "react";
import { Box, Typography, Modal, CircularProgress } from "@mui/material";
import DownloadIcon from "../../../assets/icons/DownloadIcon.svg";
import policy from "../../../assets/salesbooking/policy.svg";
import Car from "../../../assets/salesbooking/Car.svg";
import cross from "../../../assets/salesbooking/Cross.svg";

import { Link } from "react-router-dom";

export default function BookingModal({
  handleClose,
  headerData,
  vehicleDetails,
  policyDetails,
  policyCopyUrl,
  businessType,
  premiumDetails,
  isModalLoading,
}) {
  return (
    <div>
      <Modal
        open={true}
        onClose={handleClose}
        sx={{ overflowY: "auto", maxHeight: "100vh" }}
      >
        {isModalLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            sx={{
              width: "1000px",
              maxWidth: "100%", // Ensures modal adapts to the screen size
              backgroundColor: "white",
              margin: "30px auto",
              borderRadius: "20px",
              padding: "",
            }}
          >
            <div
              id="modal-modal-title"
              style={{
                width: "100%",
                height: "77px",
                backgroundColor: "#00223C",
                borderRadius: "20px 20px 0px 0px",
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                position: "relative",
              }}
            >
              <h2
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  lineHeight: "27px",
                }}
              >
                {headerData?.registrationNumber} <br />
                <span
                  style={{
                    fontSize: "12px",
                    lineHeight: "18px",
                    fontWeight: "400",
                  }}
                >
                  {headerData?.makeModel} {headerData?.partnerId}
                </span>
              </h2>
              <Typography
                component={"span"}
                onClick={handleClose}
                sx={{ position: "absolute", right: "10px", cursor: "pointer" }}
              >
                <img src={cross} alt=" cross-icon" />
              </Typography>
            </div>

            <div
              style={{
                width: "100%",
                padding: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  marginLeft: "10px",
                }}
              >
                <span
                  style={{
                    height: "32px",
                    border: "2px solid #0691FC ",
                  }}
                ></span>
                <span>
                  <img
                    src={Car}
                    alt="car-icon"
                    style={{ width: "24px", height: "24px" }}
                  />{" "}
                </span>
                <span
                  style={{
                    fontStyle: "poppins",
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "#000000",
                  }}
                >
                  Vehicle Details
                </span>
              </div>

              <div
                style={{
                  maxWidth: "950px",
                  padding: "12px",
                  marginTop: "20px",
                  backgroundColor: "#F2F2F2",
                  borderRadius: "15px",
                  margin: "20px",
                }}
              >
                {/* Inner data of vehicleDetails */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    gap: "20px",
                    flexWrap: "wrap",
                  }}
                >
                  {vehicleDetails?.map((details, index) => (
                    <div
                      key={index}
                      style={{
                        flexBasis: "calc(25% - 16px)",
                        marginBottom: "12px",
                        fontSize: "12px",
                        fontWeight: "500",
                        lineHeight: "18px",
                        color: "#808080",
                      }}
                    >
                      {details.key}{" "}
                      <span
                        style={{
                          display: "block",
                          fontSize: "12px",
                          fontWeight: "500",
                          lineHeight: "18px",
                          color: "black",
                          marginTop: "4px",
                        }}
                      >
                        {details.value || "N/A"}
                      </span>
                    </div>
                  ))}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center ",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    marginLeft: "10px",
                  }}
                >
                  <span
                    style={{
                      height: "32px",
                      border: "2px solid #0691FC ",
                    }}
                  ></span>
                  <span>
                    <img
                      src={policy}
                      alt="policy-icon"
                      style={{ width: "24px", height: "24px" }}
                    />{" "}
                  </span>
                  <span
                    style={{
                      fontStyle: "poppins",
                      fontSize: "12px",
                      fontWeight: "600",
                      color: "#000000",
                    }}
                  >
                    Policy Details
                  </span>

                  <span
                    style={{
                      backgroundColor: "#e8f3fc",
                      borderRadius: "6px",
                      color: "var(--blue-dark)",
                      fontSize: "12px",
                      fontWeight: "600",
                      gap: "10px",
                      padding: "4px 8px",
                      textAlign: "left",
                      marginLeft: "11px ",
                    }}
                  >
                    {businessType}
                  </span>
                </div>

                <Link
                  to={policyCopyUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <span>
                      <img src={DownloadIcon} alt="download-icon" />
                    </span>
                    <span
                      style={{
                        fontSize: "12px",
                        color: "#0691FC",
                        fontWeight: "500",
                        lineHeight: "18px",
                        marginRight: "20px",
                        cursor: "pointer",
                      }}
                    >
                      Download PDF
                    </span>
                  </div>
                </Link>
              </div>

              <div
                style={{
                  maxWidth: "950px",
                  padding: "12px",
                  backgroundColor: "#F2F2F2",
                  borderRadius: "15px 15px 0px 0px",
                  margin: "20px",
                  height: "500px",
                }}
              >
                {/* Inner data of vehicleDetails */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    gap: "10px",
                    flexWrap: "wrap",
                    position: "relative",
                  }}
                >
                  {policyDetails?.map((details, index) => (
                    <div
                      key={index}
                      style={{
                        flexBasis: "calc(25% - 16px)",
                        marginBottom: "12px",
                        fontSize: "12px",
                        fontWeight: "500",
                        lineHeight: "18px",
                        color: "#808080",
                      }}
                    >
                      {details.key}{" "}
                      <span
                        style={{
                          display: "block",
                          fontSize: "12px",
                          fontWeight: "500",
                          lineHeight: "18px",
                          color: "black",
                          marginTop: "4px",
                        }}
                      >
                        {details.value || "N/A"}
                      </span>
                      <div
                        style={{
                          width: "920px",
                          height: "37",
                          backgroundColor: "#CEF6D7",
                          padding: "12px",
                          borderRadius: "0px 0px 15px 15px",
                          position: "absolute",
                          top: "460px",
                          right: "-12px",
                          textAlign: "center ",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "14px",
                            lineHeight: "18px",
                            fontWeight: "600",
                            color: "#005814",
                          }}
                        >
                          Premium Breakup:{" "}
                        </span>
                        <span
                          style={{
                            fontSize: "14px",
                            lineHeight: "18px",
                            fontWeight: "600",
                            color: "#181818",
                          }}
                        >
                          <span
                            style={{
                              marginRight: "10px",
                            }}
                          >
                            OD-{premiumDetails.odPremium}
                          </span>
                          <span
                            style={{
                              marginRight: "10px",
                            }}
                          >
                            TP-{premiumDetails.tpPremium}
                          </span>
                          <span
                            style={{
                              marginRight: "10px",
                            }}
                          >
                            NET-{premiumDetails.netPremium}
                          </span>
                          <span
                            style={{
                              fontSize: "14px",
                              fontWeight: "600",
                              lineHeight: "18px",
                              color: "#181818",
                            }}
                          >
                            APE-{premiumDetails.totalPremium}
                          </span>
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Box>
        )}
      </Modal>
    </div>
  );
}
