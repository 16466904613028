import React, { useState, useEffect } from "react";
import "./index.css";
import { useDispatch } from "react-redux";
import { newQuoteRequestHeader } from "../../helpers/enums";
import NewQuoteForm from "./NewQuoteForm";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { createNewQuoteRequestPayload } from "../../utils/utils";
import {
  policyTypeOptions,
  uploadFileAllowedFormats,
} from "../../assets/constant";
import { Box, CircularProgress, Modal, Typography } from "@mui/material";
import { getInsurersList } from "../../store/action/insurers";
import axios from "../../store/api/axios";
import { raiseQuoteRequestPOST } from "../../store/api";
import { message } from "../generic/SnakeAlert/SnakeAlert";
import LottiePlayer from "../Lottie/LottiePlayer";
import Verified from "../../assets/Lottie/verified.json";
import CustomButton from "../Button/CustomButton/CustomButton";
import { getSalesQuoteListAPI } from "../../store/action/salesQuote";
const NewQuoteRequest = ({ onClose, open, partnerId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeHeader, setActiveHeader] = useState({});
  const [vehicleState, setVehicleState] = useState("Vehicle is New");

  const [vehicleType, setVehicleType] = useState(["Comprehensive Policy"]);
  const [previousPolicyFlag, setPreviousPolicyFlag] = useState(["No"]);
  const [claimPolicyFlag, setClaimPolicyFlag] = useState(["No"]);
  const [isFormSubmited, setIsFormSubmited] = useState(false);
  const [loading, setLoading] = useState(false);
  const [createdQuoteId, setCreatedQuoteId] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const vehicleSchema = yup.object().shape({
    // Common fields from both schemas
    name: yup.string().required("Customer name is required"),
    mobileNumber: yup
      .string()
      .required("Customer number is required")
      .matches(/^[6789]\d{9}$/, "Invalid mobile number")
      .length(10, "Mobile number must be exactly 10 digits"),
    email: yup
      .string()
      .required("Customer email is required")
      .test("email", "Invalid email", (value) => {
        return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);
      }),
    manufacturingYear: yup.string().required("Manufacturing year is required"),
    selectInsurer: yup
      .array()
      .required("Please select at least one insurer")
      .of(yup.string("Please select at least one insurer"))
      .min(1, "Please select at least one insurer"),

    // Fields from new vehicle schema
    rtoCode:
      vehicleState !== "Vehicle is New"
        ? yup.string().optional()
        : yup.string().required("RTO code is required"),
    idv:
      vehicleType[0] === "Comprehensive Policy" ||
      vehicleType[0] === "SAOD Policy"
        ? yup.string().required("IDV is required")
        : yup.string().nullable(),
    addOns: yup
      .array()
      .nullable()
      .of(yup.string().required("Please select at least one Addon")),
    INVOICE_COPY:
      vehicleState === "Vehicle is New"
        ? yup
            .mixed()
            .optional()
            .nullable()
            .required("Please select a file")
            .test("fileType", "Invalid file format", (value) => {
              if (!value) return true;
              const supportedFormats = uploadFileAllowedFormats;
              return supportedFormats.includes(value.type);
            })
            .test("fileSize", "File size should not exceed 5 mb", (value) => {
              return value && value.size <= 5242880; // 5MB maximum file size
            })
        : yup.string().nullable(),
    PERMIT:
      activeHeader?.key === "BUS"
        ? yup
            .mixed()
            .optional()
            .nullable()
            .required("Please select a file")
            .test("fileType", "Invalid file format", (value) => {
              if (!value) return true;
              const supportedFormats = uploadFileAllowedFormats;
              return supportedFormats.includes(value.type);
            })
            .test("fileSize", "File size should not exceed 5 mb", (value) => {
              if (!value) return true;
              return value && value.size <= 5242880; // 5MB maximum file size
            })
        : yup.string().nullable(),

    // Fields from old vehicle schema
    registrationNumber:
      vehicleState === "Vehicle is New"
        ? yup.string().optional().nullable()
        : yup.string().required("Registration number is required"), // Only in oldVehicleSchema
    registrationDate:
      vehicleState === "Vehicle is New"
        ? yup.date().optional().nullable()
        : yup.date().required("Registration date is required"),
    // isPyp: vehicleState !== "Vehicle is New" ? yup.boolean().required() : null,
    pypExpiryDate:
      previousPolicyFlag[0] === "Yes"
        ? yup
            .date("Expiry Date is Required")
            .typeError("Expiry Date is Required")
            .required("Expiry Date is Required")
        : yup.string().nullable(),
    ncb:
      previousPolicyFlag[0] === "Yes" &&
      claimPolicyFlag[0] === "No" &&
      (vehicleType[0] === policyTypeOptions.COMPREHENSIVE ||
        vehicleType[0] === policyTypeOptions.SAOD)
        ? yup
            .number("NCB is Required")
            .typeError("NCB is Required")
            .required("NCB is required")
            .min(0, "NCB can't be less than 0")
            .max(100, "NCB can't be more than 100")
        : yup.string().nullable(),
    RC:
      vehicleState !== "Vehicle is New"
        ? yup
            .mixed()
            .optional()
            .nullable()
            .required("Please select a file")
            .test("fileType", "Invalid file format", (value) => {
              if (!value) return false;
              const supportedFormats = uploadFileAllowedFormats;
              return supportedFormats.includes(value.type);
            })
            .test("fileSize", "File size should not exceed 5 mb", (value) => {
              return value && value.size <= 5242880; // 5MB maximum file size
            })
        : yup.string().nullable(),
    PYP:
      previousPolicyFlag[0] === "Yes"
        ? yup
            .mixed()
            .optional()
            .nullable()
            .required("Please select a file")
            .test("fileType", "Invalid file format", (value) => {
              if (!value) return false;
              const supportedFormats = uploadFileAllowedFormats;
              return supportedFormats.includes(value.type);
            })
            .test("fileSize", "File size should not exceed 5 mb", (value) => {
              return value && value.size <= 5242880;
            })
        : yup.string().nullable(),
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    // resetField,
    setValue,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(vehicleSchema), // Using the unified schema
    defaultValues: {
      // Combined default values from both new and old vehicle schemas
      name: "",
      mobileNumber: "",
      email: "",
      rtoCode: "", // Specific to new vehicle
      registrationNumber: "", // Specific to old vehicle
      registrationDate: null, // Specific to old vehicle
      selectInsurer: null,
      addOns: null,
      idv: "",
      isPyp: null, // Specific to old vehicle
      pypExpiryDate: null, // Specific to old vehicle
      INVOICE_COPY: null,
      OTHER_DOC: null,
      PYP: null,
      RC: null,
      PERMIT: null,
      manufacturingYear: "",
    },
  });

  const handleToReset = () => {
    reset();
    setActiveHeader(newQuoteRequestHeader[0]);
    setVehicleState("Vehicle is New");
    setVehicleType(["Comprehensive Policy"]);
    setPreviousPolicyFlag(["No"]);
    setClaimPolicyFlag(["No"]);
  };

  // const handleResetField = (field, value) => {
  //   resetField(field, value);
  // };

  const handleSetValues = (field, value) => {
    setValue(field, value);
  };

  const handleToSubmit = async (data) => {
    try {
      setLoading(true);
      const payload = {
        product: activeHeader.key,
        vehicleType: vehicleState === "Vehicle is New" ? "NEW" : "OLD",
        policyType:
          vehicleType[0] === "Comprehensive Policy"
            ? "COMP"
            : vehicleType[0] === "Third Party Policy"
              ? "TP"
              : "SAOD",
        ...data,
        insurerList: data.selectInsurer,
        partnerId: partnerId,
      };

      if (vehicleState !== "Vehicle is New") {
        payload.pypExpiryDate = data.pypExpiryDate;
        payload.isPyp = previousPolicyFlag[0] === "No" ? false : true;
        if (
          vehicleType[0] === policyTypeOptions.COMPREHENSIVE ||
          vehicleType[0] === policyTypeOptions.SAOD
        ) {
          payload.pypClaim = claimPolicyFlag[0] === "No" ? false : true;
        }
      }

      const formData = createNewQuoteRequestPayload(payload);

      const response = await axios.post(raiseQuoteRequestPOST, formData);

      if (response?.data?.success) {
        message.success(response.data.msg);

        setCreatedQuoteId(response.data.data?.quoteId);
        setIsFormSubmited(true);
        handleToReset();
        // getQuoteRequestDetails();
      }
    } catch (err) {
      message.error(err?.response?.data?.msg || "something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleToNavigateToQuoteList = () => {
    setIsFormSubmited(false);
    // setCreatedQuoteId("");
    onClose();
    dispatch(getSalesQuoteListAPI({ curPage: 1 }));
    navigate("/app/sales/quote-request");
  };

  useEffect(() => {
    setActiveHeader(newQuoteRequestHeader[0]);
  }, [open]);

  useEffect(() => {
    if (activeHeader?.key && vehicleType[0]) {
      dispatch(
        getInsurersList({
          product: activeHeader?.key,
          policyType:
            vehicleType[0] === "Comprehensive Policy"
              ? "COMP"
              : vehicleType[0] === "Third Party Policy"
                ? "TP"
                : "SAOD",
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeHeader, vehicleType]);

  useEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleState]);
  return (
    <Modal
      open={open}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "flex-end",
        height: "100%",
        position: "fixed",
        borderRadius: "24px 24px 0px 0px ",
        overflow: "hidden",
      }}
    >
      {loading ? ( // Center the CircularProgress in the parent container
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh", // Adjust based on the available space
            width: "534px",
            backgroundColor: "white",
            borderRadius: "24px 24px 0px 0px ",
          }}
        >
          <CircularProgress />
        </Box>
      ) : isFormSubmited ? (
        <Box
          sx={{
            textAlign: "center",
            borderRadius: "24px 24px 0px",
            backgroundColor: "white",
            width: "515px",
            height: "425px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            marginTop={"0px"}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <LottiePlayer width={"130px"} height={"130px"} image={Verified} />
            <Typography component={"div"}>
              <Typography
                variant="h4"
                sx={{
                  marginTop: "20px",
                  fontSize: "20px",
                  lineHeight: "24px",
                  color: "black",
                  fontWeight: "600",
                }}
              >
                Quote Request Raised Successfully!
              </Typography>
            </Typography>
            <div>
              <span style={{ color: "#808080" }}>Ticket Id :- </span>
              {createdQuoteId}
            </div>

            <CustomButton
              buttonName="Go To My Requests"
              className="submit_btn button"
              handleClick={handleToNavigateToQuoteList}
              alignCenter
            />
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            width: "582px",
            padding: "0px",
            flexShrink: 0,
            backgroundColor: "white",
            // height: "
            maxHeight: "90vh",
            height: "100%",
            borderRadius: "24px 24px 0px 0px ",
            boxShadow: 24,
            position: "absolute",
            bottom: "0px",
            right: "20px",
            transition: "transform 0.5s ease-in-out",
            transform: open ? "translateY(0)" : "translateY(100%)",
          }}
          role="presentation"
        >
          <div
            className="raise-modal_header"
            style={
              {
                // position: "sticky", // Make the header stick to the top
                // top: 0, // Position at the top
                // Ensure it stays above the scrollable content
                // Background to prevent transparency when scrolling
              }
            }
          >
            {newQuoteRequestHeader?.map((item, index) => (
              <div
                key={index}
                role="button"
                tabIndex={0}
                className={`d-flex align-items-center justify-content-center flex-column header_items cursor-pointer
                  ${activeHeader?.id === index ? "active_header_item" : ""}`}
                onClick={() => setActiveHeader(item)}
              >
                <item.image />
                <div
                  className={
                    activeHeader?.id === index
                      ? "active_veichle_name"
                      : "veichle_name"
                  }
                >
                  {item.name}
                </div>
              </div>
            ))}
          </div>
          <Box sx={{ height: "100%" }}>
            <Typography
              component={"div"}
              sx={{
                backgroundColor: "#FFEBDF",
                textAlign: "center",
                height: "36px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                component={"span"}
                sx={{
                  fontSize: "13px",
                  fontWeight: "500",
                  lineHeight: "19.5px",
                  color: "#EF8C00",
                }}
              >
                You are raising this request on behalf of the partner{" "}
                {partnerId}
              </Typography>
            </Typography>
            <NewQuoteForm
              vehicleState={vehicleState}
              setVehicleState={setVehicleState}
              errors={errors}
              handleSubmit={handleSubmit}
              control={control}
              handleToSubmit={handleToSubmit}
              reset={handleToReset}
              resetField={handleSetValues}
              setVehicleType={setVehicleType}
              vehicleType={vehicleType}
              previousPolicyFlag={previousPolicyFlag}
              setPreviousPolicyFlag={setPreviousPolicyFlag}
              claimPolicyFlag={claimPolicyFlag}
              setClaimPolicyFlag={setClaimPolicyFlag}
              activeHeader={activeHeader}
              onClose={onClose}
            />
          </Box>
        </Box>
      )}
    </Modal>
  );
};

export default NewQuoteRequest;
