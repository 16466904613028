import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchParterIdsList } from "../../store/action/auth.js";
import CrossIcon from "../../assets/icons/crossIcon.svg";
import RaisePolicyMapping from "./RaisePolicyMapping.jsx";
import NewQuoteRequest from "../../components/NewQuoteRequest/NewQuoteRequest.jsx";
import { message } from "../../components/generic/SnakeAlert/SnakeAlert.jsx";
import NewAutoComplete from "../../components/generic/NewAutoComplete/NewAutoComplete.jsx";

export default function SelectPartnerModel({
  openModal,
  handleClose,
  modalType,
}) {
  const [selectedPartner, setSelectedPartner] = useState("");
  const [showPolicyMapping, setShowPolicyMapping] = useState(false);
  const { partnerIds } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  // Fetch partner IDs on component mount
  useEffect(() => {
    if (!partnerIds || partnerIds.length === 0) {
      dispatch(fetchParterIdsList());
    }
  }, [dispatch, partnerIds]);

  // Handle proceed button click
  const handleProceedClick = () => {
    if (selectedPartner) {
      setShowPolicyMapping(true);
    } else {
      // setShowPolicyMapping(false);
      message.error("Please select a partner");
    }
  };

  // Handle closing the policy mapping drawer and modal
  const handleDrawerClose = () => {
    setSelectedPartner("");
    setShowPolicyMapping(false);
    handleClose();
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: "24",
            padding: "20px",
            backgroundColor: "white",
            width: "361px",
            height: "254px",
            borderRadius: "20px",
          }}
        >
          <Typography
            component="h2"
            sx={{
              fontSize: "24px",
              color: "black",
              fontWeight: "600",
              lineHeight: "36px",
              textAlign: "center",
            }}
          >
            Select Partner
          </Typography>
          <Button
            onClick={handleClose}
            sx={{ position: "absolute", right: "10px", top: "10px" }}
          >
            <img src={CrossIcon} alt="crossicon" />
          </Button>
          <Typography sx={{ my: 4 }}>
            <NewAutoComplete
              options={
                partnerIds
                  ?.filter((partner) => partner.partnerId.startsWith("DP"))
                  ?.map((partnerData) => ({
                    label: partnerData.partnerId + " | " + partnerData.name,
                    value: partnerData.partnerId,
                  })) || []
              }
              placeholder="Select Partner"
              className="select_dropdown"
              onChange={(selectedPartner) => {
                setSelectedPartner(selectedPartner);
              }}
              isMulti={false}
              value={selectedPartner}
            />
          </Typography>
          <Typography component={"button"} onClick={handleProceedClick}>
            <Button
              variant="contained"
              sx={{
                color: "white",
                textAlign: "center",
                backgroundColor: "#0691FC",
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "322px",
                height: "48px",
                cursor: "pointer",
                position: "relative",
              }}
            >
              PROCEED
            </Button>
          </Typography>
        </Box>
      </Modal>

      {/* Render the RaisePolicyMapping drawer conditionally */}

      {showPolicyMapping && modalType === "RAISE_POLICY_MAPPING" && (
        <RaisePolicyMapping
          onClose={handleDrawerClose}
          open={showPolicyMapping}
          partnerId={selectedPartner}
        />
      )}
      {showPolicyMapping && modalType === "RAISE_NEW_REQUEST" && (
        <NewQuoteRequest
          onClose={handleDrawerClose}
          open={showPolicyMapping}
          partnerId={selectedPartner}
        />
      )}
    </>
  );
}
