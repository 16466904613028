import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import Heading from "../Heading/Heading";
function Transition(props) {
  return <Slide {...props} direction="up" />;
}

const DialogBox = ({ open, setOpen, children, title, width }) => {
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick" && "escapeKeyDown") return;
        setOpen(false);
      }}
      PaperProps={{ sx: { borderRadius: "24px" } }}
      TransitionComponent={Transition}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: width ? width : "700px",
          },
        },
      }}
    >
      <DialogTitle
        sx={{ padding: "var(--base-space-2) 40px var(--base-space-5)" }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Heading text={title} type={"noborder large"} />
          </Box>
          <Box
            sx={{
              width: 34,
              height: 34,
              borderRadius: 50,
              background: "#E6E6E6",
              cursor: "pointer",
              padding: "5px",
            }}
            onClick={() => setOpen(false)}
          >
            <CloseIcon />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ padding: "var(--base-space-2) 40px" }}>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default DialogBox;
