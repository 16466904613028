import React, { useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import EnhancedTableHead from "./QuoteHeader";
import DialogBox from "../generic/DialogBox";
import CustomForm from "../Form/CustomForm";
import { Typography } from "@mui/material";
import CustomModal from "../Form/CustomModal";
import { quoteRequestStateList } from "../../helpers/enums";
import { useSelector } from "react-redux";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = (array || [])?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

export default function EnhancedTable({ rows }) {
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isVerify, setIsVerify] = useState("");
  // Check for potential usage of useState then remove the comments
  // const [title, setTitle] = useState("");
  // const [id, setId] = useState("");
  // const [status, setStatus] = useState("");
  const title = "",
    id = "",
    status = "";
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("status");
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const permissions = useSelector((state) => state?.auth?.permissions) || {};

  const handleDialogOpen = (id) => {
    window.open(`/app/quote-modal/${id}`, "_blank");
  };

  const visibleRows = useMemo(
    () => stableSort(rows, getComparator(order, orderBy))?.slice(),
    [order, orderBy, rows],
  );

  const handleBGColor = (key) => {
    if (
      key === quoteRequestStateList.PAYMENT_DETAILS_APPROVED ||
      key === quoteRequestStateList.QUOTE_SHARED
    ) {
      return "#09CE1D";
    }
    if (
      key === quoteRequestStateList.INSPECTION_PORTAL_REQUEST_RAISE ||
      key === quoteRequestStateList.INSPECTION_PORTAL_REQUEST_RAISE
    ) {
      return "#FF3E78";
    }
    if (key === quoteRequestStateList.DOCUMENT_VERIFICATION_STARTED) {
      return "#0691FC";
    } else {
      return "#FFBB0D";
    }
  };
  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer sx={{ height: "calc(100vh - 330px)", overflowY: "auto" }}>
        <Table
          sx={{ minWidth: 750, position: "relative", top: 0, zIndex: 1 }}
          aria-labelledby="tableTitle"
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowcount={rows?.length}
            sx={{
              "& th": {
                position: "sticky",
                top: 0,
                backgroundColor: "#ececec",
                zIndex: 1,
              },
            }}
          />
          {rows?.length !== 0 ? (
            <TableBody>
              {visibleRows?.map((row, index) => {
                // const isItemSelected = isSelected(row.name);
                // const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow key={`${row}-${index}`}>
                    <TableCell align="left">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <span
                          style={{
                            color: "#000",
                            fontSize: 14,
                            fontWeight: "600",
                            maxWidth: "150px",
                            wordWrap: "break-word",
                            linebeak: "2",
                          }}
                        >
                          {row?.quoteId}
                        </span>
                        <span
                          style={{
                            color: "#808080",
                            fontSize: 12,
                            fontWeight: "400",
                          }}
                        >
                          {row?.timestamp.split("T")[0]}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <span
                          style={{
                            color: "#000",
                            fontSize: 14,
                            fontWeight: "600",
                          }}
                        >
                          {row?.registrationNumber}
                        </span>
                        <span
                          style={{
                            color: "#808080",
                            fontSize: 12,
                            fontWeight: "400",
                          }}
                        >
                          {row?.customerName}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <span
                          style={{
                            color: "#000",
                            fontSize: 14,
                            fontWeight: "600",
                          }}
                        >
                          {row?.partnerName}
                        </span>
                        <span
                          style={{
                            color: "#808080",
                            fontSize: 12,
                            fontWeight: "400",
                          }}
                        >
                          {row?.partnerId}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      <span
                        style={{
                          background: handleBGColor(row?.quoteState),
                          borderRadius: 20,
                          color: "#fff",
                          padding: "6px 12px",
                          fontSize: 12,
                        }}
                      >
                        {row?.quoteStateName}
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      {permissions?.OPS_QUOTE_REQUEST?.EDIT ? (
                        <div
                          role="button"
                          style={{
                            display: "flex",
                            cursor: "pointer",
                          }}
                          onClick={() => handleDialogOpen(row?.quoteId)}
                          tabIndex={0}
                        >
                          <span
                            style={{
                              color: "#0691FC",
                              background: "#E8F3FC",
                              padding: "12px 16px",
                              width: 120,
                              borderRadius: 8,
                            }}
                          >
                            View Details
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell align="left">
                  <Typography sx={{ my: 10 }}>No Data Found.</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>

      {open && (
        <DialogBox
          title={`KYC verification for ${title}`}
          open={open}
          setOpen={setOpen}
          children={
            <CustomForm
              setOpen={setOpen}
              id={id}
              isBank={status}
              setOpenModal={setOpenModal}
              setIsVerify={setIsVerify}
            />
          }
        />
      )}

      {openModal && (
        <DialogBox
          width={500}
          open={openModal}
          setOpen={setOpenModal}
          children={
            <CustomModal
              setOpen={setOpenModal}
              title={`${title} details are verified !`}
              isBank={status}
              isVerify={isVerify}
            />
          }
        />
      )}
    </Box>
  );
}
