import React from "react";
import "./index.css";

const Heading = ({ text, type, className = "", ...rest }) => {
  return (
    <h3 className={`${"heading"} ${type} ${className}`} {...rest}>
      {text}
    </h3>
  );
};

export default Heading;
