import React, { useEffect, useState } from "react";
import Heading from "../../components/generic/Heading/Heading";
import checkImg from "../../assets/icons/checkImg.svg";
import { ReactComponent as UploadIcon } from "../../assets/images/QuoteRequest/Upload.svg";
import { Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Close, RemoveRedEyeOutlined } from "@mui/icons-material";
import { quoteChangeRequestAPI } from "../../store/action/quote";
import SuccessModal from "../../components/generic/Modal";
import CutomeModalNoQuote from "../../components/Form/CutomeModalNoQuote";
import DialogBox from "../../components/generic/DialogBox";
import { ReactComponent as AttachIcon } from "../../assets/icons/attachIcon.svg";
import { ReactComponent as RemoveIcon } from "../../assets/icons/bin.svg";
import { CustomSwitch } from "../../components/generic/CustomSwitch";
import { quoteStatusList } from "../../helpers/enums";
import Addons from "../../components/QuoteComponent/Addons";
import { InsurerLogoMap } from "../../helpers/InsurerLogoMap";
import { message } from "../../components/generic/SnakeAlert/SnakeAlert";
const QuoteChangeRequest = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.quote.quoteDetails);
  const [file, setfile] = useState(null);
  const [openNoQuote, setNoQuote] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [inspectionRequired, setInspectionRequired] = useState(false);

  useEffect(() => {
    if (data?.data?.offlineQuotes) {
      data?.data?.offlineQuotes?.map((i) => {
        if (i?.quoteStatus === quoteStatusList.CHANGE_REQUESTED) {
          setInspectionRequired(i?.inspectionRequired);
        }
      });
    }
  }, [data]);

  const handleSubmit = (i, isQuoteAvailable) => {
    let formData = new FormData();
    formData.append("quoteRequestId", i?.quoteRequestId);
    formData.append("inspectionRequired", inspectionRequired);
    formData.append("isQuoteAvailable", isQuoteAvailable);
    if (isQuoteAvailable) {
      formData.append("file", file);
    }
    dispatch(quoteChangeRequestAPI(formData, setOpenSuccess, setNoQuote));
  };
  return (
    <div
      style={{
        width: "100%",
        padding: "0 30px 0 0",
      }}
    >
      <Heading text="Shared Quotes" type="withborder" />
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "12px",
            marginTop: "16px",
          }}
        >
          {data?.data?.offlineQuotes?.map((i) => (
            <>
              {i?.quoteStatus === quoteStatusList.CHANGE_REQUESTED ? (
                <div
                  style={{
                    backgroundColor: "#fcefe8",
                    padding: "12px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    borderRadius: 8,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyItems: "center",
                        gap: "16px",
                      }}
                    >
                      {i?.insurerId && InsurerLogoMap[i?.insurerId] ? (
                        <img
                          src={InsurerLogoMap[i?.insurerId]?.Logo}
                          alt="insurer"
                        />
                      ) : (
                        <p>{i?.insurerName}</p>
                      )}
                      <div
                        style={{
                          backgroundColor: "#FFBB0D",
                          padding: "4px 10px",
                          borderRadius: "4px",
                          display: "flex",
                          gap: "5px",
                        }}
                      >
                        <h3
                          style={{
                            color: "#fff",
                            fontSize: "10px",
                            fontWeight: 500,
                          }}
                        >
                          Changes Requested{" "}
                        </h3>
                      </div>
                      {!openSuccess && (
                        <>
                          <CustomSwitch
                            // {...label}
                            checked={inspectionRequired}
                            // value={inspectionRequired}
                            onChange={(e) =>
                              setInspectionRequired(e.target.checked)
                            }
                          />
                          <h3>Inspection</h3>
                        </>
                      )}
                    </div>
                    {!openSuccess && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {file ? (
                          <>
                            <div
                              style={{
                                display: "flex",
                                gap: "10px",
                                alignItems: "center",
                              }}
                            >
                              <AttachIcon />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                }}
                              >
                                <Typography
                                  sx={{
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    maxWidth: "100px",
                                  }}
                                  variant="p"
                                >
                                  {file?.name}
                                </Typography>
                              </div>
                              <RemoveIcon
                                sx={{ color: "red" }}
                                onClick={() => {
                                  setfile(null);
                                  setNoQuote(false);
                                }}
                                cursor={"pointer"}
                              />
                            </div>
                          </>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              gap: "12px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              {" "}
                              <UploadIcon color="#0691FC" />
                              <h2
                                style={{
                                  color: "#0691FC",
                                  fontSize: "12px",
                                  fontWeight: 500,
                                }}
                              >
                                <input
                                  type="file"
                                  onChange={(e) => {
                                    const selectedFile = e.target.files?.[0];
                                    const allowedTypes = [
                                      "application/pdf",
                                      "image/jpeg",
                                      "image/png",
                                    ];

                                    // Check file type
                                    if (
                                      !allowedTypes.includes(selectedFile.type)
                                    ) {
                                      message.error(
                                        "Only PDF, JPG, and PNG files are allowed.",
                                      );
                                      return;
                                    }
                                    if (selectedFile) {
                                      const maxSize = 5 * 1024 * 1024; // 5 MB in bytes
                                      if (selectedFile.size > maxSize) {
                                        message.error(
                                          "File size should not exceed 5 mb",
                                        );
                                        return;
                                      }
                                      setfile(selectedFile);
                                      setNoQuote(true);
                                    }
                                  }}
                                  name="uploadfile"
                                  id="img"
                                  style={{ display: "none" }}
                                />
                                <label
                                  htmlFor="img"
                                  style={{ cursor: "pointer" }}
                                >
                                  Upload Quote
                                </label>
                              </h2>
                            </div>
                            <div
                              role="button"
                              tabIndex={0}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                                cursor: "pointer",
                              }}
                              onClick={() => setConfirm(true)}
                            >
                              {" "}
                              <Close style={{ color: "#DF0000" }} />
                              <p
                                style={{
                                  color: "#DF0000",
                                  fontSize: "12px",
                                  fontWeight: "500",
                                }}
                              >
                                Quote Unavailable
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {confirm && (
                    <DialogBox
                      width={500}
                      open={confirm}
                      setOpen={setConfirm}
                      children={
                        <CutomeModalNoQuote
                          setOpen={setConfirm}
                          title={"Are you sure?"}
                          // subTitle={
                          //   "You will be notified once partner accept/reject the quotes"
                          // }
                          confirmButton={"No quotes available"}
                          cancelButton={"Cancel"}
                          onClick={() => {
                            handleSubmit(i, false);
                            setConfirm(false);
                          }}
                        />
                      }
                    />
                  )}
                  {openSuccess ? (
                    <SuccessModal
                      title={"Quote Shared Successfully!"}
                      // subTitle={declineData?"Same has been notified to partner":"You will be notified once partner accept/reject the quotes"}
                      // shareButton="Okay"
                      btnDisabled
                    />
                  ) : (
                    <div
                      style={{
                        backgroundColor: "#fff",
                        padding: "12px",
                        borderRadius: "16px",
                      }}
                    >
                      <h2
                        style={{
                          fontSize: "12px",
                          fontWeight: 600,
                          marginBottom: "14px",
                          color: "#000",
                        }}
                      >
                        Request Details
                      </h2>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: "20px",
                        }}
                      >
                        {data?.data?.changeRequested?.map((i, index) => (
                          <div key={`change-request-${index}`}>
                            {i?.value && (
                              <h3
                                style={{
                                  fontSize: "12px",
                                  fontWeight: 400,
                                  color: "#808080",
                                }}
                              >
                                {i?.key}{" "}
                              </h3>
                            )}
                            <p
                              style={{
                                fontSize: "12px",
                                fontWeight: 400,
                                color: "#181818",
                              }}
                            >
                              {i?.key === "Add Ons" && i?.value?.length > 0
                                ? i?.value[0]?.join(", ")
                                : ""}
                              {i?.key !== "Add Ons" && i?.value}
                              {i?.key === "NCB" || i?.key === "Discount"
                                ? "%"
                                : ""}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {openNoQuote && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "right",
                      }}
                    >
                      <Button
                        style={{
                          backgroundColor: "#0691FC",
                          textTransform: "none",

                          color: "#fff",
                          borderRadius: "8px",
                          fontSize: "14px",
                          fontWeight: 600,
                          width: "153px",
                          // height: "45px",
                        }}
                        onClick={() => handleSubmit(i, true)}
                      >
                        Share Quote
                      </Button>
                    </div>
                  )}
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    borderRadius: "8px",
                    boxShadow: "0px 2px 4px 0px #0000001A",
                    flexDirection: "column",
                    overflow: "hidden",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      backgroundColor: "#fff",
                      padding: "8px 20px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {i?.insurerId && InsurerLogoMap[i?.insurerId] ? (
                        <img
                          src={InsurerLogoMap[i?.insurerId]?.Logo}
                          alt="insurer"
                        />
                      ) : (
                        <p>{i?.insurerName}</p>
                      )}
                      {i?.quoteStatus ===
                        quoteStatusList.ACCEPTED_WITH_INSPECTION && (
                        <div
                          style={{
                            backgroundColor: "#09CE1D",
                            padding: "10px",
                            borderRadius: "4px",
                            display: "flex",
                            gap: "5px",
                            marginLeft: 12,
                          }}
                        >
                          <img src={checkImg} alt="check" />{" "}
                          <h3
                            style={{
                              color: "#fff",
                              fontSize: "10px",
                              fontWeight: 500,
                            }}
                          >
                            Quote Shared with inspection
                          </h3>
                        </div>
                      )}
                    </div>

                    {/* </div> */}
                    {i?.quoteStatus !== quoteStatusList.UNAVAILABLE ? (
                      <Link
                        to={i?.quoteUrl}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        {" "}
                        <RemoveRedEyeOutlined />
                        <h2
                          style={{
                            color: "#666666",
                            fontSize: "12px",
                            fontWeight: 500,
                          }}
                        >
                          View Quote{" "}
                        </h2>
                      </Link>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8x",
                        }}
                      >
                        <h2
                          style={{
                            backgroundColor: "#E6E6E6",
                            fontSize: "10px",
                            padding: "2px 4px",
                            borderRadius: "4px",
                            fontWeight: 500,
                            color: "#808080",
                          }}
                        >
                          Quote Unavailable
                        </h2>
                      </div>
                    )}
                  </div>
                  {i?.addOns?.length > 0 && <Addons addOns={i.addOns} />}
                </div>
              )}
            </>
          ))}
        </div>
      </div>
    </div>
    // </div>
  );
};

export default QuoteChangeRequest;
