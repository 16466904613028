import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import Search from "../../components/generic/Search/Search";
import Header from "../../components/generic/Header/Header";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "flatpickr/dist/flatpickr.css";
import CustomTabing from "../../components/generic/Tabing/CustomTabing";
import { debounce, formatDate } from "../../utils/utils";
import { fetchParterIdsList } from "../../store/action/auth";
import Paginations from "../../components/generic/Pagination/Pagination";
import {
  getSalesQuoteListAPI,
  RESET_SALES_QUOTE,
} from "../../store/action/salesQuote";
import SalesQuoteTable from "../../components/Table/SalesQuoteTable";
import NewAutoComplete from "../../components/generic/NewAutoComplete/NewAutoComplete.jsx";
import DatePicker from "../../components/generic/DatePicker/DatePicker.jsx";
export default function SalesQuoteRequest() {
  const dispatch = useDispatch();
  const { quoteList, isLoading, quoteBucket, pagination } = useSelector(
    (state) => state.salesQuote,
  );
  const { partnerIds } = useSelector((state) => state.auth);

  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(pagination?.curPage || 1);
  const [filterByDate, setFilterByDate] = useState({
    startDate: "",
    endDate: "",
  });
  const handleDateChange = (date) => {
    if (date && date.length > 0) {
      setFilterByDate({ startDate: date[0], endDate: date[1] });
    }

    if (date && date.length === 0) {
      setFilterByDate({ startDate: "", endDate: "" });
    }
  };
  const handlePageChange = (event, page) => {
    if (page > pagination?.totalPage) {
      return;
    }
    setCurrentPage(page);
  };

  //add debounced search

  const handleSearch = (query) => {
    setSearch(query);
  };
  const [selectedPartner, setSelectedPartner] = useState("");
  // const [currentQuoteId, setCurrentQuoteId] = useState("");
  const [currentBucket, setCurrentBucket] = useState();

  const handleTabChange = (newValue) => {
    setCurrentBucket(newValue);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (!partnerIds) {
      dispatch(fetchParterIdsList());
    }
  }, [partnerIds]);

  const debouncedGetSalesQuoteApi = useCallback(
    debounce((searchParams) => {
      dispatch(getSalesQuoteListAPI(searchParams));
    }, 500),
    [dispatch],
  );

  useEffect(() => {
    let searchParams = {};
    if (selectedPartner) {
      searchParams["partnerId"] = selectedPartner;
    }
    if (search) {
      if (search.length < 3) return;
      searchParams["searchQuery"] = search;
    }
    if (filterByDate.startDate && filterByDate.endDate) {
      searchParams["startDate"] = formatDate(filterByDate.startDate);
      searchParams["endDate"] = formatDate(filterByDate.endDate);
    }
    if (currentPage) {
      searchParams["curPage"] = currentPage;
    }
    if (currentBucket) {
      searchParams["curBucket"] = currentBucket;
    }
    debouncedGetSalesQuoteApi(searchParams);
  }, [search, selectedPartner, filterByDate, currentPage, currentBucket]);

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_SALES_QUOTE });
    };
  }, []);

  return (
    <main className="main">
      <Header title="Quote Requests" />
      <div className="">
        <header>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Search
              value={search}
              onChange={(e) => handleSearch(e.target.value)}
            />
            <Stack direction="row" spacing={2} alignItems="center">
              <label htmlFor="selectPartnerDropDown" className="filter-label">
                Filters :
              </label>
              <Typography sx={{ width: "175px" }}>
                <NewAutoComplete
                  options={
                    partnerIds?.map((partnerData) => ({
                      label: partnerData.partnerId + " | " + partnerData.name,
                      value: partnerData.partnerId,
                    })) || []
                  }
                  placeholder="Select Partner"
                  className="select_dropdown"
                  onChange={(selectedPartner) => {
                    setSelectedPartner(selectedPartner);
                  }}
                  isMulti={false}
                  value={selectedPartner}
                  extraStyle={{ height: "40px" }}
                />
              </Typography>
              <div>
                <DatePicker
                  className="date-picker-multi"
                  placeholder="Date Range"
                  options={{
                    mode: "range",
                  }}
                  style={{ height: "40px" }}
                  value={
                    filterByDate.startDate && filterByDate.endDate
                      ? [filterByDate.startDate, filterByDate.endDate]
                      : ""
                  }
                  onChange={handleDateChange}
                />
              </div>
            </Stack>
          </Stack>
          <CustomTabing
            tabList={quoteBucket?.bucketList || []}
            value={quoteBucket?.curBucket}
            handleChange={handleTabChange}
          />
        </header>
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            <SalesQuoteTable
              rows={quoteList}
            />
          </Box>
        )}
      </div>
      <Box style={{ marginTop: "20px" }}>
        <Paginations
          currentPage={currentPage}
          totalPage={pagination?.totalPage || 1}
          handlePageChange={handlePageChange}
        />
      </Box>
    </main>
  );
}
