import React, { useEffect, useState } from "react";
import "./index.css";
import Heading from "../../components/generic/Heading/Heading";
import Header from "../../components/generic/Header/Header";
import {
  Button,
  FormGroup,
  Stack,
  TextField,
  Grid,
  Typography,
  Box,
} from "@mui/material";
import DropDown from "../../components/generic/DropDown/DropDown";
import { useDispatch, useSelector } from "react-redux";
import {
  AddPartnerAPI,
  hierarchylistAPI,
  verifyMobileAPI,
  verifyOtpAPI,
  verifyPanAPI,
} from "../../store/action/addPartner";
import CustomModalBox from "../../components/Form/CustomModal";
import DialogBox from "../../components/generic/DialogBox";

const AddNewPartner = () => {
  const dispatch = useDispatch();
  const partnerhierachy = useSelector((state) => state.partner?.hierachystate);
  const [partnerType, setPartnerType] = useState("FP");
  const [phone, setPhone] = useState();
  const [phoneDisable, setPhoneDisable] = useState(null);
  const [otp, setOtp] = useState();
  const [otpBox, setOtpBox] = useState(false);
  const [panName, setPanName] = useState();
  const [panNumber, setPanNumber] = useState();
  const [isPan, setIsPan] = useState(false);
  const [isPanLoading, setIsPanLoading] = useState(false);
  const [seconds, setSeconds] = useState(45);
  const [value, setValue] = useState("");
  const [openModal, setOpenModal] = React.useState(false);

  useEffect(() => {
    if (openModal) {
      setPhone("");
      setPhoneDisable(null);
      setPanName("");
      setPanNumber("");
      setOtpBox(false);
      setValue("");
    }
  }, [openModal]);
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    // Use regular expression to format the PAN input
    const formattedValue = inputValue.toUpperCase().replace(/[^A-Z0-9]/g, "");
    // Validate PAN format (Five letters, four numbers, one letter)
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    const isValidPan = panRegex.test(formattedValue);
    setPanNumber(formattedValue);
    setIsPan(true);
    if (isValidPan) {
      setIsPan(false);
    }
  };

  const handleVerifyMobile = () => {
    const data = {
      contactNumber: phone,
    };
    dispatch(verifyMobileAPI(data, setOtpBox, setPhoneDisable, setSeconds));
  };

  const handleVerifyOtp = () => {
    const data = {
      contactNumber: phone,
      otp: otp,
      userType: partnerType,
    };
    dispatch(verifyOtpAPI(data, setOtpBox, setPhoneDisable));
  };

  const handleVerifyPan = () => {
    const data = {
      contactNumber: phone,
      panName: panName,
      panNumber: panNumber,
      consentText:
        "I hear by declare my consent agreement for fetching my information via ZOOP API",
      consent: true,
    };
    dispatch(verifyPanAPI(data, setIsPanLoading));
  };

  const handleSubmit = () => {
    const data = {
      contactNumber: phone,
      referalCode: filteredList?.partnerId,
    };
    dispatch(AddPartnerAPI(data, setOpenModal));
  };

  useEffect(() => {
    if (otpBox && seconds) {
      const interval =
        seconds > 0 &&
        setInterval(() => {
          setSeconds((prevSeconds) => prevSeconds - 1);
        }, 1000);
      return () => clearInterval(interval);
    }
  }, [seconds, otpBox]);

  useEffect(() => {
    if (seconds === 0) {
      setSeconds(0);
    }
  }, [seconds]);
  useEffect(() => {
    if (partnerType) {
      dispatch(hierarchylistAPI(partnerType));
    }
  }, [partnerType]);
  const partnerList = partnerhierachy?.data.map((item) => item?.partnerName);
  const filteredList = partnerhierachy?.data?.find(
    (item) => item?.partnerName === value,
  );

  return (
    <>
      <Header title="Add Partners" />
      <div className="list-wrapper add-new-wrapper">
        <FormGroup>
          <Heading
            type={"withborder"}
            className="no-margin"
            text="Type of Partner"
          />
          <Stack direction={"row"} gap={3} alignItems={"center"} sx={{ mt: 3 }}>
            <Button
              variant="outline"
              sx={{
                textTransform: "unset",
                border: "1px solid transparent",
                borderColor: partnerType === "FP" && "var(--color-primary)",
                borderRadius: 2,
                padding: 2.5,
                background:
                  partnerType === "FP"
                    ? "var(--color-primary-light)"
                    : "var(--color-grey-95)",
                color: partnerType === "FP" && "var(--color-primary)",
                minWidth: 209,
              }}
              onClick={() => setPartnerType("FP")}
            >
              Firm
            </Button>
            <Button
              variant="outline"
              sx={{
                textTransform: "unset",
                border: "1px solid transparent",
                borderColor: partnerType === "EP" && "var(--color-primary)",
                borderRadius: 2,
                padding: 2.5,
                background:
                  partnerType === "EP"
                    ? "var(--color-primary-light)"
                    : "var(--color-grey-95)",
                color: partnerType === "EP" && "var(--color-primary)",
                minWidth: 209,
              }}
              onClick={() => setPartnerType("EP")}
            >
              Enterprise
            </Button>
          </Stack>
        </FormGroup>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormGroup sx={{ marginTop: 5 }}>
              <Heading
                type={"withborder"}
                className="no-margin"
                text="Phone Verification"
              />

              <Box sx={{ position: "relative", width: "100%" }}>
                <TextField
                  disabled={phoneDisable === null ? false : true}
                  placeholder="Enter Mobile Number * "
                  sx={{ mt: 2, width: "inherit" }}
                  inputProps={{ maxLength: 10 }}
                  value={phone}
                  onInput={(event) => {
                    event.target.value = event.target.value.replace(
                      /[^0-9]/gi,
                      "",
                    );
                  }}
                  onChange={(e) => setPhone(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <>
                        {phoneDisable === null ? (
                          <Button
                            disabled={phone?.length > 9 ? false : true}
                            onClick={handleVerifyMobile}
                            className="verify-OTP-button"
                            sx={{
                              background: "var(--color-primary)",
                              textTransform: "capitalize",
                            }}
                            variant="contained"
                          >
                            Send OTP
                          </Button>
                        ) : phoneDisable === "true" ? (
                          <Box sx={{ display: "flex", gap: 1 }}>
                            <img src="/check.svg" alt="CheckIcon" />
                            <Typography
                              sx={{
                                minWidth: "100px",

                                // mr: "8px",
                              }}
                              className="send-OTP"
                              variant="text"
                            >
                              OTP Sent
                            </Typography>
                            <img
                              onClick={() => {
                                setPhoneDisable(null);
                                setOtpBox(false);
                              }}
                              src="/editIcon.svg"
                              style={{
                                cusrsor: "pointer",
                                position: "absolute",
                                left: "28%",
                                top: "35%",
                              }}
                              alt="EditIcon"
                            />
                          </Box>
                        ) : (
                          <Typography
                            sx={{
                              minWidth: "250px",
                            }}
                            className="send-OTP"
                            variant="text"
                          >
                            Number Verified Successfully
                          </Typography>
                        )}
                      </>
                    ),
                  }}
                />
              </Box>
            </FormGroup>
          </Grid>
        </Grid>
        {otpBox && (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormGroup sx={{ marginTop: 2 }}>
                <TextField
                  placeholder="Enter OTP here"
                  sx={{ mt: 2 }}
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  inputProps={{ maxLength: 4 }}
                  onInput={(event) => {
                    event.target.value = event.target.value.replace(
                      /[^0-9]/gi,
                      "",
                    );
                  }}
                  InputProps={{
                    endAdornment: (
                      <Button
                        onClick={handleVerifyOtp}
                        disabled={otp?.length > 3 ? false : true}
                        className="verify-OTP-button"
                        variant="contained"
                        sx={{ textTransform: "capitalize" }}
                      >
                        Verify OTP
                      </Button>
                    ),
                  }}
                />
              </FormGroup>
              <Typography sx={{ mt: 1 }}>
                <Button
                  disabled={seconds}
                  className="text-primary"
                  style={{ cursor: "pointer", textTransform: "capitalize" }}
                  onClick={handleVerifyMobile}
                >
                  Resend OTP
                </Button>{" "}
                in 00:{seconds}
              </Typography>
            </Grid>
          </Grid>
        )}

        {isPanLoading ? (
          <FormGroup sx={{ marginTop: 5 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                py: 4.1,
              }}
            >
              <img
                src="/search.gif"
                style={{ width: "28px" }}
                alt="searchIcon"
              />
              <Typography sx={{ fontWeight: 700, color: "#000" }}>
                We are matching your PAN details
              </Typography>
            </Box>
          </FormGroup>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormGroup sx={{ marginTop: 5 }}>
                <Heading
                  type={"withborder"}
                  className="no-margin"
                  text="Enter Name as per PAN"
                />
                <TextField
                  value={panName}
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^A-Za-z]/gi, " ");
                    setPanName(value);
                  }}
                  sx={{ mt: 2 }}
                  placeholder="Enter Name *"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={6}>
              <FormGroup sx={{ marginTop: 5 }}>
                <Heading
                  type={"withborder"}
                  className="no-margin"
                  text="PAN Verification"
                />
                <TextField
                  value={panNumber}
                  inputProps={{ maxLength: 10 }}
                  onChange={handleInputChange}
                  placeholder="Enter PAN Number"
                  sx={{ mt: 2 }}
                  InputProps={{
                    endAdornment: (
                      <Button
                        onClick={handleVerifyPan}
                        disabled={
                          panName?.length > 1 && panNumber?.length > 9 && !isPan
                            ? false
                            : true
                        }
                        className="verify-OTP-button"
                        sx={{
                          background: "#52CE6E",
                          textTransform: "capitalize",
                        }}
                        variant="contained"
                      >
                        Verify PAN
                      </Button>
                    ),
                  }}
                />
              </FormGroup>
              {isPan && (
                <span style={{ color: "#FF2222" }}>Wrong PAN details</span>
              )}
            </Grid>
          </Grid>
        )}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormGroup sx={{ marginTop: 5 }}>
              <Heading
                type={"withborder"}
                className="no-margin"
                text="Partner Tagging"
              />
              <Box sx={{ mt: 2 }} />
              <DropDown
                value={value}
                onChange={(e) => setValue(e.target.value)}
                data={partnerList}
                placeholder="Select Partner Tagging"
                height={56}
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Stack
          direction={"row"}
          gap={3}
          justifyContent={"flex-end"}
          sx={{ mt: 3 }}
        >
          <Button
            disabled={phone?.length > 9 ? false : true}
            onClick={handleSubmit}
            className="verify-OTP-button"
            sx={{
              background: "var(--color-primary)",
              textTransform: "capitalize",
            }}
            variant="contained"
          >
            SUBMIT
          </Button>
        </Stack>
        {openModal && (
          <DialogBox
            width={500}
            open={openModal}
            setOpen={setOpenModal}
            children={
              <CustomModalBox
                setOpen={setOpenModal}
                title={`${partnerType} code has been created!`}
              />
            }
          />
        )}
      </div>
    </>
  );
};

export default AddNewPartner;
