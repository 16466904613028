import { Autocomplete, Checkbox, Chip, TextField } from "@mui/material";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import React from "react";

const NewAutoComplete = React.forwardRef(
  (
    {
      value,
      options,
      placeholder,
      isMulti,
      onChange,
      isSelectAllAllowed = true,
      extraStyle = {},
      error,
      onInputChange,
      ...rest
    },
    ref,
  ) => {
    const selectAllOption = {
      label: "Select All",
      value: "selectAll",
    };

    const multiOptions = [];
    if (isSelectAllAllowed) {
      multiOptions.push(selectAllOption);
    }
    multiOptions.push(...options);

    const newOptions = isMulti ? multiOptions : options;
    const isAllSelected = isMulti && value?.length === options.length;

    const handleChange = (event, newValue) => {
      if (isMulti) {
        newValue = newValue || [];
        if (newValue.some((option) => option.value === "selectAll")) {
          if (isAllSelected) {
            onChange([]);
          } else {
            onChange(options.map((option) => option.value));
          }
        } else {
          onChange(newValue.map((option) => option.value));
        }
      } else {
        onChange(newValue ? newValue.value : "");
      }
    };

    return (
      <Autocomplete
        sx={{
          backgroundColor: "#fff",
          "& .MuiAutocomplete-inputRoot": {
            display: "flex",
            alignItems: "center",
            fontSize: "12px",
            fontWeight: "500",
            borderRadius: "8px",
            minHeight: "40px",
            minWidth: "180px",
            ...extraStyle,
          },
          "& .MuiOutlinedInput-root": {
            "&:hover": {
              "& fieldset": {
                border: "1px solid #0691fc",
              },
            },
            "&.Mui-focused fieldset": {
              border: "1px solid #0691fc",
            },
          },
          "& .MuiAutocomplete-inputRoot.Mui-disabled": {
            backgroundColor: "#cccccc",
          },
        }}
        {...rest}
        ref={ref}
        multiple={isMulti}
        disableCloseOnSelect={isMulti}
        options={newOptions}
        getOptionLabel={(option) => option.label}
        filterSelectedOptions={false}
        renderOption={(props, option, { selected }) => (
          <li {...props} style={{ fontSize: "12px", fontWeight: "400" }}>
            {isMulti && (
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={
                  option.value === "selectAll" ? isAllSelected : selected
                }
              />
            )}
            {option.label}
          </li>
        )}
        renderTags={(tagValue, getTagProps) => {
          if (isMulti) {
            if (tagValue.length > 1) {
              return [
                <React.Fragment key={tagValue[0].value}>
                  <Chip
                    size="small"
                    variant="outlined"
                    key={tagValue[0].value}
                    label={tagValue[0].label.substring(0, 4)}
                    {...getTagProps({ index: 0 })}
                  />
                  +{tagValue.length - 1}
                </React.Fragment>,
              ];
            } else {
              return tagValue.map((option, index) => (
                <Chip
                  size="small"
                  variant="outlined"
                  key={option.value}
                  label={option.label.substring(0, 4)}
                  {...getTagProps({ index })}
                />
              ));
            }
          } else {
            return tagValue.map((option, index) => (
              <Chip
                size="small"
                variant="outlined"
                key={option.value}
                label={option.label.substring(0, 4)}
                {...getTagProps({ index })}
              />
            ));
          }
        }}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            variant="outlined"
            error={!!error}
            helperText={error}
            InputProps={{
              ...params.InputProps,
              style: {
                "--placeholder-color": "black", // Custom placeholder color as a CSS variable
              },
              endAdornment: <>{params.InputProps.endAdornment}</>,
            }}
            sx={{
              "& .MuiOutlinedInput-input::placeholder": {
                color: "rgb(130, 130, 130)", // Update the color here
                opacity: 1, // Ensure visibility in some browsers
              },
              "& .MuiFormHelperText-root": {
                width: "100%",
                marginTop: "0.25rem",
                fontSize: "12px !important",
                color: "#dc3545",
                height: "0.9em",
                fontWeight: "400 !important",
              },
            }}
          />
        )}
        value={
          isMulti
            ? value && Array.isArray(value)
              ? options.filter((option) => value.includes(option.value))
              : []
            : value
              ? options.find((option) => option.value == value)
              : null
        }
        onInputChange={onInputChange} // For handling search input
      />
    );
  },
);

NewAutoComplete.displayName = "NewAutoComplete";

export default NewAutoComplete;
