import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Heading from "../Heading/Heading";
import { useSelector } from "react-redux";

export default function BasicAccordion() {
  const quoteData = useSelector((state) => state.quote.quoteDetails);
  const salesQuoteData = useSelector((state) => state.salesQuote.quoteDetails);
  const data = quoteData || salesQuoteData;

  return (
    <div
      style={{
        overflowY: "scroll",
        height: "calc(100vh - 90px)",
        overflowX: "hidden",
        maxWidth: "310px",
      }}
    >
      <div
        style={{
          marginBottom: "24px",
        }}
      >
        <Heading text="Requested Details" type={"withborder"} />
      </div>
      <div>
        <Accordion disableGutters sx={{ boxShadow: "none" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <img src="/Car.svg" alt="car" style={{ marginRight: "8px" }} />
            <Typography
              sx={{ fontSize: "12px", color: "#181818", fontWeight: 400 }}
            >
              {"Customer Details"}
            </Typography>
          </AccordionSummary>
          <div
            style={{
              flexWrap: "wrap",
              display: "flex",
              gap: "4px",
              justifyContent: "space-between",
            }}
          >
            {data?.data?.customerDetails.map(
              (i, index) =>
                i?.value && (
                  <AccordionDetails key={index}>
                    <p
                      style={{
                        fontSize: "10px",
                        color: "var(--color-grey-40)",
                      }}
                    >
                      {i?.key}
                    </p>
                    <p style={{ fontSize: "10px", color: "#000" }}>
                      {i?.value}
                    </p>
                  </AccordionDetails>
                ),
            )}
          </div>
        </Accordion>
        <Accordion disableGutters sx={{ boxShadow: "none" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <img src="/Car.svg" alt="car" style={{ marginRight: "8px" }} />
            <Typography
              sx={{ fontSize: "12px", color: "#181818", fontWeight: 400 }}
            >
              {"Vehicle Details"}
            </Typography>
          </AccordionSummary>
          <div
            style={{
              flexWrap: "wrap",
              display: "flex",
              gap: "4px",
              justifyContent: "space-between",
            }}
          >
            {data?.data?.vehicleDetails.map(
              (i, index) =>
                i?.value && (
                  <AccordionDetails key={index}>
                    {i?.value && (
                      <p
                        style={{
                          fontSize: "10px",
                          color: "var(--color-grey-40)",
                        }}
                      >
                        {i?.key}
                      </p>
                    )}
                    <p style={{ fontSize: "10px", color: "#000" }}>
                      {i?.value}
                    </p>
                  </AccordionDetails>
                ),
            )}
          </div>
        </Accordion>
        <Accordion disableGutters sx={{ boxShadow: "none" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <img src="/Car.svg" alt="car" style={{ marginRight: "8px" }} />
            <Typography
              sx={{ fontSize: "12px", color: "#181818", fontWeight: 400 }}
            >
              {"PYP Details"}
            </Typography>
          </AccordionSummary>
          <div
            style={{
              flexWrap: "wrap",
              display: "flex",
              gap: "4px",
              justifyContent: "space-between",
            }}
          >
            {data?.data?.pypDetails.map(
              (i, index) =>
                i?.value && (
                  <AccordionDetails key={index}>
                    {i?.value && (
                      <p
                        style={{
                          fontSize: "10px",
                          color: "var(--color-grey-40)",
                        }}
                      >
                        {i?.key}
                      </p>
                    )}
                    <p style={{ fontSize: "10px", color: "#000" }}>
                      {i?.value}
                    </p>
                  </AccordionDetails>
                ),
            )}
          </div>
        </Accordion>
        <Accordion disableGutters sx={{ boxShadow: "none" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <img src="/Car.svg" alt="car" style={{ marginRight: "8px" }} />
            <Typography
              sx={{ fontSize: "12px", color: "#181818", fontWeight: 400 }}
            >
              {"Request Details"}
            </Typography>
          </AccordionSummary>
          <div
            style={{
              flexWrap: "wrap",
              display: "flex",
              gap: "4px",
              // justifyContent: "space-between",
            }}
          >
            {data?.data?.quoteRequestDetails.map(
              (i, index) =>
                i?.value && (
                  <AccordionDetails key={index}>
                    {i?.value && (
                      <p
                        style={{
                          fontSize: "10px",
                          color: "var(--color-grey-40)",
                        }}
                      >
                        {i?.key}
                      </p>
                    )}
                    <p style={{ fontSize: "10px", color: "#000" }}>
                      {i?.value}
                    </p>
                  </AccordionDetails>
                ),
            )}
          </div>
        </Accordion>
      </div>
      <div>
        <div
          style={{
            marginBottom: "24px",
          }}
        >
          <Heading text="Documents" type={"withborder"} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            borderRadius: "14px",
            backgroundColor: "#fff",
          }}
        >
          {data?.data?.documents?.map((i, index) => (
            <div
              key={`document-${index}`}
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "14px",
                borderBottom: "2px solid #fafafa",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <img src="/docsIcon.svg" alt="docIcon" />
                <h1
                  style={{
                    fontSize: "12px",
                    fontWeight: 400,
                    color: "#181818",
                  }}
                >
                  {i?.documentName}
                </h1>
              </div>
              <div>
                <a
                  href={i?.documentUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ width: 26, height: 26, marginLeft: 12 }}
                >
                  <img src="/eyeIcon.svg" alt="view" />
                </a>
                <a
                  href={i?.documentUrl}
                  target="blank"
                  download
                  style={{ width: 26, height: 26, marginLeft: 12 }}
                >
                  <img src="/downloadIcon.svg" alt="download" />
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
