import { combineReducers } from "redux";
import authReducer from "./auth";
import partnerReducer from "./partner";
import quoteReducer from "./quote";
import mappingRequestsReducer from "./mappingRequests";
import gridReducer from "./grid";
import salesMappingReducer from "./salesMapping";
import { salesPartnerReducer } from "./salesPartner";
import salesMappingRaiseReducer from "./salesMappingRequest.js";
import salesQuoteReducer from "./salesQuote";
import { salesBookingReducer } from "./salesBooking";
import insurerReducer from "./insurers";
import { salesDashboardReducer } from "./dashboard";
import { salesManagerReducer } from "./salesManager.js";
import { salesPartnerManagerReducer } from "./salesManagerPartner.js";
import { salesManagerBookingReducer } from "./salesManagerBooking.js"

export default combineReducers({
  auth: authReducer, // Authentication
  partner: partnerReducer, // List
  quote: quoteReducer, // quote
  mappingRequests: mappingRequestsReducer, // Mapping Requests
  grid: gridReducer,
  salesMapping: salesMappingReducer, // Sales Mapping Requests
  salesPartner: salesPartnerReducer,
  salesMapingRaise: salesMappingRaiseReducer,
  salesQuote: salesQuoteReducer,
  salesBooking: salesBookingReducer, // Sales Booking
  insurers: insurerReducer,
  salesDashboard: salesDashboardReducer, // Sales Dashboard
  salesManagerDashboard: salesManagerReducer, //Manager Dashboard
  salesPartnerManager: salesPartnerManagerReducer, //Manager Partner Dashboard
  salesManagerBooking: salesManagerBookingReducer, // Sales Manager Bookings
});
