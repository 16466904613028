import React from "react";
import { ReactComponent as NoDataFoundIcon } from "../../../assets/icons/NoDataFoundIcon.svg";


const NoDataFound = () => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "30px",
          flexDirection: "column",
        }}
      >
        <NoDataFoundIcon />
        <span style={{fontSize: "16px", marginTop: "8px"}}>No Data Found</span>
      </div>
    </div>
  );
};

export default NoDataFound;
