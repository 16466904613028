import React from "react";
import PolicyDealsHeader from "./PolicyDealsHeader";
import PolicyOfferCard from "../../../components/PolicyOfferCard/PolicyOfferCard";
import DealsListing from "../DealsListing/DealsListing";
import "./PolicyDeals.css";

const PolicyDeals = ({ policyHeaderData, policyOffersData }) => {
  return (
    <div className="policy-deals-container">
      <PolicyDealsHeader data={policyHeaderData} />
      {policyHeaderData.date && (
        <div className="policy-deals-date">{policyHeaderData.date}</div>
      )}
      <DealsListing>
        {policyOffersData?.declineDeals?.length > 0 &&
          policyOffersData.declineDeals.map((deal, index) => (
            <div key={index} className="deals-list-item">
              <PolicyOfferCard
                index={index}
                key={`decline_deal_${index}`}
                policyDetails={deal}
                dealType="decline_deal"
              />
            </div>
          ))}

        {policyOffersData?.specialDeals?.length > 0 &&
          policyOffersData.specialDeals.map((deal, index) => (
            <div key={index} className="deals-list-item">
              <PolicyOfferCard
                index={index}
                key={`special_deal_${index}`}
                policyDetails={deal}
                dealType="special_deal"
              />
            </div>
          ))}

        {policyOffersData?.marketDeals?.length > 0 &&
          policyOffersData?.marketDeals.map((deal, index) => (
            <div
              key={`market_deal_container_${index}`}
              className="deals-list-item"
            >
              <PolicyOfferCard
                index={index}
                key={`market_deal_${index}`}
                policyDetails={deal}
                dealType="market_deal"
              />
            </div>
          ))}
      </DealsListing>
    </div>
  );
};

export default PolicyDeals;
