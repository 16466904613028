import React, { useEffect } from "react";
import Done from "../../assets/icons/done.gif";

import { Box, Button, Typography } from "@mui/material";

const CustomModalBox = ({
  setOpen,
  title,
  subTitle,
  shareButton,
  handleClose,
}) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(false);
    }, 4000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        gap: 2,
        alignItems: "center",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <img src={Done} alt="" />
      </Box>
      <Typography sx={{ fontWeight: "600", textAlign: "center", fontSize: 24 }}>
        {" "}
        {title}
      </Typography>
      <Typography
        sx={{
          fontWeight: "400",
          textAlign: "center",
          fontSize: 14,
          color: "#666666",
        }}
      >
        {" "}
        {subTitle}
      </Typography>
      <Button
        variant="contained"
        sx={{
          borderRadius: "8px",
          backgroundColor: "#0691FC",
          width: "192px",
          color: "#fff",
          height: "40px",
        }}
        onClick={() => handleClose()}
      >
        {shareButton}
      </Button>
    </Box>
  );
};

export default CustomModalBox;
