const inititalState = {
  isAuthenticated: false,
  access_token: null,
  user: null,
  permissions: null,
  partnerIds: null,
  loading: true,
};

export default function authReducer(state = inititalState, action) {
  switch (action.type) {
    case "USER_LOGIN_SUCCESS":
      window.localStorage.setItem("token", action.data);
      return {
        ...state,
        isAuthenticated: true,
        access_token: action.data,
      };
    case "GET_USER":
      return {
        ...state,
        user: action.data,
      };
    case "FETCH_PERMISSIONS_SUCCESS":
      return {
        ...state,
        permissions: action.data,
        loading: false,
      };
    case "FETCH_PARTNER_IDS":
      return {
        ...state,
        partnerIds: action.data,
      };
    case "UNAUTHORIZED":
      localStorage.removeItem("token");
      localStorage.removeItem("user");

      return {
        ...state,
        isAuthenticated: false,
        permissions: null, // Reset permissions on unauthorized
      };

    case "LOGOUT_SUCCESSFULL":
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      return {
        ...state,
        isAuthenticated: false,
        permissions: null, // Reset permissions on logout
      };
    default:
      return state;
  }
}
