import {
  GET_PARTNER_LIST_SUCCESS,
  GET_PARTNER_LIST_FAILURE,
  SET_CURRENT_PAGE,
  SALES_PARTNER_LOADING,
} from "../action/salesPartner";

const initialState = {
  partnerList: [],
  pagination: {
    curPage: 1, // Track the current page
    totalPage: 0,
  },

  partnerBucket: {
    curBucket: "",
    bucketList: [],
  },

  loading: true,
  error: null,
};

export const salesPartnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PARTNER_LIST_SUCCESS:
      return {
        ...state,
        partnerList: action.payload.partnerList, // Update with API data
        pagination: {
          ...state.pagination,
          ...action.payload.pagination, // Update pagination details
        },
        partnerBucket: {
          ...state.partnerBucket,
          curBucket: action.payload.partnerBucket.curBucket, // Directly update curBucket
          bucketList: action.payload.partnerBucket.bucketList, // Update bucketList
        },
        loading: false,
        error: null,
      };
    case GET_PARTNER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case SET_CURRENT_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          curPage: action.payload.curPage, // Update current page
        },
      };
    case SALES_PARTNER_LOADING:
      return {
        ...state,
        loading: action.data,
      };
    default:
      return state;
  }
};
