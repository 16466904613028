import { message } from "../../components/generic/SnakeAlert/SnakeAlert";
import { salesBookingList, salesBookingModal } from "../api";
import axios from "../api/axios";

// Action Types
export const GET_BOOKING_LIST_SUCCESS = "GET_BOOKING_LIST_SUCCESS";
export const GET_BOOKING_LIST_FAILURE = "GET_BOOKING_LIST_FAILURE";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const GET_BOOKING_MODAL_SUCCESS = "GET_BOOKING_MODAL_SUCCESS";
export const GET_BOOKING_MODAL_FAILURE = "GET_BOOKING_MODAL_FAILURE";
export const SALES_BOOKING_LOADING = "SALES_BOOKING_LOADING";
export const SALES_BOOKING_MODAL_LOADING = "SALES_BOOKING_MODAL_LOADING";

// Action Creator to fetch booking list
export const salesBookingListAction = (search) => async (dispatch) => {
  try {
    dispatch({ type: SALES_BOOKING_LOADING, data: true });
    const response = await axios.get(salesBookingList, {
      params: search,
    });

    if (response.status === 200) {
      const { bookingList, pagination, bookingBucket } = response.data.data;

      dispatch({
        type: GET_BOOKING_LIST_SUCCESS,
        payload: {
          bookingList,
          pagination,
          bookingBucket,
        },
      });
    } else {
      throw new Error(response.data.msg || "Failed to fetch booking list");
    }
  } catch (error) {
    dispatch({
      type: GET_BOOKING_LIST_FAILURE,
      error: error.message,
    });
    message.error("Error fetching booking list: " + error.message);
  } finally {
    dispatch({ type: SALES_BOOKING_LOADING, data: false });
  }
};

export const salesBookingModalAction = (bookingId) => async (dispatch) => {
  try {
    dispatch({ type: SALES_BOOKING_MODAL_LOADING, data: true });
    const response = await axios.get(salesBookingModal, {
      params: { bookingId },
    });

    if (response.status === 200) {
      const {
        headerData,
        vehicleDetails,
        policyDetails,
        premiumDetails,
        businessType,
        policyCopyUrl,
      } = response.data.data;

      dispatch({
        type: GET_BOOKING_MODAL_SUCCESS,
        payload: {
          headerData,
          vehicleDetails,
          policyDetails,
          premiumDetails,
          businessType,
          policyCopyUrl,
        },
      });
    } else {
      throw new Error(
        response.data.msg || "Failed to fetch booking modal data",
      );
    }
  } catch (error) {
    dispatch({
      type: GET_BOOKING_MODAL_FAILURE,
      error: error.message,
    });
    message.error("Error fetching booking modal data: " + error.message);
  } finally {
    dispatch({ type: SALES_BOOKING_MODAL_LOADING, data: false });
  }
};
