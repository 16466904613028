import React, { useState } from "react";
import { Button, Stack } from "@mui/material";
import VerifyDocument from "../../components/generic/verifyDocument";
import { useDispatch, useSelector } from "react-redux";
import { getQuoteDetailAPI, verifyKycDocsAPI } from "../../store/action/quote";
import { DeleteOutline } from "@mui/icons-material";
import SuccessModal from "../../components/generic/Modal";
import { ReactComponent as CaseClosedIcon } from "../../assets/icons/case_closed.svg";
import Heading from "../../components/generic/Heading/Heading";
import ChequeDetails from "./ChequeDetails";
const KycDocuments = () => {
  const dispatch = useDispatch();
  // const [openModal, setOpenModal] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [value, setValue] = useState([]);
  const [verifyDocuments, setVerifyDocuments] = useState([]); // Initialize as an empty array
  const [verifyChequeDetails, setVerifyChequeDetails] = useState([]);
  const data = useSelector((state) => state.quote.quoteDetails);

  const verificationDocs = data?.data?.verificationPendingDocument;
  const chequeDetails = data?.data?.chequeDetails;

  const handleSubmit = () => {
    let chequeToSend = verifyChequeDetails.map((item) => {
      return {
        chequeNumber: item.documentType,
        chequeStatus: item.documentStatus,
        chequeDeclineReason: item.documentReason,
      };
    });

    const payload = {
      quoteId: data?.data?.header?.ticketId,
      documentStatusList: verifyDocuments,
      verifyChequeDetailsList: chequeToSend,
    };
    // Todo: change API
    dispatch(verifyKycDocsAPI(payload, setOpenSuccess));
  };

  return (
    <>
      <Heading text="Customer KYC Documents" type="withborder" />

      {verificationDocs?.length > 0 ? (
        <div
          style={{
            width: "calc(100% - 30px)",
            borderRadius: "16px",
            backgroundColor: "#fff",
            padding: "20px",
            marginTop: "20px",
          }}
        >
          {openSuccess ? (
            <SuccessModal
              title="Documents Verified Successfully!"
              subTitle="Please share the quotes with partner"
              shareButton="Share Quotes"
              onClick={() =>
                dispatch(getQuoteDetailAPI(data?.data?.header?.ticketId))
              }
            />
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // maxWidth: "530px",
                margin: "0 auto",
                gap: "10px",
              }}
            >
              <Stack
                sx={{ width: "100%" }}
                justifyContent={"space-between"}
                gap={"16px"}
                paddingY={"16px"}
              >
                {verificationDocs?.length > 0 ? (
                  verificationDocs?.map((i, index) => (
                    <VerifyDocument
                      key={`verify-doc-${index}`}
                      data={i}
                      verifyDocuments={verifyDocuments}
                      setVerifyDocuments={setVerifyDocuments}
                      documentType={i?.documentType}
                    />
                  ))
                ) : (
                  <>
                    <p>No Data</p>
                  </>
                )}
              </Stack>
              {value &&
                value.map((item, index) => (
                  <div
                    key={`additional-docs-${index}`}
                    style={{
                      width: "100%",
                      borderRadius: 8,
                      background: "#FAFAFA",
                      padding: "8px 16px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    {item}
                    <DeleteOutline
                      onClick={() => {
                        const newValue = value.filter((_, i) => i !== index);
                        setValue(newValue);
                      }}
                      style={{ color: "#FF3E78", cursor: "pointer" }}
                    />
                  </div>
                ))}
              {chequeDetails?.length > 0 && (
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <Heading text={"Cheque Details"} color={"#000"} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "16px",
                      // justifyContent: "space-between",
                      width: "100%",
                      backgroundColor: "#FAFAFA",
                      padding: "12px",
                      borderRadius: "16px",
                    }}
                  >
                    {chequeDetails.map((item, index) => {
                      return (
                        <ChequeDetails
                          key={`cheque-details-${index}`}
                          chequeDetail={item}
                          verifyChequeDetails={verifyChequeDetails}
                          setVerifyChequeDetails={setVerifyChequeDetails}
                        />
                      );
                    })}
                  </div>
                </div>
              )}

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "inherit",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    width: "110px",
                    borderRadius: "8px",
                    color: "#fff",
                    "&:hover": { backgroundColor: "#0691FC" },
                    backgroundColor: "#0691FC",
                    textTransform: "none",
                  }}
                  disabled={
                    verificationDocs?.length !== verifyDocuments?.length ||
                    chequeDetails?.length !== verifyChequeDetails?.length
                  }
                  onClick={handleSubmit}
                >
                  SUBMIT
                </Button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            borderRadius: "16px",
            backgroundColor: "#fff",
            padding: "20px 0 20px 0",
            marginRight: "30px",
            marginTop: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "2rem",
          }}
        >
          <CaseClosedIcon />
          <h2
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "20px",
              fontWeight: 500,
              color: "#000",
              // height: "40vh",
            }}
          >
            {data?.data?.quoteState === "CASE_CLOSED"
              ? "Case has been closed!"
              : "No data found"}
          </h2>
        </div>
      )}
    </>
  );
};

export default KycDocuments;
