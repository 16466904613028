import {
  SALES_MANAGER_DATA_LOADING,
  GET_MANAGER_LIST_SUCCESS,
  GET_MANAGER_LIST_FAILURE,
} from "../action/salesManager";

const initialState = {
  preIssuanceList: [],
  hierarchyList: [],
  hierarchy: "",
  pagination: { curPage: 1, totalPage: 0 },
  loading: true,
  error: null,
};

export const salesManagerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MANAGER_LIST_SUCCESS:
      return {
        ...state,
        hierarchyList: action.payload.hierarchyList,
        hierarchy: action.payload.hierarchy,
        preIssuanceList: action.payload.preIssuanceList || [],
        pagination: {
          ...state.pagination,
          ...action.payload.pagination,
        },
        loading: false,
        error: null,
      };
    case GET_MANAGER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case SALES_MANAGER_DATA_LOADING:
      return {
        ...state,
        loading: action.data,
      };

    default:
      return state;
  }
};
