import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import FormInput from "../../components/FormInput/FormInput";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import { Box, Grid, Typography, IconButton } from "@mui/material";
import "./index.css";
import RequestDetails from "./RequestDetails";
import NewAutoComplete from "../generic/NewAutoComplete/NewAutoComplete";
import PreviousPolicy from "./PreviousPolicy";
import DocFields from "./DocFields";
import { CalendarMonth } from "@mui/icons-material";
import { ReactComponent as CustomerIcon } from "../../assets/icons/CustomerIcon.svg";
import { ReactComponent as PhoneIcon } from "../../assets/icons/PhoneIcon.svg";
import { ReactComponent as EmailIcon } from "../../assets/icons/EmailIcon.svg";

const manufacturingYearsOptions = Array.from(
  { length: new Date().getFullYear() - 1989 },
  (v, k) => k + 1990,
).map((year) => ({
  value: year,
  label: year.toString(),
}));

const VehicleForm = ({
  errors,
  control,
  vehicleState,
  vehicleType,
  setVehicleType,
  previousPolicyFlag,
  setPreviousPolicyFlag,
  claimPolicyFlag,
  setClaimPolicyFlag,
  activeHeader,
  resetField,
}) => {
  // const isNewVehicle = vehicleState === "Vehicle is New";
  const [isNewVehicle, setIsNewVehicle] = useState(
    vehicleState === "Vehicle is New" ? true : false,
  );
  useEffect(() => {
    setIsNewVehicle(vehicleState === "Vehicle is New");
  }, [vehicleState]);

  return (
    <Box
      className="form_body"
      sx={{ display: "flex", flexDirection: "column", gap: "24px" }}
    >
      {/* Name and Mobile Field */}
      <Grid container spacing={3} className="pb_24">
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="name"
            render={({ field: { onChange, value, ...field } }) => (
              <FormInput
                icon={<CustomerIcon />}
                iconPosition={"start"}
                {...field}
                value={value || ""}
                onChange={(e) => {
                  const alphabeticValue = e.target.value.replace(
                    /[^a-zA-Z ]/g,
                    "",
                  );
                  onChange(alphabeticValue);
                }}
                placeholder="Customer Name"
              />
            )}
          />
          {errors.name && (
            <Typography className="error_class">
              {errors.name.message}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="mobileNumber"
            render={({ field: { onChange, value, ...field } }) => (
              <FormInput
                icon={<PhoneIcon />}
                iconPosition={"start"}
                {...field}
                value={value || ""}
                onChange={(e) => {
                  let numericValue = e.target.value.replace(/[^0-9]/g, "");
                  if (numericValue.length > 10) {
                    numericValue = numericValue.slice(0, 10);
                  }
                  onChange(numericValue);
                }}
                placeholder="Customer Mobile No."
              />
            )}
          />
          {errors.mobileNumber && (
            <Typography className="error_class">
              {errors.mobileNumber.message}
            </Typography>
          )}
        </Grid>
      </Grid>

      {/* Email and RTO/Registration Field */}
      <Grid container spacing={3} className="pb_24">
        <Grid item xs={isNewVehicle ? 6 : 12}>
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <FormInput
                icon={<EmailIcon />}
                iconPosition={"start"}
                field={field}
                // icon={<img src={Email} alt="email" />}
                placeholder="Customer Email Id"
              />
            )}
          />
          {errors.email && (
            <Typography className="error_class">
              {errors.email.message}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          {isNewVehicle ? (
            <Controller
              control={control}
              name="rtoCode"
              render={({ field: { onChange, value, ...field } }) => (
                <FormInput
                  {...field}
                  value={value || ""}
                  onChange={(e) => {
                    const alphanumericValue = e.target.value.replace(
                      /[^a-zA-Z0-9 ]/g,
                      "",
                    );
                    onChange(alphanumericValue);
                  }}
                  placeholder="Enter RTO Code *"
                />
              )}
            />
          ) : (
            <Controller
              control={control}
              name="registrationNumber"
              render={({ field: { onChange, value, ...field } }) => (
                <FormInput
                  {...field}
                  value={value || ""}
                  maxLength={14}
                  onChange={(e) => {
                    const alphanumericValue = e.target.value.replace(
                      /[^a-zA-Z0-9 ]/g,
                      "",
                    );
                    onChange(alphanumericValue);
                  }}
                  placeholder="Enter Registration Number "
                />
              )}
            />
          )}
          {isNewVehicle
            ? errors.rtoCode && (
                <Typography className="error_class">
                  {errors.rtoCode.message}
                </Typography>
              )
            : errors.registrationNumber && (
                <Typography className="error_class">
                  {errors.registrationNumber.message}
                </Typography>
              )}
        </Grid>
        {!isNewVehicle && (
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="registrationDate"
              render={({ field }) => (
                <Box
                  className="date-picker date-container"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                    padding: "0",
                  }}
                >
                  <Flatpickr
                    {...field}
                    placeholder="Registration Date"
                    className="form_date_picker"
                    style={{ width: "100%", height: "100%" }}
                  />
                  <IconButton>
                    {" "}
                    <CalendarMonth sx={{ color: "#0691FC" }} />{" "}
                  </IconButton>
                </Box>
              )}
            />
            {errors.registrationDate && (
              <Typography className="error_class">
                {"Registration date is required"}
              </Typography>
            )}
          </Grid>
        )}
      </Grid>

      {/* Registration Date for old vehicles */}
      {vehicleState !== "Vehicle is New" && <Grid container spacing={3}></Grid>}

      {/* Manufacturing Year */}
      <Grid container spacing={3} className="pb_24">
        <Grid item xs={12}>
          <Controller
            control={control}
            name="manufacturingYear"
            render={({ field }) => (
              <NewAutoComplete
                {...field}
                isSearchable={false}
                options={manufacturingYearsOptions}
                placeholder="Manufacturing Year"
                isClearable
                className="select_dropdown"
                onChange={(selectedOption) => {
                  field.onChange(selectedOption);
                }}
                isMulti={false}
                value={field.value}
              />
            )}
          />
          {errors.manufacturingYear && (
            <Typography className="error_class">
              {errors.manufacturingYear.message}
            </Typography>
          )}
        </Grid>
      </Grid>

      {/* Request Details */}
      <RequestDetails
        errors={errors}
        control={control}
        setVehicleType={setVehicleType}
        vehicleType={vehicleType}
        resetField={resetField}
        isNewVehicle={isNewVehicle}
      />

      {/* Conditionally render Previous Policy section for old vehicle */}
      {!isNewVehicle && (
        <PreviousPolicy
          errors={errors}
          control={control}
          previousPolicyFlag={previousPolicyFlag}
          setPreviousPolicyFlag={setPreviousPolicyFlag}
          claimPolicyFlag={claimPolicyFlag}
          setClaimPolicyFlag={setClaimPolicyFlag}
          vehicleType={vehicleType}
          setVehicleType={setVehicleType}
          resetField={resetField}
        />
      )}

      {/* Document Fields */}
      <DocFields
        errors={errors}
        control={control}
        activeHeader={activeHeader}
        vehicleState={vehicleState}
        isNewVehicle={isNewVehicle}
        previousPolicyFlag={previousPolicyFlag}
        resetField={resetField}
      />
    </Box>
  );
};

export default VehicleForm;
