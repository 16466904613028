import axios from "../api/axios";
import { getSalesDashboardData } from "../api";
import { message } from "../../components/generic/SnakeAlert/SnakeAlert";

// Action Types
export const GET_DASHBOARD_DATA_SUCCESS = "GET_DASHBOARD_DATA_SUCCESS";
export const GET_DASHBOARD_DATA_FAILURE = "GET_DASHBOARD_DATA_FAILURE";
export const GET_DASHBOARD_LOADING = "GET_DASHBOARD_LOADING";

export const getSalesDashboardDataAPI = (search) => async (dispatch) => {
  try {
    dispatch({ type: GET_DASHBOARD_LOADING, data: true });
    const response = await axios.get(getSalesDashboardData, {
      params: search,
    });

    if (response.status === 200) {
      const {
        bookingDetails,
        mappingDetails,
        quoteDetails,
        topPerformingPartners,
        onboardingDetails,
      } = response.data.data;

      dispatch({
        type: GET_DASHBOARD_DATA_SUCCESS,
        payload: {
          bookingDetails,
          mappingDetails,
          quoteDetails,
          topPerformingPartners,
          onboardingDetails,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: GET_DASHBOARD_DATA_FAILURE,
      error: error.message,
    });
    message.error("Error fetching dashboard data: " + error.message);
  } finally {
    dispatch({ type: GET_DASHBOARD_LOADING, data: false });
  }
};
