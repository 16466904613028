import React from "react";
import Done from "../../../assets/icons/done.gif";
import { Box, Button, Typography } from "@mui/material";

export default function SuccessModal({
  // eslint-disable-next-line no-unused-vars
  setOpen,
  title,
  subTitle,
  shareButton,
  btnDisabled,
  onClick,
}) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        gap: 2,
        alignItems: "center",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "center", borderRadius: 90 }}>
        {/* <img src={RightGif} alt="" /> */}
        <img src={Done} alt="" style={{ borderRadius: 90 }} />
      </Box>
      <Typography
        sx={{
          fontWeight: "600",
          textAlign: "center",
          fontSize: 24,
          color: "#000",
        }}
        variant="h3"
      >
        {" "}
        {title}
      </Typography>
      <Typography
        sx={{
          fontWeight: "400",
          textAlign: "center",
          fontSize: 14,
          color: "#666666",
        }}
      >
        {" "}
        {subTitle}
      </Typography>
      {btnDisabled ? (
        ""
      ) : (
        <Button
          variant="contained"
          sx={{
            borderRadius: "8px",
            backgroundColor: "#0691FC",
            width: "192px",
            color: "#fff",
            height: "40px",
          }}
          onClick={onClick}
        >
          {shareButton}
        </Button>
      )}
    </Box>
  );
}
