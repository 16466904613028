import {
  GET_BOOKING_LIST_SUCCESS,
  GET_BOOKING_LIST_FAILURE,
  SET_CURRENT_PAGE,
  GET_BOOKING_MODAL_SUCCESS,
  GET_BOOKING_MODAL_FAILURE,
  SALES_BOOKING_LOADING,
  SALES_BOOKING_MODAL_LOADING,
} from "../action/salesBooking";

const initialState = {
  bookingList: [],
  pagination: {
    curPage: 1,
    totalPage: 0,
  },
  bookingBucket: {
    curBucket: "",
    bucketList: [],
  },
  modalData: {
    headerData: {},
    vehicleDetails: [],
    policyDetails: [],
    premiumDetails: {},
    policyCopyUrl: "",
    businessType: "",
  },
  loading: true,
  isModalLoading: false,
  error: null,
};

export const salesBookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BOOKING_LIST_SUCCESS:
      return {
        ...state,
        bookingList: action.payload.bookingList,
        pagination: {
          ...state.pagination,
          ...action.payload.pagination,
        },
        bookingBucket: {
          ...state.bookingBucket,
          curBucket: action.payload.bookingBucket.curBucket,
          bucketList: action.payload.bookingBucket.bucketList,
        },
        loading: false,
        error: null,
      };
    case GET_BOOKING_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case SET_CURRENT_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          curPage: action.payload.curPage,
        },
      };
    case GET_BOOKING_MODAL_SUCCESS:
      return {
        ...state,
        modalData: {
          ...state.modalData,
          headerData: action.payload.headerData || {},
          vehicleDetails: action.payload.vehicleDetails,
          policyDetails: action.payload.policyDetails,
          premiumDetails: action.payload.premiumDetails,
          policyCopyUrl: action.payload.policyCopyUrl,
          businessType: action.payload.businessType,
        },
        loading: false,
        error: null,
      };
    case GET_BOOKING_MODAL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
      case SALES_BOOKING_LOADING:
        return {
          ...state,
          loading: action.data,
        };
        case SALES_BOOKING_MODAL_LOADING:
          return {
            ...state,
            isModalLoading: action.data,
          };
    default:
      return state;
  }
};
