import { Box, Button, Typography } from "@mui/material";
import React from "react";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DropDown from "../generic/DropDown/DropDown";
import Heading from "../generic/Heading/Heading";

export default function HigherEducation({
  setVerifyDocument,
  education,
  setEducation,
  reason,
  setReason,
  partnerNoc,
}) {
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank");
    if (newWindow) newWindow.opener = null;
  };

  //  to handle higher education
  const handleReason = (event) => {
    setReason(event.target.value);
    setVerifyDocument([
      {
        documentType: "MARKSHEET",
        documentStatus: "REUPLOAD",
        documentReason: event.target.value,
      },
    ]);
  };

  return (
    <Box>
      <Heading type={"withborder"} text="Higher Education Department" />
      <Box
        sx={{
          background: "#F7F7F7",
          width: "100%",
          borderRadius: 5,
          marginBottom: 2,
          padding: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontSize: "14px" }}>10th Marksheet</Typography>

          {education == null ? (
            <Box display="flex" flexDirection="row" gap={1}>
              <Box>
                <Button
                  onClick={() =>
                    openInNewTab(
                      partnerNoc?.data?.higherEducationDocumentWidget?.url,
                    )
                  }
                  startIcon={<VisibilityIcon />}
                  sx={{
                    background: "#EEF6FD",
                    color: "#0691FC",
                    width: 180,
                    fontSize: 12,
                    padding: "12px",
                    borderRadius: 2,
                    textTransform: "capitalize",
                  }}
                >
                  View Document
                </Button>
              </Box>
              <Box>
                <Button
                  onClick={() => {
                    setEducation(true);
                    setVerifyDocument([
                      {
                        documentType: "MARKSHEET",
                        documentStatus: "VERIFIED",
                        documentReason: null,
                      },
                    ]);
                  }}
                  startIcon={<DoneIcon />}
                  sx={{
                    background: "#E2F1E6",
                    color: "#23963D",
                    fontSize: 12,
                    padding: "12px",
                    borderRadius: 2,
                    textTransform: "capitalize",
                  }}
                  fullWidth
                >
                  Valid
                </Button>
              </Box>
              <Button
                onClick={() => setEducation(false)}
                startIcon={<CloseIcon />}
                sx={{
                  background: "#F9E5E5",
                  color: "#DF0000",
                  fontSize: 12,
                  padding: "12px",
                  borderRadius: 2,
                  textTransform: "capitalize",
                }}
                fullWidth
              >
                Invalid
              </Button>
            </Box>
          ) : education ? (
            <Box display="flex" flexDirection="row">
              <Box flexGrow={1} />
              <Button
                onClick={() => setEducation(true)}
                startIcon={<DoneIcon />}
                sx={{
                  color: "#23963D",
                  fontSize: 12,
                  fontWeight: 500,
                  padding: "12px",
                  borderRadius: 2,
                  textTransform: "capitalize",
                }}
                variant="text"
              >
                Validated
              </Button>
              <Button
                onClick={() => setEducation(null)}
                sx={{ color: "#0691FC", fontSize: 12 }}
              >
                Edit
              </Button>
            </Box>
          ) : (
            <Box display="flex" flexDirection="row">
              <Box flexGrow={1} />
              <DropDown
                value={reason}
                onChange={handleReason}
                data={
                  partnerNoc?.data?.higherEducationDocumentWidget
                    ?.higherEducationDocumentInvalidReason
                }
                placeholder="Select Reason"
              />
              <Button
                variant="text"
                onClick={() => setEducation(false)}
                startIcon={<CloseIcon />}
                sx={{
                  color: "#DF0000",
                  mx: 2,
                  fontSize: 12,
                  minWidth: "unset",
                  textTransform: "capitalize",
                }}
              >
                Invalidated
              </Button>
              <Button
                onClick={() => setEducation(null)}
                sx={{ color: "#0691FC", fontSize: 12 }}
              >
                Edit
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
