import React, { useEffect, useState } from "react";
import Heading from "../../components/generic/Heading/Heading";
import { Button } from "@mui/material";
import DialogBox from "../../components/generic/DialogBox";
import CustomModalBox from "../../components/Form/CustomModal";
import CutomeModalNoQuote from "../../components/Form/CutomeModalNoQuote";
import SuccessModal from "../../components/generic/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  noQuoteAvailableAPI,
  shareQuoteDetailsAPI,
} from "../../store/action/quote";
import RequestQuoteComponent from "./ReqQuoteComponent";
import { useNavigate } from "react-router-dom";

const RequestQuote = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector((state) => state.quote.quoteDetails);
  const [openModal, setOpenModal] = useState(false);
  const [openNoQuote, setNoQuote] = useState(false);
  const [files, setFiles] = useState([]);
  const [newData, setNewData] = useState([]);

  const initializeNewData = () => {
    const initialData = data?.data?.offlineQuotes?.map((i) => ({
      quoteRequestId: i?.quoteRequestId,
      isQuoteAvailable: true,
      inspectionRequired: false,
    }));
    setNewData(initialData);
  };

  const updateNewData = (index, newDataItem) => {
    const updatedNewData = [...newData];
    updatedNewData[index] = newDataItem;
    setNewData(updatedNewData);
  };

  // Initialize newData when data changes
  useEffect(() => {
    initializeNewData();
  }, [data]);

  useEffect(() => {
    if (data?.data?.offlineQuotes && data?.data?.offlineQuotes?.length > 0) {
      const files = Array.from({ length: data?.data?.offlineQuotes?.length }, () => null);
      setFiles(files);
    }
  }, [data]);

  const [openSuccess, setOpenSuccess] = useState(false);
  const [declineData, setDeclineData] = useState(false);
  const handleShareQuotes = () => {
    let formData = new FormData();
    files.forEach((file, index) => {
      formData.append(`QUOTE_PDF${index + 1}`, file);
    });
    formData.append("quoteId", data?.data?.header?.ticketId);
    formData.append("sharedQuoteList", JSON.stringify(newData));

    dispatch(shareQuoteDetailsAPI(formData, setOpenSuccess));
  };
  const handleClose = () => {
    setOpenSuccess(false);
    navigate("/app/quote-request");
  };
  return (
    <>
      <div
        style={{
          width: "100%",
          borderRadius: "16px",
          backgroundColor: "transparent",
          padding: "20px",
          marginRight: "30px",
          marginTop: "20px",
        }}
      >
        {openSuccess ? (
          <SuccessModal
            title={
              declineData
                ? "Quotes Declined Successfully!"
                : "Quotes Shared Successfully!"
            }
            subTitle={
              declineData
                ? "Same has been notified to partner"
                : "You will be notified once partner accept/reject the quotes"
            }
            shareButton="Okay"
            onClick={handleClose}
          />
        ) : (
          <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Heading text="Requested Quotes" type="withborder" />
              <div
                role="button"
                tabIndex={0}
                style={{ cursor: "pointer" }}
                onClick={() => setNoQuote(true)}
              >
                <Heading
                  text="No Quote available"
                  type="withoutborder"
                  className={"text-red"}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                marginTop: "16px",
              }}
            >
              {data?.data?.offlineQuotes?.map((item, index) => (
                <RequestQuoteComponent
                  key={`quote-${index}`}
                  ids={index}
                  data={item}
                  idx={index}
                  updateNewData={updateNewData}
                  setFiles={setFiles}
                  files={files}
                />
              ))}
            </div>
            <div
              style={{
                marginTop: "20px",
                textAlign: "right",
              }}
            >
              <Button
                sx={{
                  width: "160px",
                  color: "#fff",
                  backgroundColor: "#0691FC",
                  borderRadius: "8px",
                }}
                variant="contained"
                onClick={handleShareQuotes}
              >
                Share Quotes
              </Button>
            </div>
          </>
        )}

        {openModal && (
          <DialogBox
            width={500}
            open={openModal}
            setOpen={setOpenModal}
            children={
              <CustomModalBox
                setOpen={setOpenModal}
                title={"Quotes Shared Successfully!"}
                subTitle={
                  "You will be notified once partner accept/reject the quotes"
                }
                shareButton={"Okay"}
              />
            }
          />
        )}
        {openNoQuote && (
          <DialogBox
            width={500}
            open={openNoQuote}
            setOpen={setNoQuote}
            children={
              <CutomeModalNoQuote
                setOpen={setNoQuote}
                title={"Are you sure?"}
                // subTitle={
                //   "You will be notified once partner accept/reject the quotes"
                // }
                confirmButton={"No quotes available"}
                cancelButton={"cancel"}
                onClick={() =>
                  dispatch(
                    noQuoteAvailableAPI(
                      data?.data?.header?.ticketId,
                      "Case closed by ops",
                      setOpenSuccess,
                      setDeclineData,
                      setNoQuote,
                    ),
                  )
                }
              />
            }
          />
        )}
      </div>
    </>
  );
};

export default RequestQuote;
