import React from "react";
import { quoteStatusList, quoteRequestStateList } from "../../helpers/enums";
import Addons from "./Addons";
import checkImg from "../../assets/icons/checkFFF.svg";
import doneGif from "../../assets/icons/done.gif";
import { InsurerLogoMap } from "../../helpers/InsurerLogoMap";

const QuoteCardBox = ({ quoteData, quoteState }) => {
  return (
    <div
      style={{
        display: "flex",
        borderRadius: "8px",
        boxShadow: "0px 2px 4px 0px #0000001A",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor:
            quoteData?.quoteStatus === quoteStatusList.ACCEPTED_WITH_INSPECTION
              ? "#E2F1E6"
              : "#fff",
          padding: "8px 20px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 16,
          }}
        >
          {quoteData?.insurerId && InsurerLogoMap[quoteData?.insurerId] ? (
            <img
              src={InsurerLogoMap[quoteData?.insurerId]?.Logo}
              alt="insurer"
            />
          ) : (
            <p>{quoteData?.insurerName}</p>
          )}
          {[
            quoteStatusList.ACCEPTED,
            quoteStatusList.ACCEPTED_WITH_INSPECTION,
          ].includes(quoteData?.quoteStatus) && (
            <div
              style={{
                backgroundColor: "#09CE1D",
                padding: "4px 10px",
                borderRadius: "4px",
                display: "flex",
                gap: "5px",
              }}
            >
              <img src={checkImg} alt="check" />{" "}
              <h3
                style={{
                  color: "#fff",
                  fontSize: "10px",
                  fontWeight: 500,
                }}
              >
                {quoteData?.quoteStatusName}
              </h3>
            </div>
          )}
        </div>

        <div
          style={{
            display: "flex",
            gap: "8px",
          }}
        >
          {quoteData?.quoteStatus !== quoteStatusList.UNAVAILABLE && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <a href={quoteData?.quoteUrl} target="_blank" rel="noreferrer">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    flexDirection: "row",
                  }}
                >
                  <img src="/eyeIcon.svg" alt="view" target="_blank" />
                  <h2
                    style={{
                      color: "#0691FC",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    View Quote{" "}
                  </h2>
                </div>
              </a>
            </div>
          )}

          {quoteData?.quoteStatus === quoteStatusList.UNAVAILABLE && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8x",
              }}
            >
              <h2
                style={{
                  backgroundColor: "#E6E6E6",
                  fontSize: "10px",
                  padding: "2px 4px",
                  borderRadius: "4px",
                  fontWeight: 500,
                  color: "#808080",
                }}
              >
                Quote Unavailable
              </h2>
            </div>
          )}
        </div>
      </div>
      <div>
        {quoteState === quoteRequestStateList.FILL_CUSTOMER_DETAILS &&
          quoteData?.quoteStatus ===
            quoteStatusList.ACCEPTED_WITH_INSPECTION && (
            <div
              style={{
                backgroundColor: "#E2F1E6",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <img
                style={{ borderRadius: 100, padding: 16 }}
                src={doneGif}
                alt="done"
              />
              <h2
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                }}
              >
                Quote Approved Successfully!
              </h2>
              <h4
                style={{
                  fontSize: "12px",
                  fontWeight: 400,
                }}
              >
                Waiting for the partner to fill customer details
              </h4>
            </div>
          )}
      </div>
      {quoteData?.addOns?.length > 0 && <Addons addOns={quoteData.addOns} />}
    </div>
  );
};

export default QuoteCardBox;
