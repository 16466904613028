import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Chip from "@mui/material/Chip";
import { styled, emphasize } from "@mui/material/styles";

// StyledBreadcrumb component using MUI's Chip and theme-based styling
const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];

  return {
    backgroundColor: "#D3D3D3",
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});

const EmployeeBreadcrumbs = ({
  hierarchyList,
  selectedHierarchy,
  handleBreadcrumbClick,
}) => {
  // Parse hierarchy data
  const parsedHierarchy =
    hierarchyList && Array.isArray(hierarchyList)
      ? hierarchyList
      : selectedHierarchy || [];

  // If there's no hierarchy, return null to render nothing
  if (parsedHierarchy.length === 0) return null;

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNextIcon fontSize="small" />}
    >
      {parsedHierarchy.map((employee, index) => {
        const isLast = index === parsedHierarchy.length - 1;

        return isLast ? (
          <StyledBreadcrumb
            key={employee.employeeId}
            label={employee.employeeName}
            sx={{
              fontSize: "14px",
              color: "#0691FC",
              fontWeight: 600,
            }}
          />
        ) : (
          <Typography
            key={employee.employeeId}
            sx={{
              fontSize: "14px",
              fontWeight: "600",
              color: "#0691FC",
            }}
          >
            <Link
              underline="hover"
              color="inherit"
              href={`/employee/${employee.employeeId}`}
              onClick={(event) => {
                event.preventDefault();
                handleBreadcrumbClick(employee);
              }}
            >
              {employee.employeeName}
            </Link>
          </Typography>
        );
      })}
    </Breadcrumbs>
  );
};

export default EmployeeBreadcrumbs;
