const initialState = {
  mappingRequestsList: [],
  mappingVerificationDetails: {},
  pagination: {
    curPage: 1,
    totalPage: 0,
  },
  mappingBucket: {
    curBucket: "",
    bucketList: [],
  },

  isLoadingRequests: false,
  isLoadingDetails: false,
  bookingOptions: [],
  vehicleOptions: [],
  headerOptions: {},
  docs: [],
  rejectReasons: {},
};

export default function mappingRequestsReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_MAPPING_REQUESTS_LIST":
      return {
        ...state,
        mappingRequestsList: action.data?.mappingList || [],
        pagination: action.data?.pagination,
        mappingBucket: action.data?.mappingBucket,
        isLoadingRequests: false,
      };

    case "RESET_MAPPING_REQUESTS_LIST":
      return {
        ...state,
        mappingRequestsList: [],
        pagination: {
          curPage: 1,
          totalPage: 0,
        },
        mappingBucket: {
          curBucket: "",
          bucketList: [],
        },
        isLoadingRequests: false,
      };

    case "GET_MAPPING_VERIFICATION_DETAILS":
      return {
        ...state,
        mappingVerificationDetails: {
          documentData: action.data?.data || [],
          extraData: action.data?.extraData || {},
          mappingId: action.data?.mappingId,
        },
        isLoadingDetails: false,
      };

    case "SET_MAPPING_REQUESTS_LOADING":
      return {
        ...state,
        isLoadingRequests: action.data,
      };
    case "SET_MAPPING_DETAILS_LOADING":
      return {
        ...state,
        isLoadingDetails: action.data,
      };
    case "SET_MAPPING_OPTIONS":
      return {
        ...state,
        bookingOptions: action.data?.bookingOptions || [],
        vehicleOptions: action.data?.vehicleOptions || [],
        headerOptions: action.data?.header || {},
        docs: action.data?.docs || [],
        mappingState: action.data?.mappingState || "",
        isLoadingDetails: false,
      };

    default:
      return state;
  }
}
