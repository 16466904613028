import {
  ADD_PARTNER,
  GRID,
  OPS_MAPPING_REQUEST,
  OPS_QUOTE_REQUEST,
  PARTNER_VERIFICATION,
  PARTNERS,
  SALES_PARTNER,
  SALES_MAPPING,
  SALES_QUOTE,
  SALES_BOOKING,
  SALES_DASHBOARD,
  SALES_MANAGER_PRE_ISSUANCE,
  SALES_MANAGER_PARTNERS,
  SALES_MANAGER_BOOKINGS,
} from "../helpers/featuresName";
import { IconPercentange } from "./icons/IconPercentange";
import { ReactComponent as MappingRequestIcon } from "./icons/MappingRequestIcon.svg";
import { ReactComponent as QuoteRequestIcon } from "./icons/QuoteRequestIcon.svg";
import { ReactComponent as PartnersIcon } from "./icons/partnersIcon.svg";
import { ReactComponent as DashboardIcon } from "./icons/DashboardIcon.svg";
import { ReactComponent as BookingIcon } from "./icons/BookingIcon.svg";

export const navs = [
  {
    text: "Dashboard",
    icon: <DashboardIcon width="24px" height="24px" />,
    link: "dashboard",
    featureName: SALES_DASHBOARD,
    subNav: [],
  },
  {
    text: "Pre Issuance",
    icon: <DashboardIcon width="24px" height="24px" />,
    link: "sales/manager/pre-issuance-dashboard",
    featureName: SALES_MANAGER_PRE_ISSUANCE,
    subNav: [],
  },

  {
    text: "Partners",
    icon: <PartnersIcon width="24px" height="24px" />,
    link: "partners",
    featureName: PARTNERS, // Optional, if needed for the main nav item
    subNav: [
      {
        id: 0,
        name: "Verification Pending",
        link: "verification",
        featureName: PARTNER_VERIFICATION, // Maps to the feature name in the API response
      },
      {
        id: 1,
        name: "Add Partners",
        link: "add-new-partner",
        featureName: ADD_PARTNER, // Maps to the feature name in the API response
      },
    ],
  },
  {
    text: "Quote Requests",
    icon: <QuoteRequestIcon width="24px" height="24px" />,
    link: "quote-request",
    featureName: OPS_QUOTE_REQUEST, // Maps to the feature name in the API response
    subNav: [],
  },
  {
    text: "Mapping Requests", // Ops Mapping Request
    icon: <MappingRequestIcon width="24px" height="24px" />,
    link: "mapping-requests",
    featureName: OPS_MAPPING_REQUEST, // Maps to the feature name in the API response
    subNav: [],
  },
  {
    text: "Grid",
    icon: <IconPercentange width="24px" height="24px" />,
    link: "grid",
    featureName: GRID,
    subNav: [],
  },
  {
    text: "Partners",
    icon: <PartnersIcon width="24px" height="24px" />,
    link: "sales/view-partner",
    featureName: SALES_PARTNER,
    subNav: [],
  },
  {
    text: "Partners",
    icon: <PartnersIcon width="24px" height="24px" />,
    link: "sales/manager/view-partners",
    featureName: SALES_MANAGER_PARTNERS,
    subNav: [],
  },
  {
    text: "Mapping Requests", // Sales Mapping Request
    icon: <MappingRequestIcon width="24px" height="24px" />,
    link: "sales/mapping-requests",
    featureName: SALES_MAPPING,
    subNav: [],
  },
  {
    text: "Quote Requests",
    icon: <QuoteRequestIcon width="24px" height="24px" />,
    link: "sales/quote-request",
    featureName: SALES_QUOTE,
    subNav: [],
  },
  {
    text: "Bookings",
    icon: <BookingIcon width="24px" height="24px" />,
    link: "sales/bookings",
    featureName: SALES_BOOKING,
    subNav: [],
  },
  {
    text: "Bookings",
    icon: <BookingIcon width="24px" height="24px" />,
    link: "sales/manager/bookings",
    featureName: SALES_MANAGER_BOOKINGS,
    subNav: [],
  },
];

export const uploadFileAllowedFormats = [
  "image/jpeg",
  "image/png",
  "application/pdf",
];
export const quoteDocumentsObj = {
  INVOICE: "Invoice",
  AADHAAR_BACK: "Aadhaar Back",
  AADHAAR_FRONT: "Aadhaar Front",
  PAN: "PAN",
  RC: "RC",
};
export const kycDocFields = [
  {
    documentType: "PAN",
    label: "PAN Card",
  },
  {
    documentType: "AADHAAR_FRONT",
    label: "Aadhar Front",
  },
  {
    documentType: "AADHAAR_BACK",
    label: "Aadhar Back",
  },
];

export const policyTypeOptions = {
  COMPREHENSIVE: "Comprehensive Policy",
  THIRD_PARTY: "Third Party Policy",
  SAOD: "SAOD Policy",
};

export const creataMultipleDocUploadPayload = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (typeof data[key] === "object" && Object.keys(data[key]).length > 0) {
      formData.append(key, data[key].file, data[key].fileName);
    } else {
      formData.append(key, data[key]);
    }
  });
  return formData;
};

export const Colors = {
  SoftRed: "#FF6384",
  BrightBlue: "#36A2EB",
  Yellow: "#FFCE56",
  Turquoise: "#4BC0C0",
  LavenderPurple: "#9966FF",
  Orange: "#FF9F40",
  LightGray: "#C9CBCF",
  Coral: "#FF5733",
  SoftSkyBlue: "#2E93FA",
  LightGreen: "#9ED36A",
};

export const createFormDataWithFileAndJSON = (data) => {
  let formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (typeof data[key] === "object" && data[key] instanceof File) {
      formData.append(key, data[key], data[key].name);
    } else if (Array.isArray(data[key]) && data[key].length > 0) {
      formData.append(key, JSON.stringify(data[key]));
    } else if (
      typeof data[key] === "object" &&
      Object.keys(data[key]).length > 0
    ) {
      formData.append(key, JSON.stringify(data[key]));
    } else {
      formData.append(key, data[key]);
    }
  });
  return formData;
};

export const declineReasonOptions = [
  { value: "Already Insured", label: "Already Insured" },
  { value: "High Premium", label: "High Premium" },
  { value: "Coverage Not Sufficient", label: "Coverage Not Sufficient" },
  { value: "Others", label: "Others" },
];

export const filteredData = (data) =>
  Object.fromEntries(
    Object.entries(data).filter(([value]) => value !== undefined),
  );

export const AvatarColors = (string, saturation = 70, lightness = 65) => {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  const hue = Math.abs(hash) % 360; // Generate a hue between 0-359
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};
