import { message } from "../../components/generic/SnakeAlert/SnakeAlert";
import { createSalesMappingRequestURL } from "../api";
import axios from "../api/axios";

// Action Types
export const POST_DATA_REQUEST = "POST_DATA_REQUEST";
export const POST_DATA_SUCCESS = "POST_DATA_SUCCESS";
export const POST_DATA_FAILURE = "POST_DATA_FAILURE";

// Action Creator for POST request
export const SalesMappingRequest = (formData) => async (dispatch) => {
  try {
    dispatch({ type: POST_DATA_REQUEST });
    const response = await axios.post(createSalesMappingRequestURL, formData);

    if (response.status === 200) {
      // Dispatch the success action with the response data
      dispatch({
        type: POST_DATA_SUCCESS,
        payload: response.data.data,
      });

      message.success(response.data.msg);

      return { success: true }; // Return success to the component
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    let errorMessage = "Something went wrong";

    if (error.response && error.response.data && error.response.data.msg) {
      errorMessage = error.response.data.msg; // Get the custom error message from API
    }
    dispatch({
      type: POST_DATA_FAILURE,
      error: errorMessage,
    });
    message.error(errorMessage);

    return { success: false };
  }
};
