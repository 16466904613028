const inititalState = {
  partnerList: null,
  partnerPayment: null,
  partnerNoc: null,
  hierachystate: null,
};

export default function partnerReducer(state = inititalState, action) {
  switch (action.type) {
    case "GET_PARTNER_LIST":
      return {
        ...state,
        partnerList: action.data,
      };
    case "GET_PARTNER_PAYMENT_VERIFICATION":
      return {
        ...state,
        partnerNoc: action.data,
      };
    case "GET_PARTNER_NOC_VERIFICATION":
      return {
        ...state,
        partnerNoc: action.data,
      };
    case "GET_HIERACHY_LIST":
      return {
        ...state,
        hierachystate: action.data,
      };
    default:
      return state;
  }
}
