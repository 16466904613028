import {
  GET_SALES_QUOTE_LIST,
  RESET_SALES_QUOTE,
  SALES_QUOTE_LOADING,
  GET_QUOTE_DETAILS,
} from "../action/salesQuote";

const inititalState = {
  quoteList: [],
  quoteDetails: null,
  pagination: {
    curPage: 1,
    totalPage: 0,
  },
  quoteBucket: {
    curBucket: "",
    bucketList: [],
  },
  isLoading: true,
};

export default function salesQuoteReducer(state = inititalState, action) {
  switch (action.type) {
    case GET_SALES_QUOTE_LIST:
      return {
        ...state,
        quoteList: action.data?.quoteList,
        pagination: action.data?.pagination,
        quoteBucket: action.data?.quoteBucket,
        isLoading: false,
      };
    case GET_QUOTE_DETAILS:
      return {
        ...state,
        quoteDetails: action.data,
      };
    case SALES_QUOTE_LOADING:
      return {
        ...state,
        isLoading: action.data,
      };
    case RESET_SALES_QUOTE:
      return inititalState;
    default:
      return state;
  }
}
