import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ component: Component, featureName, ...rest }) => {
  const { permissions, loading } = useSelector((state) => state.auth);
  
  if (loading) {
    return <div></div>;
  }
  if (permissions?.[featureName]?.VIEW) {
    return <Component {...rest} permissions={permissions[featureName]} />;
  }

  if (permissions) return <Navigate to="/access-denied" />;
};

export default ProtectedRoute;
