import React from "react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import { Typography, Link, Grid } from "@mui/material";
import FormInput from "../../components/FormInput/FormInput";
import FileSelector from "../../components/generic/FileSelector";
import { RemoveRedEyeOutlined } from "@mui/icons-material";

const ChequeDetails = ({ field, errors, index, item }) => {
  const handleChange = (e) => {
    field.onChange({
      ...field.value,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Grid container gap={0.9} alignItems={"flex-start"}>
      <Grid item xs={2.3}>
        <FormInput
          field={field}
          name="chequeNumber"
          placeholder={"Cheque No."}
          value={field?.value?.chequeNumber}
          onChange={handleChange}
          maxLength={6}
          inputMode={"numeric"}
          disabled={
            item && item.chequeStatus && item.chequeStatus === "VERIFIED"
          }
        />
        {errors?.chequeDetails?.[index]?.chequeNumber && (
          <p className="error_class mb-3">
            {errors?.chequeDetails[index]?.chequeNumber?.message}
          </p>
        )}
      </Grid>

      <Grid item xs={2.3}>
        <FormInput
          field={field}
          name="bankName"
          value={field?.value?.bankName}
          placeholder={"Bank Name"}
          onChange={handleChange}
          inputMode={"alpha"}
          disabled={
            item && item.chequeStatus && item.chequeStatus === "VERIFIED"
          }
        />
        {errors?.chequeDetails?.[index]?.bankName && (
          <Typography className="error_class mb-3" variant="p">
            {errors?.chequeDetails[index]?.bankName?.message}
          </Typography>
        )}
      </Grid>

      <Grid
        item
        xs={2.3}
        sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
      >
        <Flatpickr
          {...field}
          name="issueDate"
          value={field?.value?.issueDate}
          onChange={(date) =>
            handleChange({ target: { name: "issueDate", value: date[0] } })
          }
          placeholder="Issue Date"
          className="flat_picker_date_cheque date-container"
          disabled={
            item && item.chequeStatus && item.chequeStatus === "VERIFIED"
          }
        />
        {errors?.chequeDetails?.[index]?.issueDate && (
          <Typography className="error_class mb-3" variant="p">
            {errors?.chequeDetails[index]?.issueDate?.message}
          </Typography>
        )}
      </Grid>

      <Grid item xs={2.3}>
        <FormInput
          field={field}
          name="amount"
          inputMode="currency"
          value={field?.value?.amount}
          placeholder={"Amount"}
          onChange={handleChange}
          maxLength={21}
          disabled={
            item && item.chequeStatus && item.chequeStatus === "VERIFIED"
          }
        />
        {errors?.chequeDetails?.[index]?.amount && (
          <Typography className="error_class mb-3" variant="p">
            {errors?.chequeDetails[index]?.amount?.message}
          </Typography>
        )}
      </Grid>

      {item?.chequeUrl && item?.chequeStatus === "VERIFIED" ? (
        <Grid item xs={2.3} sx={{ mt: 2 }}>
          <Link
            href={item?.chequeUrl}
            target="_blank"
            rel="noreferrer"
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
          >
            <RemoveRedEyeOutlined size={16} />
            <Typography variant="body2">View</Typography>
          </Link>
        </Grid>
      ) : (
        <Grid item xs={2.25}>
          <FileSelector
            id={`chequeFile${index}`}
            file={field?.value?.chequeFile}
            fileNameToDisplay={
              field?.value?.chequeFile?.name?.length > 7
                ? field?.value?.chequeFile?.name?.slice(0, 7) + ".."
                : field?.value?.chequeFile?.name
            }
            handleFileChange={(file) =>
              handleChange({ target: { name: "chequeFile", value: file } })
            }
          />
          {errors?.chequeDetails?.[index]?.chequeFile && (
            <p className="error_class mb-3">
              {errors?.chequeDetails[index]?.chequeFile?.message}
            </p>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default ChequeDetails;
