import {
  GET_SALES_MAPPING_LIST,
  GET_UPLOAD_DOCS_LIST,
  RESET_SALES_MAPPING,
  SALES_MAPPING_LOADING,
} from "../action/salesMapping";

const inititalState = {
  //create states
  mappingList: [],
  pagination: {
    curPage: 1,
    totalPage: 0,
  },
  mappingBucket: {
    curBucket: "",
    bucketList: [],
  },
  uploadDocsList: [],
  isLoading: true,
};

export default function salesMappingReducer(state = inititalState, action) {
  switch (action.type) {
    case GET_SALES_MAPPING_LIST:
      return {
        ...state,
        mappingList: action.data?.mappingList,
        pagination: action.data?.pagination,
        mappingBucket: action.data?.mappingBucket,
        isLoading: false,
      };
    case SALES_MAPPING_LOADING:
      return {
        ...state,
        isLoading: action.data,
      };
    case RESET_SALES_MAPPING:
      return {
        ...state,
        mappingList: [],
        pagination: {
          curPage: 1,
          totalPage: 0,
        },
        mappingBucket: {
          curBucket: "",
          bucketList: [],
        },
        isLoading: true,
      };
    case GET_UPLOAD_DOCS_LIST:
      return {
        ...state,
        uploadDocsList: action.data,
      };

    default:
      return state;
  }
}
