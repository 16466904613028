import { Box, Button, Typography } from "@mui/material";
import React from "react";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DropDown from "../generic/DropDown/DropDown";
import Heading from "../generic/Heading/Heading";

export default function VerifyBankDetails({
  bank,
  setBank,
  bankReason,
  setBankReason,
  partnerNoc,
}) {
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank");
    if (newWindow) newWindow.opener = null;
  };

  //  to handle bank change
  const handleBankChange = (event) => {
    setBankReason(event.target.value);
  };
  return (
    <Box>
      <Heading type={"withborder"} text="Bank Details" />
      <Box
        sx={{
          background: "#F7F7F7",
          width: "100%",
          borderRadius: 5,
          marginBottom: 2,
          padding: 2,
        }}
      >
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box display="flex" flexDirection="column">
            <span style={{ fontSize: 12, marginBottom: 8 }}>
              Bank beneficiary name:
            </span>
            <Typography>
              {partnerNoc?.data?.bankDetails?.bankBeneficiaryName}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column">
            <span style={{ fontSize: 12, marginBottom: 8 }}>Account No:</span>
            <Typography>
              {partnerNoc?.data?.bankDetails?.accountNumber}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column">
            <span style={{ fontSize: 12, width: 70, marginBottom: 8 }}>
              IFSC:
            </span>
            <Typography>{partnerNoc?.data?.bankDetails?.ifsc}</Typography>
          </Box>
        </Box>
        <Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            gap={1}
            mt={4}
          >
            <Button
              onClick={() =>
                openInNewTab(partnerNoc?.data?.bankDetails?.cancelledChequeUrl)
              }
              startIcon={<VisibilityIcon />}
              sx={{
                background: "#EEF6FD",
                color: "#0691FC",
                fontSize: 12,
              }}
            >
              View Cancelled Cheque
            </Button>

            {bank == null ? (
              <>
                <Box flexGrow={1} />
                <Box>
                  <Button
                    onClick={() => setBank(true)}
                    startIcon={<DoneIcon />}
                    sx={{
                      background: "#E2F1E6",
                      color: "#23963D",
                      fontSize: 12,
                      textTransform: "capitalize",
                    }}
                    fullWidth
                  >
                    Valid
                  </Button>
                </Box>
                <Button
                  onClick={() => setBank(false)}
                  startIcon={<CloseIcon />}
                  sx={{
                    background: "#F9E5E5",
                    color: "#DF0000",
                    fontSize: 12,
                    textTransform: "capitalize",
                  }}
                >
                  Invalid
                </Button>
              </>
            ) : bank ? (
              <Box display="flex" flexDirection="row">
                <Box flexGrow={1} />
                <Button
                  onClick={() => setBank(true)}
                  startIcon={<DoneIcon />}
                  sx={{
                    color: "#23963D",
                    fontSize: 12,
                    textTransform: "capitalize",
                  }}
                  variant="text"
                >
                  Validated
                </Button>
                <Button
                  onClick={() => {
                    setBank(null);
                    setBankReason("");
                  }}
                  sx={{ color: "#0691FC", fontSize: 12 }}
                >
                  Edit
                </Button>
              </Box>
            ) : (
              <Box display="flex" flexDirection="row" gap={1}>
                <DropDown
                  width={180}
                  value={bankReason}
                  onChange={handleBankChange}
                  data={partnerNoc?.data?.bankDetails?.chequeInvalidReason}
                  placeholder="Select Reason"
                />
                <Button
                  variant="text"
                  onClick={() => setBank(false)}
                  startIcon={<CloseIcon />}
                  sx={{
                    color: "#DF0000",
                    fontSize: 12,
                    minWidth: "unset",
                    textTransform: "capitalize",
                  }}
                >
                  Invalidated
                </Button>
                <Button
                  onClick={() => {
                    setBank(null);
                    setBankReason("");
                  }}
                  sx={{ color: "#0691FC", fontSize: 12 }}
                >
                  Edit
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
