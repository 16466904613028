import * as React from "react";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import SendIcon from "@mui/icons-material/Send";
import "./style.css";
import { ReactComponent as Avatar } from "../../assets/icons/Avatar.svg";
import { SalesPostCommentAPI } from "../../store/action/salesQuote";

export default function SalesVerticalLinearStepper({ id }) {
  const data = useSelector((state) => state.salesQuote.quoteDetails);
  const [comment, setComment] = React.useState("");
  const dispatch = useDispatch();

  const handleSendComment = () => {
    if (comment.trim()) {
      dispatch(SalesPostCommentAPI(comment, id));
      setComment("");
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSendComment();
    }
  };

  return (
    <>
      <div className="chatContainer">
        {data?.data?.quoteCommentList?.map((i, index) => (
          <div className="chatDiv" key={`chat-text-${index}`}>
            {i.userType === "OPS" ? (
              <img
                src="/Ellipse 63.svg"
                style={{ width: "32px", height: "32px" }}
                alt="avatar"
              />
            ) : (
              <Avatar
                style={{ width: "32px", height: "32px" }}
                className="avatar"
              />
            )}
            <div>
              <h2 className="chatHeading">{i?.comment}</h2>
              <h2
                style={{
                  fontSize: "10px",
                  fontWeight: 400,
                  color: "#000",
                }}
              >
                {i?.timestamp.split("T")[0]}
              </h2>
            </div>
          </div>
        ))}

        <div
          className="chatInput"
          style={{
            width: 300,
            height: 38,
            borderRadius: 35,
            border: "1px solid #CCCCCC",
            padding: "4px 12px",
            display: "flex",
            flexDirection: "row",
            position: "fixed",
            bottom: 16,
            background: "#fff",
          }}
        >
          <input
            type="text"
            style={{ width: "90%", height: "100%" }}
            placeholder="Comment Here..."
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <Box
            onClick={handleSendComment}
            sx={{
              color: "#23963D",
              background: "#0691FC",
              borderRadius: 20,
              textTransform: "capitalize",
              width: 30,
              height: 30,
              padding: "4px 6px",
              cursor: "pointer",
            }}
          >
            <SendIcon sx={{ color: "#fff", fontSize: 20 }} />
          </Box>
        </div>
      </div>
    </>
  );
}
