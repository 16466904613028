import { message } from "../../components/generic/SnakeAlert/SnakeAlert";
import { getSalePartnerManagerList } from "../api";
import axios from "../api/axios";

export const GET_MANAGER_LIST_SUCCESS = "GET_MANAGER_LIST_SUCCESS";
export const GET_MANAGER_LIST_FAILURE = "GET_MANAGER_LIST_FAILURE";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SALES_MANAGER_DATA_LOADING = "SALES_MANAGER_DATA_LOADING";

// Action creator to fetch the manager list
export const salesPartnerManagerListAction = (search) => async (dispatch) => {
  try {
    dispatch({ type: SALES_MANAGER_DATA_LOADING, data: true });

    const response = await axios.get(getSalePartnerManagerList, {
      params: search,
    });

    if (response.status === 200) {
      const { salesManagerPartnersList, pagination, hierarchyList, hierarchy } =
        response.data.data;

      dispatch({
        type: GET_MANAGER_LIST_SUCCESS,
        payload: {
          salesManagerPartnersList,
          pagination,
          hierarchyList,
          hierarchy,
        },
      });
    } else {
      throw new Error(response.data.msg || "Failed to fetch booking list");
    }
  } catch (error) {
    dispatch({
      type: GET_MANAGER_LIST_FAILURE,
      error: error.message,
    });
    message.error("Error fetching list: " + error.message);
  } finally {
    dispatch({ type: SALES_MANAGER_DATA_LOADING, data: false });
  }
};
