import { message } from "../../components/generic/SnakeAlert/SnakeAlert";
import {
  addPartnerURL,
  hierarchylistUrl,
  verifyMobileURL,
  verifyOtpURL,
  verifyPanURL,
} from "../api";
import axios from "../api/axios";

export const verifyMobileAPI =
  (data, setOtpBox, setPhoneDisable, setSeconds) => async () => {
    try {
      let response = await axios.post(verifyMobileURL, data);
      if (response.status === 200) {
        setOtpBox(true);
        setPhoneDisable("true");
        setSeconds(45);
        message.success(response.data.msg);
      }
    } catch (error) {
      message.error(error.response.data.msg);
    }
  };

export const verifyOtpAPI =
  (data, setOtpBox, setPhoneDisable) => async () => {
    try {
      let response = await axios.post(verifyOtpURL, data);
      if (response.status === 200) {
        setOtpBox(false);
        setPhoneDisable("false");
      }
    } catch (error) {
      message.error(error.response.data.msg);
    }
  };

export const verifyPanAPI = (data, setIsPanLoading) => async () => {
  try {
    setIsPanLoading(true);
    let response = await axios.post(verifyPanURL, data);
    if (response.status === 200) {
      setIsPanLoading(false);
    }
  } catch (error) {
    message.error(error.response.data.msg);
    setIsPanLoading(false);
  }
};

export const AddPartnerAPI = (data, setOpenModal) => async () => {
  try {
    let response = await axios.post(addPartnerURL, data);
    if (response.status === 200) {
      setOpenModal(true);
    }
  } catch (error) {
    message.error(error.response.data.msg);
  }
};
export const hierarchylistAPI = (partnerType) => async (dispatch) => {
  try {
    let response = await axios.get(hierarchylistUrl, {
      params: {
        partnerType,
      },
    });
    if (response.status === 200) {
      dispatch({ type: "GET_HIERACHY_LIST", data: response.data });
    }
  } catch (error) {
    throw error.message;
  }
};
