import { Box, Button, Typography } from "@mui/material";
import React from "react";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import DropDown from "../generic/DropDown/DropDown";
import Heading from "../generic/Heading/Heading";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

export default function IIBStatus({
  partnerNoc,
  iibStatus,
  setIibStatus,
  iibData,
  setIibData,
}) {

  const onChangeIIB = (value, formKey, indx) => {
    const orgData = [...iibData];
    orgData[indx][formKey] = value;
    setIibData([...orgData]);
  };

  const handleDelete = (removeIndex) => {
    setIibData((oldArray) => {
      return oldArray.filter((value, i) => i !== removeIndex);
    });
  };

  return (
    <Box>
      {iibStatus === null ? (
        <>
          {" "}
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Heading type={"withborder"} text="IIB Status" />
            <Typography
              color="#DF0000"
              onClick={() => setIibStatus(false)}
              sx={{ cursor: "pointer", mt: 3, fontSize: 14 }}
            >
              No Record Found
            </Typography>
          </Box>
          <Box
            sx={{
              background: "#F7F7F7",
              width: "100%",
              borderRadius: 5,
              marginBottom: 2,
              padding: 2,
            }}
          >
            {iibData?.map((i, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 1,
                  mb: 2,
                }}
              >
                <DropDown
                  value={iibData[index]["insurerType"]}
                  name="insurerType"
                  onChange={(e) =>
                    onChangeIIB(e.target.value, "insurerType", index)
                  }
                  data={partnerNoc?.data?.iibStatusWidget?.data?.insuranceType}
                  placeholder="Select Insurance type"
                />
                <DropDown
                  value={iibData[index]["insurerName"]}
                  name="insurerName"
                  onChange={(e) =>
                    onChangeIIB(e.target.value, "insurerName", index)
                  }
                  data={iibData[index]["insurerType"] && partnerNoc?.data?.iibStatusWidget?.data 
                  ? partnerNoc?.data?.iibStatusWidget?.data[iibData[index]["insurerType"].toLowerCase().replace(/\s/g, "") + "Insurers"] || [] : []}
                  placeholder="Select insurer"
                />
                <DropDown
                  value={iibData[index]["iibStatus"]}
                  name="iibStatus"
                  onChange={(e) =>
                    onChangeIIB(e.target.value, "iibStatus", index)
                  }
                  data={partnerNoc?.data?.iibStatusWidget?.data?.status}
                  placeholder="Select Status"
                />

                {index !== 0 && (
                  <CloseIcon
                    onClick={() => handleDelete(index)}
                    sx={{
                      color: "#DF0000",
                      mt: 1.5,
                      ml: 1,
                      cursor: "pointer",
                    }}
                  />
                )}
              </Box>
            ))}
            {iibData?.length <= 2 && (
              <Button
                onClick={() =>
                  setIibData((oldArray) => [
                    ...oldArray,
                    { insurerType: "", insurerName: "", iibStatus: "" },
                  ])
                }
                startIcon={<AddCircleOutlineIcon />}
                sx={{
                  color: "#0691FC",
                  display: "flex",
                  ml: "auto",
                  mt: 1,
                  fontSize: 12,
                }}
                variant="text"
                size="small"
              >
                Add More
              </Button>
            )}
          </Box>
        </>
      ) : iibStatus ? (
        <>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Heading type={"withborder"} text="IIB Status" />
            <Typography
              color="#DF0000"
              onClick={() => setIibStatus(false)}
              sx={{ cursor: "pointer", mt: 3, fontSize: 14 }}
            >
              No Record Found
            </Typography>
          </Box>
          <Box
            sx={{
              background: "#F7F7F7",
              width: "100%",
              borderRadius: 5,
              marginBottom: 2,
              padding: 2,
            }}
          >
            {iibData?.map((i, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 1,
                  mb: 2,
                }}
              >
                <DropDown
                  value={iibData[index]["insurerType"]}
                  name="insurerType"
                  onChange={(e) =>
                    onChangeIIB(e.target.value, "insurerType", index)
                  }
                  data={partnerNoc?.data?.iibStatusWidget?.data?.insuranceType}
                  placeholder="Select Insurance type"
                />
                <DropDown
                  value={iibData[index]["insurerName"]}
                  name="insurerName"
                  onChange={(e) =>
                    onChangeIIB(e.target.value, "insurerName", index)
                  }
                  data={partnerNoc?.data?.iibStatusWidget?.data?.insurer}
                  placeholder="Select insurer"
                />
                <DropDown
                  value={iibData[index]["iibStatus"]}
                  name="iibStatus"
                  onChange={(e) =>
                    onChangeIIB(e.target.value, "iibStatus", index)
                  }
                  data={partnerNoc?.data?.iibStatusWidget?.data?.status}
                  placeholder="Select Status"
                />

                {index !== 0 && (
                  <CloseIcon
                    onClick={() => handleDelete(index)}
                    sx={{
                      color: "#DF0000",
                      mt: 1.5,
                      ml: 1,
                      cursor: "pointer",
                    }}
                  />
                )}
              </Box>
            ))}
            {iibData?.length <= 2 && (
              <Button
                onClick={() =>
                  setIibData((oldArray) => [
                    ...oldArray,
                    { insurerType: "", insurerName: "", iibStatus: "" },
                  ])
                }
                startIcon={<AddCircleOutlineIcon />}
                sx={{
                  color: "#0691FC",
                  display: "flex",
                  ml: "auto",
                  mt: 1,
                  fontSize: 12,
                }}
                variant="text"
                size="small"
              >
                Add More
              </Button>
            )}
          </Box>
        </>
      ) : (
        <Box
          display="flex"
          padding="16px"
          gap={2}
          width="100%"
          sx={{
            background: "#F7F7F7",
            width: "100%",
            height: 80,
            borderRadius: 5,
            marginBottom: 2,
            padding: 2,
          }}
        >
          <Button
            startIcon={<DoneIcon />}
            sx={{ color: "#23963D", ml: 12, fontSize: 12 }}
            fullWidth
            variant="text"
          >
            Marked As No Record Found
          </Button>
          <Box flexGrow={1} />
          <Button
            onClick={() => {
              setIibStatus(null);
              setIibData([
                {
                  insurerType: "",
                  insurerName: "",
                  iibStatus: "",
                },
              ]);
            }}
            sx={{ color: "#0691FC", fontSize: 12 }}
          >
            Edit
          </Button>
        </Box>
      )}
    </Box>
  );
}
