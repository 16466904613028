import React from "react";
import Pending from "../../assets/Lottie/pendingClock.json";
import LottiePlayer from "../../components/Lottie/LottiePlayer";
import { Button, Typography } from "@mui/material";
import Heading from "../../components/generic/Heading/Heading";

const SalesInspectionAwaited = () => {
  return (
    <>
      <Heading text="Inspection Details" type="withborder" />
      <Typography
        component={"div"}
        sx={{
          backgroundColor: "white",
          width: "100%",
          height: "350px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          boxShadow: "0px 1px 6px 0px rgba(0, 0, 0, 0.25)",
          borderRadius: "16px",
          gap: "24px",
          margin: "20px 0px",
        }}
      >
        <LottiePlayer width={"150px"} height={"150px"} image={Pending} />
        <Typography
          component={"div"}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "18px",
          }}
        >
          <Typography
            component={"span"}
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              color: "black",
              lineHeight: "24px",
            }}
          >
            Inspection Agency Details Awaited!
          </Typography>{" "}
          <Typography
            component={"span"}
            sx={{
              fontSize: "12px",
              color: "Grey/40",
              lineHeight: "18px",
              fontWeight: "400",
            }}
          >
            Please relax while we are fetching the third party inspection
            details
          </Typography>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            sx={{
              margin: "10px 15px",
              padding: "10px 23px",
              backgroundColor: "#0691fc",
              width: "max-content",
            }}
          >
            Okay
          </Button>
        </Typography>
      </Typography>
    </>
  );
};

export default SalesInspectionAwaited;
