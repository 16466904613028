import {
  GET_DASHBOARD_DATA_SUCCESS,
  GET_DASHBOARD_DATA_FAILURE,
  GET_DASHBOARD_LOADING,
} from "../action/dashboard";

const initialState = {
  bookingDetails: {},
  mappingDetails: {},
  quoteDetails: {},
  topPerformingPartners: [],
  onboardingDetails: {
    bucketList: [{}],
    total: 0,
  },
  loading: true,
  error: null,
};

export const salesDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        bookingDetails: action.payload.bookingDetails,
        mappingDetails: action.payload.mappingDetails,
        quoteDetails: action.payload.quoteDetails,
        topPerformingPartners: action.payload.topPerformingPartners,
        onboardingDetails: {
          ...state.onboardingDetails,
          bucketList: action.payload.onboardingDetails.bucketList,
          total: action.payload.onboardingDetails.total,
        },
        loading: false,
        error: null,
      };
    case GET_DASHBOARD_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case GET_DASHBOARD_LOADING:
      return {
        ...state,
        loading: action.data,
      };
    default:
      return state;
  }
};
