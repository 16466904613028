export const PARTNERS = "PARTNERS";
export const PARTNER_VERIFICATION = "PARTNER_VERIFICATION";
export const ADD_PARTNER = "ADD_PARTNER";
export const OPS_QUOTE_REQUEST = "OPS_QUOTE_REQUEST";
export const OPS_MAPPING_REQUEST = "OPS_MAPPING_REQUEST";
export const GRID = "GRID";
export const SALES_PARTNER = "SALES_PARTNER";
export const SALES_MAPPING = "SALES_MAPPING";
export const SALES_BOOKING = "SALES_BOOKING";
export const SALES_QUOTE = "SALES_QUOTE";
export const SALES_DASHBOARD = "SALES_DASHBOARD";
export const SALES_MANAGER_PRE_ISSUANCE = "SALES_MANAGER_PRE_ISSUANCE";
export const SALES_MANAGER_PARTNERS = "SALES_MANAGER_PARTNERS";
export const SALES_MANAGER_BOOKINGS = "SALES_MANAGER_BOOKINGS";
