import { Stack } from "@mui/material";
import React from "react";
import Pagination from "@mui/material/Pagination";

const Paginations = ({ currentPage, totalPage, handlePageChange }) => {
  return (
    <>
      <Stack
        spacing={2}
        sx={{
          margin: " 24px  auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Pagination
          color="primary"
          count={totalPage} // Total number of pages
          page={currentPage} // Current page
          onChange={handlePageChange}
        />
      </Stack>
    </>
  );
};

export default Paginations;
