import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Typography } from "@mui/material";

export default function DashboardToggle({ value, onChange }) {
  return (
    <div>
      <ToggleButtonGroup
        value={value} // Use the value passed from props
        onChange={onChange} // Directly handle the change
        exclusive
        aria-label="Platform"
        sx={{
          // color: "white",
          border: "1px solid #B3B3B3",
          borderRadius: "12px",
          padding: "2px",
          height: "40px",
        }}
      >
        <Typography sx={{ display: "flex", alignItems: "center", gap: "3px" }}>
          <ToggleButton
            value="MAPPING"
            sx={{
              height: "36px",
              padding: "12px 20px 12px 20px",
              borderRadius: "10px",
              textTransform: "none",
              fontWeight: "400",
              fontSize: "14px",
              color: value === "MAPPING" ? "white" : "",
              backgroundColor: value === "MAPPING" ? "black" : "",
              "&.Mui-selected": {
                color: "white",
                backgroundColor: "black", // Background color when selected
                borderRadius: "10px",
              },
              "&.MuiToggleButtonGroup-firstButton": {
                borderRadius: "10px",
              },
              "&:hover": {
                color: "white",
                backgroundColor: value === "MAPPING" ? "black" : "#f0f0f0",
              },
              "&.MuiToggleButtonGroup-grouped ": {
                border: "none ",
              },
            }}
          >
            Mapping Date
          </ToggleButton>
          <ToggleButton
            value="ISSUANCE"
            sx={{
              height: "36px",
              padding: "12px 20px 12px 20px",
              borderRadius: "10px",
              textTransform: "none",
              fontWeight: "400",
              fontSize: "14px",
              color: value === "ISSUANCE" ? "white" : "", // Text color based on value
              backgroundColor: value === "ISSUANCE" ? "black" : "", // Background color
              "&.Mui-selected": {
                color: "white", // Text color when selected
                backgroundColor: "black",
              },
              "&.MuiToggleButtonGroup-lastButton": {
                borderRadius: "10px",
              },
              "&:hover": {
                color: "white", // Text color on hover
                backgroundColor: value === "ISSUANCE" ? "black" : "#f0f0f0",
              },
              "&.MuiToggleButtonGroup-grouped ": {
                border: "none ",
              },
            }}
          >
            Issuance Date
          </ToggleButton>
        </Typography>
      </ToggleButtonGroup>
    </div>
  );
}
