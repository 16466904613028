import axios from "../api/axios";
import { getInsurerAndAddOnsListURL, getInsurerIds } from "../api";
export const GET_INSURER_IDS_SUCCESS = "GET_INSURER_IDS_SUCCESS";
export const GET_INSURER_IDS_FAILURE = "GET_INSURER_IDS_FAILURE";
export const INSURER_IDS_LOADING = "INSURER_IDS_LOADING";

export const getInsurersList =
  ({ product, policyType }) =>
  async (dispatch) => {
    try {
      let response = await axios.get(getInsurerAndAddOnsListURL, {
        params: { product, policyType },
      });
      if (response.status === 200) {
        dispatch({ type: "GET_INSURER_LIST", data: response.data.data });
      }
    } catch (error) {
      throw error.message;
    }
  };

  export const insurerIdsAction = (search) => async (dispatch) => {
    try {
      dispatch({ type: INSURER_IDS_LOADING, data: true });
  
      const response = await axios.get(getInsurerIds, {
        params: search,
      });
  
      if (response.status === 200) {
        const { insurerList } = response.data.data;
        
        dispatch({
          type: GET_INSURER_IDS_SUCCESS,
          payload: insurerList,
        });
      } else {
        throw new Error(response.data.msg || "Failed to fetch insurer list");
      }
    } catch (error) {
      dispatch({
        type: GET_INSURER_IDS_FAILURE,
        error: error.message,
      });
    } finally {
      dispatch({ type: INSURER_IDS_LOADING, data: false });
    }
  };
