import Switch from "@mui/material/Switch";
import { useSelector } from "react-redux";

export default function BasicSwitches({ checked, onChange }) {
  const permissions = useSelector((state) => state.auth.permissions) || {};

  return permissions?.GRID?.EDIT ? (
    <div
      style={{ background: "#004880", padding: "5px", borderRadius: "5px " }}
    >
      <Switch
        checked={checked}
        onChange={onChange}
        color="warning"
        inputProps={{ "aria-label": "controlled" }}
      />
      <p style={{ fontSize: "13px " }}> {checked ? "PAY IN" : "PAY OUT"}</p>
    </div>
  ) : (
    ""
  );
}
