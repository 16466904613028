import React from "react";
import { Box, Typography, Container, Button } from "@mui/material";
import { styled } from "@mui/system";

const ErrorIcon = styled("div")(({ theme }) => ({
  fontSize: "80px",
  color: theme.palette.error.main,
  marginBottom: theme.spacing(2),
}));

const ErrorCode = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  fontSize: "20px",
  color: theme.palette.text.secondary,
}));

const AccessDenied = () => {
  return (
    <Container maxWidth="sm" sx={{ textAlign: "center", marginTop: "100px" }}>
      <Box>
        <ErrorIcon>✖</ErrorIcon>
        <Typography
          variant="h1"
          component="h1"
          sx={{ fontSize: "48px", fontWeight: "bold" }}
        >
          Access Denied
        </Typography>
        <Typography variant="body1" sx={{ marginTop: 2 }}>
          You do not have permission to view this page.
        </Typography>
        <Typography variant="body1">
          Please check your credentials and try again.
        </Typography>
        <ErrorCode variant="body2">Error Code: 403</ErrorCode>
        <Button variant="contained" color="primary" sx={{ marginTop: 3 }}>
          Go Back
        </Button>
      </Box>
    </Container>
  );
};

export default AccessDenied;
