import React, { useEffect, useState } from "react";
import Header from "../../components/generic/Header/Header";
import { CircularProgress, Stack, Typography } from "@mui/material";
import DropDown from "../../components/generic/DropDown/DropDown";
import "flatpickr/dist/plugins/monthSelect/style.css";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect/index";
import SalesManagerTable from "./SalesManagerTable";
import { useDispatch, useSelector } from "react-redux";
import { salesManagerListAction } from "../../store/action/salesManager";
import Paginations from "../../components/generic/Pagination/Pagination";
import EmployeeBreadcrumbs from "../../components/EmployeeBreadcrumbs/EmployeeBreadcrumbs";
import NoDataFound from "../../components/generic/NoDataFound/NoDataFound";
import DatePicker from "../../components/generic/DatePicker/DatePicker";

const ManagerDashboard = () => {
  const dispatch = useDispatch();
  const { preIssuanceList, loading, pagination, hierarchyList, hierarchy } =
    useSelector((state) => state?.salesManagerDashboard);

  const [currentPage, setCurrentPage] = useState(pagination?.curPage || 1);
  const [filterByDate, setFilterByDate] = useState();
  const [product, setProduct] = useState("");
  const [selectedHierarchy, setSelectedHierarchy] = useState([]);
  //page Change
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };
  // date function
  const handleDateChange = (selectedDates) => {
    const selectedDate = selectedDates[0];
    setFilterByDate(selectedDate);
  };

  const handleRowClick = (employee) => {
    setSelectedHierarchy([{ employeeId: employee.employeeeId }]);
  };
  const handleBreadcrumbClick = (employee) => {
    setSelectedHierarchy([{ employeeId: employee.employeeId }]);
  };

  useEffect(() => {
    let searchParams = {};
    if (currentPage) {
      searchParams["curPage"] = currentPage;
    }

    if (filterByDate) {
      searchParams["month"] = filterByDate.getMonth().toString();
      searchParams["year"] = filterByDate.getFullYear().toString();
    }
    if (product) {
      searchParams["product"] = product;
    }
    if (hierarchy) {
      searchParams["hierarchy"] = hierarchy;
    }

    if (selectedHierarchy.length > 0) {
      searchParams["curManagerId"] = selectedHierarchy[0].employeeId; // Use employeeId from selectedHierarchy
    }
    dispatch(salesManagerListAction(searchParams));
  }, [filterByDate, currentPage, product, selectedHierarchy]);

  return (
    <>
      <Header title="Pre Issuance Dashboard" />

      <Typography component={"div"} sx={{ paddingBottom: "20px" }}>
        <EmployeeBreadcrumbs
          hierarchyList={hierarchyList}
          selectedHierarchy={selectedHierarchy}
          handleBreadcrumbClick={handleBreadcrumbClick}
        />
      </Typography>

      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          <div className="list-wrapper">
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              justifyContent="end"
              margin="10px 10px"
            >
              <label htmlFor="partner-dropdown" className="filter-label">
                Filters :
              </label>
              <DropDown
                placeholder={"Product - Motor"}
                onChange={(e) =>
                  setProduct(
                    e.target.value === "No Product" ? "" : e.target.value,
                  )
                }
                value={product}
                data={[
                  "CAR",
                  "TW",
                  "GCV",
                  "PCV",
                  "BUS",
                  "MISC_D",
                  "No Product",
                ]}
                width={160}
              />
              <div>
                <DatePicker
                  placeholder=" Month - Year"
                  options={{
                    dateFormat: "m-Y",
                    maxDate: new Date(),
                    mode: "single",
                    // allowInput: true,
                    disableMobile: true,
                    plugins: [
                      new monthSelectPlugin({
                        shorthand: true,
                        dateFormat: "F Y",
                        altFormat: "F Y",
                      }),
                    ],
                  }}
                  style={{ width: "100%" }}
                  onChange={handleDateChange}
                  value={filterByDate}
                />
              </div>
            </Stack>

            <Typography component={"div"} sx={{ marginTop: "15px" }}>
              <SalesManagerTable
                preIssuanceList={preIssuanceList}
                onRowClick={handleRowClick}
              />
            </Typography>
          </div>
          <div>{preIssuanceList.length === 0 ? <NoDataFound /> : ""}</div>
        </div>
      )}
      <Paginations
        currentPage={currentPage}
        totalPage={pagination?.totalPage || 1}
        handlePageChange={handlePageChange}
      />
    </>
  );
};

export default ManagerDashboard;
