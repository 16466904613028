import { Typography } from "@mui/material";
import React from "react";

const Addons = ({ addOns }) => {
  return (
    <div
      style={{
        backgroundColor: "#E8F3FC",
        alignItems: "center",
        padding: "4px 20px",
        display: "flex",
        flexWrap: "wrap",
        gap: "2px",
        color: "black",
      }}
    >
      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: "600 !important",
        }}
      >
        Addons:{" "}
      </Typography>
      <Typography
        // variant="p"
        sx={{
          fontSize: "12px",
          fontWeight: "400 !important",
        }}
      >
        {addOns.join(", ")}
      </Typography>
    </div>
  );
};

export default Addons;
