import axios from "../api/axios";
import { message } from "../../components/generic/SnakeAlert/SnakeAlert";
import {
  getSalesQuoteListURL,
  getSalesQuoteDetailURL,
  salesPostCommentURL,
  getQuoteUploadDocumentsDetailsForm,
} from "../api";
export const SALES_QUOTE_LOADING = "SALES_QUOTE_LOADING";
export const GET_SALES_QUOTE_LIST = "GET_SALES_QUOTE_LIST";
export const GET_QUOTE_DETAILS = "GET_QUOTE_DETAILS";
export const RESET_SALES_QUOTE = "RESET_SALES_QUOTE";

export const getSalesQuoteListAPI = (search) => async (dispatch) => {
  try {
    dispatch({ type: SALES_QUOTE_LOADING, data: true });
    let response = await axios.get(getSalesQuoteListURL, {
      params: search,
    });
    if (response.status === 200 && response.data && response.data.data) {
      dispatch({ type: GET_SALES_QUOTE_LIST, data: response.data.data });
    }
  } catch (error) {
    throw error.message;
  } finally {
    dispatch({ type: SALES_QUOTE_LOADING, data: false });
  }
};

export const getSalesQuoteDetailAPI = (quoteId) => async (dispatch) => {
  try {
    dispatch({ type: SALES_QUOTE_LOADING, data: true });
    let response = await axios.get(getSalesQuoteDetailURL, {
      params: {
        quoteId,
      },
    });
    if (response.status === 200) {
      dispatch({ type: GET_QUOTE_DETAILS, data: response.data });
    }
  } catch (error) {
    throw error.message;
  } finally {
    dispatch({ type: SALES_QUOTE_LOADING, data: false });
  }
};

export const SalesPostCommentAPI = (comment, quoteId) => async (dispatch) => {
  try {
    const data = {
      quoteId: quoteId,
      comment: comment,
    };
    let response = await axios.post(salesPostCommentURL, data);
    if (response.status === 200) {
      dispatch(getSalesQuoteDetailAPI(quoteId));
      message.success(response.data.msg);
    }
  } catch (error) {
    message.error(error.response.data.msg);
  }
};

export const reUploadDocs = (formData) => async (dispatch) => {
  try {
    dispatch({ type: "POST_DATA_REQUEST" });
    // Make the API call
    const response = await axios.post(
      getQuoteUploadDocumentsDetailsForm,
      formData,
    );

    if (response.status === 200) {
      dispatch({
        type: "POST_DATA_SUCCESS",
        payload: response.data.data,
      });

      message.success(response.data.msg);

      return { success: true, payload: response.data };
    } else {
      throw new Error(response.data.msg || "Unexpected error occurred.");
    }
  } catch (error) {
    let errorMessage = "Something went wrong";
    if (error.response && error.response.data && error.response.data.msg) {
      errorMessage = error.response.data.msg;
    }

    // Dispatch failure action with the error message
    dispatch({
      type: "POST_DATA_FAILURE",
      error: errorMessage,
    });
    message.error(errorMessage);
    return { success: false, error: errorMessage };
  }
};
