import { Box, Button, Typography } from "@mui/material";
import React from "react";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import Heading from "../generic/Heading/Heading";
export default function PosStatus({ pos, setPos, partnerNoc }) {
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Heading type={"withborder"} text="POS Status" />
        <Typography
          color="#0691FC"
          sx={{ cursor: "pointer", mt: 3, fontSize: 14 }}
          onClick={() =>
            openInNewTab(partnerNoc?.data?.posStatusWidget?.portalUrl)
          }
        >
          Check on Portal
        </Typography>
      </Box>
      <Box
        sx={{
          background: "#F7F7F7",
          width: "100%",
          borderRadius: 5,
          marginBottom: 2,
        }}
      >
        {pos == null ? (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            padding="16px"
            gap={2}
          >
            <Button
              onClick={() => setPos(false)}
              startIcon={<DoneIcon />}
              sx={{
                background: "#E2F1E6",
                color: "#23963D",
                fontSize: 12,
                padding: "12px",
                borderRadius: 2,
              }}
              fullWidth
            >
              No Record found
            </Button>
            <Button
              onClick={() => setPos(true)}
              startIcon={<CloseIcon />}
              sx={{
                background: "#F9E5E5",
                color: "#DF0000",
                fontSize: 12,
                padding: "12px",
                borderRadius: 2,
              }}
              fullWidth
            >
              Record found
            </Button>
          </Box>
        ) : pos ? (
          <Box display="flex" padding="16px" gap={2} width="100%">
            <Button
              startIcon={<CloseIcon />}
              sx={{
                color: "#DF0000",
                ml: 12,
                fontSize: 12,
                padding: "12px",
                borderRadius: 2,
              }}
              fullWidth
            >
              Record Found
            </Button>
            <Box flexGrow={1} />
            <Button
              onClick={() => setPos(null)}
              sx={{ color: "#0691FC", fontSize: 12 }}
            >
              Edit
            </Button>
          </Box>
        ) : (
          <Box display="flex" padding="16px" gap={2} width="100%">
            <Button
              startIcon={<DoneIcon />}
              sx={{
                color: "#23963D",
                ml: 12,
                fontSize: 12,
                padding: "12px",
                borderRadius: 2,
              }}
              fullWidth
              variant="text"
            >
              No Record Found
            </Button>
            <Box flexGrow={1} />
            <Button
              onClick={() => setPos(null)}
              sx={{ color: "#0691FC", fontSize: 12 }}
            >
              Edit
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
}
