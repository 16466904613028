import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
} from "@mui/material";
import React from "react";
import { productTypeImageObj, quoteStatesBGColor } from "../../helpers/enums";
import { formatDate } from "../../utils/utils";
import "./index.css";
import { useSelector } from "react-redux";
import TableRow from "@mui/material/TableRow";
import NoDataFound from "../generic/NoDataFound/NoDataFound";

const SalesQuoteTable = ({ rows }) => {
  const permissions = useSelector((state) => state?.auth?.permissions) || {};

  const handleDialogOpen = (id) => {
    const isEditable = permissions?.SALES_QUOTE?.EDIT;
    if (isEditable) {
      window.open(`/app/sales/quote-modal/${id}`, "_blank");
    }
  };

  const handleBGColor = (key) => {
    return quoteStatesBGColor[key] || "#FFBB0D";
  };

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer sx={{ height: "calc(100vh - 330px)" }}>
        {rows?.length !== 0 ? (
          <Table
            sx={{
              minWidth: 750,
              borderCollapse: "separate",
              borderSpacing: " 0px 12px ",
            }}
            aria-labelledby="tableTitle"
          >
            <TableBody>
              {rows?.map((row, index) => {
                return (
                  <TableRow
                    className={
                      row.quoteState === "DOCUMENT_REUPLOAD"
                        ? "mapping_table_row cursor-pointer"
                        : "mapping_table_row"
                    }
                    key={`${row}-${index}`}
                    sx={{
                      marginBottom: "16px",
                      background: "white",
                      cursor: "pointer",
                      height: "90px",
                    }}
                    rowSpacing={2}
                    onClick={() => handleDialogOpen(row?.quoteId)}
                  >
                    <TableCell
                      align="start "
                      sx={{
                        width: "12%",
                        borderRadius: "16px 0 0 16px",
                      }}
                    >
                      <div style={{ display: "flex", gap: "16px" }}>
                        <div
                          style={{
                            padding: "16px",
                            borderRadius: "8px",
                            background: "#fafafa",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {productTypeImageObj[row?.product] || ""}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                          }}
                        >
                          <span
                            style={{
                              display: "-webkit-box",
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: 120,
                              color: "#000",
                              fontSize: 14,
                              fontWeight: "600",
                            }}
                          >
                            {row?.registrationNumber}
                          </span>
                          <span
                            style={{
                              color: "#808080",
                              fontSize: 12,
                              fontWeight: "400",
                            }}
                          >
                            {row?.customerName}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="start" sx={{ width: "12%" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <span
                          style={{
                            color: "#808080",
                            fontSize: 12,
                            fontWeight: "400",
                          }}
                        >
                          Partner
                        </span>
                        <span
                          className="row-partnerID"
                          style={{
                            color: "#0691FC",
                            fontSize: 14,
                            fontWeight: "600",
                          }}
                        >
                          {row?.partnerId}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell align="start " sx={{ width: "12%" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <span
                          style={{
                            color: "#808080",
                            fontSize: 12,
                            fontWeight: "400",
                          }}
                        >
                          Ticket ID
                        </span>
                        <span
                          style={{
                            color: "#181818",
                            fontSize: 14,
                            fontWeight: "400",
                          }}
                        >
                          {row?.quoteId}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell sx={{ width: "20%" }}>
                      <span
                        style={{
                          background: handleBGColor(row?.quoteState),
                          borderRadius: 20,
                          color: "#fff",
                          padding: "6px 12px",
                          fontSize: 12,
                          cursor: "default",
                        }}
                      >
                        {row?.quoteStateName}
                      </span>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ width: "12%", borderRadius: "0px 16px 16px 0px" }}
                    >
                      <span
                        className="date_content"
                        style={{
                          color: "#808080",
                          fontSize: 12,
                          fontWeight: "400",
                        }}
                      >
                        {row && formatDate(row.timestamp)}
                      </span>
                      {/* {row.quoteState === "DOCUMENT_REUPLOAD" && (
                        <div className="more_info">
                          <div className="btn_block">
                            <img src={Upload} alt="upload" />
                            <p className="mb-0 btn_title">Upload Docs</p>
                          </div>
                        </div>
                      )} */}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : (
          <div>
            <NoDataFound />
          </div>
        )}
      </TableContainer>
    </Box>
  );
};

export default SalesQuoteTable;
