import React, { useEffect, useState } from "react";
import Heading from "../../components/generic/Heading/Heading";
import { Box, Button } from "@mui/material";
import SuccessModal from "../../components/generic/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  shareQuoteApprovalAPI,
  submitAgencyDetailsAPI,
} from "../../store/action/quote";
import DialogBox from "../../components/generic/DialogBox";
import CutomeModalNoQuote from "../../components/Form/CutomeModalNoQuote";
import { getQuoteDetailAPI } from "../../store/action/quote";
import QuoteCardBox from "../../components/QuoteComponent/QuoteCardBox";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import FormInput from "../../components/FormInput/FormInput";
const AgencyDetailsSubmit = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [quoteId, setQuoteId] = useState("");
  const data1 = useSelector((state) => state.quote.quoteDetails);
  const quoteState = data1?.data?.quoteState;
  const [confirm, setConfirm] = useState(false);

  const initialInputState = [
    {
      placeHolder: "Inspection Id",
      value: "",
      key: "inspectionId",
      inputMode: "numeric",
      maxLength: 15,
      type: "number",
    },
    {
      placeHolder: "Agency Name",
      value: "",
      key: "agencyName",
      inputMode: "alpha",
    },
    {
      placeHolder: "Agency Email Id",
      value: "",
      key: "agencyEmail",
      // inputMode: "email",
    },
    {
      placeHolder: "Agency Mobile Number",
      value: "",
      key: "agencyMobileNumber",
      inputMode: "numeric",
      maxLength: 10,
      type: "number",
    },
  ];
  useEffect(() => {
    if (data1?.data?.header?.tickeId) {
      setQuoteId(data1?.data?.header?.tickeId);
    }
  }, [data1]);

  const schema = yup.object().shape({
    inspectionId: yup.string().required("Inspection ID is required"),
    agencyName: yup.string().required("Agency Name is required"),
    agencyEmail: yup
      .string()
      .required("Agency Email is required")
      .test("agencyEmail", "Invalid email", (value) => {
        return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);
      }),
    agencyMobileNumber: yup
      .string()
      .required("Agency Mobile Number is required")
      .matches(/^[0-9\b]+$/, "Mobile number must be digits")
      .min(10, "Mobile number must be 10 digits"),
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleAccept = (data) => {
    dispatch(
      submitAgencyDetailsAPI(
        { ...data, quoteId: data1?.data?.header?.ticketId },
        setOpen,
      ),
    );
  };

  const handleReject = () => {
    const data = {
      quoteRequestId: data1?.data?.header?.ticketId,
      isQuoteApproved: false,
      quoteDeclineReason: "test",
    };
    dispatch(shareQuoteApprovalAPI(data, quoteId, setOpen));
  };

  return (
    <div
      style={{
        width: "100%",
        padding: "0 30px 0 0",
      }}
    >
      <Heading text="Shared Quotes" type="withborder" />
      <div style={{ marginTop: "16px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "12px",
          }}
        >
          {data1?.data?.offlineQuotes?.map((i) => (
            <QuoteCardBox
              quoteData={i}
              key={i?.quoteRequestId}
              quoteState={quoteState}
            />
          ))}
        </div>

        {confirm && (
          <DialogBox
            width={500}
            open={confirm}
            setOpen={setConfirm}
            children={
              <CutomeModalNoQuote
                setOpen={setConfirm}
                title={"Are you sure?"}
                // subTitle={
                //   "You will be notified once partner accept/reject the quotes"
                // }
                confirmButton={"Reject quote"}
                cancelButton={"Cancel"}
                onClick={() => {
                  handleReject();
                  setConfirm(false);
                }}
              />
            }
          />
        )}

        <div>
          <Heading text="Inspection Details" type="withborder" />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
            }}
          >
            <div
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                padding: "12px",
                background: "var(--White, #FFF)",
                boxShadow: "0px 1px 6px 0px rgba(0, 0, 0, 0.25)",
              }}
            >
              <h1
                style={{
                  fontSize: "12px",
                  fontWeight: 600,
                  color: "#000",
                }}
              >
                Customer Details
              </h1>
              <div
                style={{
                  backgroundColor: "#E8F3FC",
                  padding: "12px",
                  borderRadius: "16px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  {data1?.data?.customerDetails?.map((i) => (
                    <div key={`customer-details-${i?.key}`}>
                      <h3
                        style={{
                          fontSize: "10px",
                          fontWeight: 400,
                          color: "var(--color-grey-40)",
                        }}
                      >
                        {i?.key}
                      </h3>
                      {i?.key && (
                        <p style={{ fontSize: "12px", fontWeight: 400 }}>
                          {i?.value}
                        </p>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              {/* First*/}
              {!open && (
                <div>
                  <h1
                    style={{
                      fontSize: "12px",
                      fontWeight: 600,
                      color: "#000",
                      marginBottom: "10px",
                    }}
                  >
                    Update Agency Details
                  </h1>
                  <form onSubmit={handleSubmit(handleAccept)}>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "10px",
                        // justifyContent: "center",
                      }}
                    >
                      {initialInputState?.map((input, index) => (
                        <Box
                          flexBasis={"calc(50% - 5px)"}
                          key={`input-${index}`}
                        >
                          <Controller
                            key={input.key}
                            name={input.key}
                            control={control}
                            render={({ field }) => (
                              <FormInput
                                field={field}
                                type={input.type || "text"}
                                placeholder={input?.placeHolder}
                                maxLength={input?.maxLength}
                                inputMode={input?.inputMode}
                              />
                            )}
                          />
                          {errors && errors[input.key] && (
                            <p className="error_class mt-5">
                              {errors[input.key].message}
                            </p>
                          )}
                        </Box>
                      ))}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "right",
                        marginTop: 24,
                      }}
                    >
                      <Button
                        type="submit"
                        style={{
                          backgroundColor: "#0691FC",
                          textTransform: "none",

                          color: "#fff",
                          borderRadius: "8px",
                          fontSize: "14px",
                          fontWeight: 600,
                          width: "116px",
                          height: "45px",
                        }}
                      >
                        SUBMIT
                      </Button>
                    </div>
                  </form>
                </div>
              )}

              {/* Second */}
              {open && (
                <SuccessModal
                  title={"Agency Details Shared Successfully!"}
                  subTitle={
                    "You will be notified once agency completes the inspection"
                  }
                  // btnDisabled
                  shareButton="Okay"
                  onClick={() => {
                    setOpen(false);
                    dispatch(getQuoteDetailAPI(quoteId));
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgencyDetailsSubmit;
