import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import React from "react";
import { mappingStatesBGColor, productTypeImageObj } from "../../helpers/enums";
import { formatDate } from "../../utils/utils";
import { ReactComponent as Upload } from "../../assets/images/QuoteRequest/Upload.svg";
import "./index.css";
import { useSelector } from "react-redux";
import NoDataFound from "../generic/NoDataFound/NoDataFound";

const SalesMappingTable = ({ rows, handleToToggleModal }) => {
  const handleBGColor = (key) => {
    return mappingStatesBGColor[key] || "#FFBB0D";
  };

  const permissions = useSelector((state) => state?.auth?.permissions) || {};

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer sx={{ height: "calc(100vh - 330px)" }}>
        {rows?.length !== 0 ? (
          <Table
            sx={{
              minWidth: 750,
              borderCollapse: "separate",
              borderSpacing: " 0px 12px ",
            }}
            aria-labelledby="tableTitle"
          >
            <TableBody>
              {rows?.map((row, index) => {
                const isEditable = permissions?.SALES_MAPPING?.EDIT;

                return (
                  <TableRow
                    className={
                      row.mappingState === "DOCUMENT_REUPLOAD"
                        ? "mapping_table_row cursor-pointer"
                        : "mapping_table_row"
                    }
                    key={`${row}-${index}`}
                    sx={{
                      marginBottom: "16px",
                      background: "white",
                      height: "90px",
                    }}
                    rowSpacing={2}
                    onClick={(e) =>
                      row.mappingState === "DOCUMENT_REUPLOAD" &&
                      handleToToggleModal(e, "upoad_doc", row.mappingId)
                    }
                  >
                    <TableCell
                      align="start "
                      sx={{
                        width: "12%",
                        borderRadius: "16px 0 0 16px",
                      }}
                    >
                      <div style={{ display: "flex", gap: "16px" }}>
                        <div
                          style={{
                            padding: "16px",
                            borderRadius: "8px",
                            background: "#fafafa",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {productTypeImageObj[row?.product] || ""}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                          }}
                        >
                          <span
                            style={{
                              display: "-webkit-box",
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: 120,
                              color: "#000",
                              fontSize: 14,
                              fontWeight: "600",
                            }}
                          >
                            {row?.registrationNumber}
                          </span>
                          <span
                            style={{
                              color: "#808080",
                              fontSize: 12,
                              fontWeight: "400",
                            }}
                          >
                            {row?.partnerName}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="start" sx={{ width: "12%" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <span
                          style={{
                            color: "#808080",
                            fontSize: 12,
                            fontWeight: "400",
                          }}
                        >
                          Partner
                        </span>
                        <span
                          className="row-partnerID"
                          style={{
                            color: "#0691FC",
                            fontSize: 14,
                            fontWeight: "600",
                          }}
                        >
                          {row?.partnerId}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell align="start " sx={{ width: "12%" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <span
                          style={{
                            color: "#808080",
                            fontSize: 12,
                            fontWeight: "400",
                          }}
                        >
                          Ticket ID
                        </span>
                        <span
                          style={{
                            color: "#181818",
                            fontSize: 14,
                            fontWeight: "400",
                          }}
                        >
                          {row?.mappingId}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell sx={{ width: "20%" }}>
                      <span
                        style={{
                          background: handleBGColor(row?.mappingState),
                          borderRadius: 20,
                          color: "#fff",
                          padding: "6px 12px",
                          fontSize: 12,
                          cursor: "default",
                        }}
                      >
                        {row?.mappingStateName}
                      </span>
                    </TableCell>
                    <TableCell
                      align="start"
                      sx={{ width: "20%", borderRadius: "0px 16px 16px 0px" }}
                    >
                      <span
                        className="date_content_none"
                        style={{
                          color: "#808080",
                          fontSize: 12,
                          fontWeight: "400",
                        }}
                      >
                        {row &&
                          formatDate(
                            row.mappingCreationTime,
                            "DD-MM-YYYY, hh:mm a",
                          )}
                      </span>
                      {row.mappingState === "DOCUMENT_REUPLOAD" &&
                        isEditable && (
                          <div className="more_info">
                            <div className="btn_block">
                              <Upload color="#0691FC" />
                              <p className="mb-0 btn_title">Upload Docs</p>
                            </div>
                          </div>
                        )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : (
          <div>
            <NoDataFound />
          </div>
        )}
      </TableContainer>
    </Box>
  );
};

export default SalesMappingTable;
