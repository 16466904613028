import React from "react";
import VerifyDocument from "../../components/generic/verifyDocument";

const VerifyDocsMappingRequests = ({
  docs,
  verifyDocuments,
  setVerifyDocuments,
}) => {
  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "10px",
        }}
      >
        {docs?.length > 0 ? (
          docs?.map((i, index) => (
            <VerifyDocument
              key={`verify-doc-${index}`}
              data={i}
              verifyDocuments={verifyDocuments}
              setVerifyDocuments={setVerifyDocuments}
              documentType={i?.documentType}
            />
          ))
        ) : (
          <>
            <p>No Data</p>
          </>
        )}
      </div>
    </>
  );
};

export default VerifyDocsMappingRequests;
