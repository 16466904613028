import React from "react";
import Header from "../../components/generic/Header/Header";
import { CircularProgress, Stack, Typography } from "@mui/material";
import Search from "../../components/generic/Search/Search";
import { useDispatch, useSelector } from "react-redux";
import Paginations from "../../components/generic/Pagination/Pagination.jsx";
import "./index.css";
import BookingCard from "./BookingCard/BookingCard";
import { useCallback, useEffect, useState } from "react";
import CustomTabing from "../../components/generic/Tabing/CustomTabing.jsx";
import {
  salesBookingListAction,
  salesBookingModalAction,
} from "../../store/action/salesBooking";
import { formatDate, debounce } from "../../utils/utils";
import { fetchParterIdsList } from "../../store/action/auth.js";
import BookingModal from "./BookingModal/BookingModal.jsx";
import NewAutoComplete from "../../components/generic/NewAutoComplete/NewAutoComplete.jsx";
import NoDataFound from "../../components/generic/NoDataFound/NoDataFound.jsx";
import DatePicker from "../../components/generic/DatePicker/DatePicker.jsx";

const BookingPage = () => {
  let searchParams = {};
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const [bookingId, setBookingId] = useState(null);
  const [isOpen, setIsOpen] = useState(false); // Manages modal open/close
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [filterByDate, setFilterByDate] = useState({
    startDate: "",
    endDate: "",
  });
  // sales bookingList
  const {
    bookingList,
    bookingBucket,
    pagination,
    loading,
    isModalLoading,
    modalData,
  } = useSelector((state) => state?.salesBooking);

  const {
    headerData,
    vehicleDetails,
    policyDetails,
    premiumDetails,
    businessType,
    policyCopyUrl,
  } = modalData || {};

  useEffect(() => {
    if (bookingId) {
      dispatch(salesBookingModalAction(bookingId));
    }
  }, [bookingId]);

  const { partnerIds } = useSelector((state) => state.auth);

  // const loading = useSelector((state) => state?.salesBooking?.loading);

  // Debounce the search input
  const debounceSearch = useCallback(
    debounce((searchValue) => {
      if (searchValue.length >= 3) {
        setDebouncedSearch(searchValue);
      } else {
        setDebouncedSearch(""); // Clear search if less than 3 characters
      }
    }, 500),
    [],
  );
  const [currentPage, setCurrentPage] = useState(pagination?.curPage || 1);
  const [currentBucket, setcurrentBucket] = useState();
  const [debouncedSearch, setDebouncedSearch] = useState("");
  //  SEARCH LIST
  const handleSearch = (evt) => {
    const searchValue = evt.target.value;
    setSearch(searchValue);
    debounceSearch(searchValue);
  };
  const handleDateChange = (date) => {
    if (date && date.length == 2) {
      setFilterByDate({ startDate: date[0], endDate: date[1] });
    }

    if (date && date.length === 0) {
      setFilterByDate({ startDate: "", endDate: "" });
    }
  };

  const handleTabChange = (newValue) => {
    if (!loading) {
      setcurrentBucket(newValue);
      setCurrentPage(1); // Reset to page 1 when bucket changes
    }
  };

  // Modal Handlers
  const handleOpenModal = (booking) => {
    setBookingId(booking?.bookingId); // Set the clicked booking
    setIsOpen(true); // Open the modal
  };

  const handleCloseModal = () => {
    setBookingId(null); // Clear the selected booking
    setIsOpen(false); // Close the modal
  };

  // page change

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    if (!partnerIds) {
      dispatch(fetchParterIdsList());
    }
  }, [partnerIds]);

  // Trigger API call to fetch the booking list for the selected bucket
  useEffect(() => {
    if (currentBucket) {
      searchParams["curBucket"] = currentBucket;
    }

    if (currentPage) {
      searchParams["curPage"] = currentPage;
    }
    if (selectedPartner) {
      searchParams["partnerId"] = selectedPartner;
    }
    if (debouncedSearch) {
      searchParams["searchQuery"] = debouncedSearch;
    }

    if (filterByDate.startDate && filterByDate.endDate) {
      searchParams["startDate"] = formatDate(filterByDate.startDate);
      searchParams["endDate"] = formatDate(filterByDate.endDate);
    }

    dispatch(salesBookingListAction(searchParams));
  }, [
    currentBucket,
    currentPage,
    selectedPartner,
    filterByDate,
    debouncedSearch,
  ]);

  return (
    <div className="main">
      <Header title="Bookings" />
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", // Full height of the viewport
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          <div className="booking-filters-item">
            <Search value={search} onChange={handleSearch} />
            <Stack direction="row" spacing={2} alignItems="center">
              <label htmlFor="partner-dropdown" className="filter-label">
                Filters :
              </label>
              <Typography sx={{ width: "175px" }}>
                <NewAutoComplete
                  options={
                    partnerIds?.map((partnerData) => ({
                      label: partnerData.partnerId + " | " + partnerData.name,
                      value: partnerData.partnerId,
                    })) || []
                  }
                  placeholder="Select Partner"
                  className="select_dropdown"
                  onChange={(selectedPartner) => {
                    setSelectedPartner(selectedPartner);
                  }}
                  isMulti={false}
                  value={selectedPartner}
                  extraStyle={{ height: "40px" }}
                />
              </Typography>
              <div>
                <DatePicker
                  className="date-picker-multi"
                  placeholder="Date Range"
                  options={{ mode: "range" }}
                  value={
                    filterByDate.startDate && filterByDate.endDate
                      ? [filterByDate.startDate, filterByDate.endDate]
                      : ""
                  }
                  onChange={handleDateChange}
                />
              </div>
            </Stack>
          </div>
          <div className="custom-tabing-wrapper">
            <CustomTabing
              value={bookingBucket?.curBucket}
              handleChange={handleTabChange}
              tabList={bookingBucket?.bucketList || []}
            />
            {bookingList?.length !== 0 ? (
              <BookingCard
                bookingList={bookingList}
                handleOpenModal={handleOpenModal}
              />
            ) : (
              <NoDataFound />
            )}
            <Paginations
              currentPage={currentPage}
              totalPage={pagination?.totalPage || 1}
              handlePageChange={handlePageChange}
            />
          </div>
          {/* Render BookingModal conditionally */}
          {isOpen && (
            <BookingModal
              handleClose={handleCloseModal}
              headerData={headerData}
              vehicleDetails={vehicleDetails}
              policyDetails={policyDetails}
              premiumDetails={premiumDetails}
              policyCopyUrl={policyCopyUrl}
              businessType={businessType}
              isModalLoading={isModalLoading}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default BookingPage;
