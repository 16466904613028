import React, { useEffect, useState } from "react";
import Header from "./VerifyDocsHeader";
import HorizontalLinearStepper from "./verifyDocsStapper";
import Heading from "../../components/generic/Heading/Heading";
import VerifyFirstDocs from "./verifyFirstDocs";
import RequestQuote from "./RequestQuote";
import { getQuoteDetailAPI } from "../../store/action/quote";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import QuoteShared from "./QuoteShared";
import RequestsChanges from "./RequestsChanges";
import "./style.css";
import QuoteChangeRequest from "./QuoteChangeRequest";
import QuoteAcceptedApproval from "./QuoteAcceptedApproval";
import PaymentLinkPending from "./PaymentLinkPending";
import AgencyDetailsSubmit from "./AgencyDetailsSubmit";
import InspectionReportPending from "./InspectionReportPending";
import { quoteRequestStateList } from "../../helpers/enums";
import QuoteSharedModule from "./QuoteSharedModule";
import { CircularProgress, Typography } from "@mui/material";

const VerifyDoc = () => {
  const data = useSelector((state) => state.quote.quoteDetails);
  const loading = useSelector((state) => state.quote.isLoading);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [quoteState, setQuoteState] = useState("");
  useEffect(() => {
    if (id) {
      dispatch(getQuoteDetailAPI(id));
    }
  }, [id]);

  useEffect(() => {
    //rerender if data changes
    setQuoteState(data?.data?.quoteState);
  }, [data]);

  const handleState = () => {
    switch (quoteState) {
      case quoteRequestStateList.DOCUMENT_VERIFICATION_STARTED:
        return <VerifyFirstDocs />;
      case quoteRequestStateList.DOCUMENT_REUPLOAD:
        return <RequestsChanges />;

      case quoteRequestStateList.SHARE_QUOTE: // inspection button here
        return <RequestQuote />;

      case quoteRequestStateList.QUOTE_SHARED:
      case quoteRequestStateList.FILL_CUSTOMER_DETAILS:
        return <QuoteShared />;

      case quoteRequestStateList.QUOTE_CHANGE_REQUEST:
        return <QuoteChangeRequest />;
      case quoteRequestStateList.QUOTE_ACCEPTED_SEND_FOR_APPROVAL:
      case quoteRequestStateList.QUOTE_ACCEPTED_WITH_INSPECTION_SEND_FOR_APPROVAL:
        return <QuoteAcceptedApproval />;
      case quoteRequestStateList.PAYMENT_LINK_PENDING:
      case quoteRequestStateList.PAYMENT_LINK_SHARED:
      case quoteRequestStateList.PAYMENT_PROOF_TAKEN:
      case quoteRequestStateList.PAYMENT_DETAILS_APPROVED:
      case quoteRequestStateList.UPLOAD_POLICY_COPY:
        return <PaymentLinkPending />;

      case quoteRequestStateList.INSPECTION_PORTAL_REQUEST_RAISE:
        return <AgencyDetailsSubmit />;
      case quoteRequestStateList.INSPECTION_DETAILS_SHARED:
        return <InspectionReportPending />;

      case quoteRequestStateList.VERIFY_KYC_DOCUMENT:
      case quoteRequestStateList.FILL_KYC_DOCUMENT:
        return <QuoteSharedModule quoteState={quoteState} />;
      default:
        return <VerifyFirstDocs />;
    }
  };

  return (
    <>
      {loading ? (
        <Typography
          component={"div"}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: "150px",
            left: "650px",
          }}
        >
          <CircularProgress />
        </Typography>
      ) : (
        <>
          <Header />
          <div className="quoteContainer">
            <div className="middleContainer">{handleState()}</div>
            <div className="rightContainer">
              <Heading text={"Activity"} type={"withborder"} />
              <div className="rightChatSection">
                <HorizontalLinearStepper id={data?.data?.header?.ticketId} />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default VerifyDoc;
