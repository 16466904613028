import React from "react";
import { Box, Typography } from "@mui/material";

const CutomeModalNoQuote = ({
  setOpen,
  title,
  subTitle,
  cancelButton,
  confirmButton,
  onClick,
  isAccepted,
}) => {
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setOpen(false);
  //   }, 4000);
  //   return () => clearTimeout(timer);
  // }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        gap: 2,
        alignItems: "center",
      }}
    >
      <Typography sx={{ fontWeight: "600", textAlign: "center", fontSize: 24 }}>
        {" "}
        {title}
      </Typography>
      <Typography
        sx={{
          fontWeight: "400",
          textAlign: "center",
          fontSize: 14,
          color: "#666666",
        }}
      >
        {" "}
        {subTitle}
      </Typography>
      <Box sx={{ display: "flex", gap: 8 }}>
        <button
          onClick={onClick}
          // variant="contained"
          style={{
            borderRadius: "8px",
            backgroundColor: isAccepted ? "rgb(9, 206, 29)" : "#DF0000",
            // width: "192px",
            color: "#fff",
            height: "40px",
            padding: "8px 12px",
            cursor: "pointer",
            minWidth: "150px",
            textAlign: "center",
          }}
        >
          {confirmButton}
        </button>
        <button
          onClick={() => setOpen(false)}
          // variant="contained"
          style={{
            borderRadius: "8px",
            backgroundColor: "rgb(6, 145, 252)",
            width: "130px",
            color: "#fff",
            height: "40px",
            padding: "8px 12px",
            cursor: "pointer",
            textAlign: "center",
          }}
        >
          {cancelButton}
        </button>
      </Box>
    </Box>
  );
};

export default CutomeModalNoQuote;
