import React from "react";

const SalesQuoteHeader = ({ data }) => {
  return (
    <div
      style={{
        backgroundColor: "#f2f2f2",
        height: "65px",
        margin: "-96px -32px 0",
        display: "flex",
        alignItems: "center",
        borderBottom: "2px solid #E6E6E6",
        // marginLeft: "-32px",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "80%",
          // justifyContent: "space-between",
          margin: "0 auto",
          gap: 32,
        }}
      >
        <div style={{ display: "flex", textAlign: "center", gap: "5px" }}>
          <h1
            style={{
              fontSize: "14px",
              fontWeight: 600,
              color: "#000",
            }}
          >
            {data?.data?.header?.registrationNumber}
          </h1>
          <h1
            style={{
              fontWeight: 400,
              fontSize: "12px",
              color: "#808080",
              alignItems: "center",
            }}
          >
            {data?.data?.header?.name}
          </h1>
        </div>

        <div style={{ display: "flex", textAlign: "center", gap: "5px" }}>
          <h1
            style={{
              fontSize: "14px",
              fontWeight: 600,
              color: "#000",
            }}
          >
            Ticket ID
          </h1>
          <h1
            style={{
              fontWeight: 400,
              fontSize: "12px",
              color: "#808080",
              alignItems: "center",
            }}
          >
            {data?.data?.header?.ticketId}
          </h1>
        </div>
      </div>
    </div>
  );
};

export default SalesQuoteHeader;
