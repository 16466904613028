import React from "react";
import { productTypeImageObj } from "../../../helpers/enums";
//import { setPolicyDeals } from "../../../store/action/grid";
import { useDispatch } from "react-redux";
import "./PolicyDealsHeader.css";
import { resetPolicyDeals } from "../../../store/action/grid";

const PolicyDealsHeader = ({ data }) => {
  const dispatch = useDispatch();

  const handleReset = () => {
    dispatch(resetPolicyDeals());
  };

  return (
    <div className="car-info-card">
      <div className="icon">{productTypeImageObj[data.product]}</div>
      <div className="info">
        {data.rtocode && (
          <div className="policy_header_row">
            <span className="label">RTO</span>
            <span className="value type">
              {" "}
              <span className="type">{data.rtocode}</span>{" "}
            </span>{" "}
          </div>
        )}
        {/*create a verttical divider */}

        {data?.insurerIds?.length > 0 && (
          <div className="policy_header_row">
            <span className="label">Insurers</span>
            <span className="value insurers type ">
              {data.insurerIds.length <= 3 ? (
                data.insurerIds.map((insurer) => (
                  <span key={insurer.value} className="type">
                    {insurer.label.split(" ")[0]}
                    {/* {index < data.insurerIds.length - 1 ? ", " : ""} */}
                  </span>
                ))
              ) : (
                <>
                  {data.insurerIds.slice(0, 3).map((insurer) => (
                    <span key={insurer.value} className="type">
                      {insurer.label.split(" ")[0]}
                      {/* {index < 2 ? " " : ""d} */}
                    </span>
                  ))}

                  <span className="type"> +{data.insurerIds.length - 3}</span>
                </>
              )}
            </span>
          </div>
        )}
        {data.policyType && (
          <div className="policy_header_row">
            <span className="label">Policy Type</span>
            <span className="value type">
              {" "}
              <span className="type">{data.policyType}</span>{" "}
            </span>
          </div>
        )}
        {data.fuelType && (
          <div className="policy_header_row">
            <span className="label">Fuel Type</span>
            <span className="value type">
              {" "}
              <span className="type">{data.fuelType}</span>{" "}
            </span>
          </div>
        )}
        {data.vehicleSubclass && (
          <div className="policy_header_row">
            <span className="label ">Vehicle SubClass</span>
            <span className="value type">
              {" "}
              <span className="type">{data.vehicleSubclass}</span>{" "}
            </span>{" "}
          </div>
        )}
        {data.make && (
          <div className="policy_header_row">
            <span className="label">Make</span>
            <span className="value type">
              {" "}
              <span className="type">{data.make}</span>{" "}
            </span>
          </div>
        )}
        {data.model && (
          <div className="policy_header_row">
            <span className="label">Model</span>
            <span className="value type">
              {" "}
              <span className="type">{data.model}</span>{" "}
            </span>{" "}
          </div>
        )}
        {data.gvw && (
          <div className="policy_header_row">
            <span className="label"> GVW Range</span>
            <span className="value type">
              {" "}
              <span className="type">{data.gvw}</span>{" "}
            </span>{" "}
          </div>
        )}
        {data.date && (
          <div className="policy_header_row">
            <span className="label">Date</span>
            <span className="value type">
              {" "}
              <span className="type">{data.date}</span>{" "}
            </span>{" "}
          </div>
        )}
      </div>
      <div className="edit">
        <button onClick={handleReset}>Edit Search</button>
      </div>
    </div>
  );
};

export default PolicyDealsHeader;
