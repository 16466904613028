import { Box, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as BimaGuideLogo } from "../../../assets/icons/Bimaguide.svg";
import { Close } from "@mui/icons-material";
const MapPolicyHeader = ({ headerOptions, handleClose }) => {
  return (
    <Stack padding={2} direction={"row"} justifyContent={"space-between"}>
      <BimaGuideLogo width="151px" height="34px" />
      <Stack direction={"row"} gap={2} alignItems={"center"}>
        <Box alignItems={"center"} display={"flex"} gap={1}>
          <Typography variant="h6" sx={{ color: "var(--color-grey-dark)" }}>
            {headerOptions?.registrationNumber}
          </Typography>
          <Typography variant="p" sx={{ color: "var(--color-grey-dark)" }}>
            {headerOptions?.product}
          </Typography>
        </Box>
        <Typography variant="p" sx={{ color: "var(--color-grey-dark)" }}>
          Ticket id - {headerOptions?.ticketId}
        </Typography>
        <Typography variant="p" sx={{ color: "var(--color-grey-dark)" }}>
          {headerOptions?.opsName &&
            headerOptions.opsName !== "" &&
            `Assigned to - ${headerOptions?.opsName}`}
        </Typography>
        <IconButton onClick={handleClose} size="small">
          <Close />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default MapPolicyHeader;
