import moment from "moment/moment";

export const formatDate = (date, format = "DD-MM-YYYY") => {
  return date ? moment(date).format(format) : "";
};

// Is Mobile device check function using device type
export const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );
};

export function debounce(func, wait) {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}
export const createNewQuoteRequestPayload = (data) => {
  // Create a new FormData object
  const formData = new FormData();

  formData.append("product", data.product);
  formData.append("vehicleType", data.vehicleType);
  formData.append("policyType", data.policyType);
  formData.append("name", data.name);
  formData.append("mobileNumber", data.mobileNumber);
  formData.append("email", data.email);
  if (data.vehicleType === "NEW") formData.append("rtoCode", data.rtoCode);
  if (data.vehicleType === "OLD")
    formData.append("registrationNumber", data.registrationNumber);
  if (data.vehicleType === "OLD")
    formData.append("registrationDate", data.registrationDate);
  if (data.idv) {
    let newIdv = data.idv.replace(/,/g, "");
    formData.append("idv", newIdv);
  }
  if (data.manufacturingYear)
    formData.append("manufacturingYear", data.manufacturingYear);
  // formData.append("insurerAddonsData", JSON.stringify(data.insurerAddonsData));
  if (data.insurerList) {
    formData.append("insurerList", JSON.stringify(data.insurerList));
  }
  if (data.addOns && data.addOns.length > 0) {
    formData.append("addons", JSON.stringify(data.addOns));
  }
  if (data.INVOICE_COPY) formData.append("INVOICE", data.INVOICE_COPY);
  // if (data.PAN) formData.append("PAN", data.PAN);
  // if (data.AADHAAR_BACK) formData.append("AADHAAR_BACK", data.AADHAAR_BACK);
  // if (data.AADHAAR_FRONT) formData.append("AADHAAR_FRONT", data.AADHAAR_FRONT);
  if (data.OTHER_DOC) formData.append("OTHER_DOC", data.OTHER_DOC);
  if (data.PERMIT) formData.append("PERMIT", data.PERMIT);
  if (data.RC) formData.append("RC", data.RC);
  if (data.isPyp != null) formData.append("isPyp", data.isPyp ? "yes" : "no");
  if (data.pypClaim != null)
    formData.append("pypClaim", data.pypClaim ? "yes" : "no");
  if (data.PYP) formData.append("PYP", data.PYP);
  if (data.pypExpiryDate) formData.append("pypExpiryDate", data.pypExpiryDate);
  if (data.ncb) formData.append("ncb", data.ncb);
  if (data.partnerId) formData.append("partnerId", data.partnerId);
  return formData;
};

export function randomNumber(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}
