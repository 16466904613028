import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import Search from "../../components/generic/Search/Search";
import Header from "../../components/generic/Header/Header";
import { useCallback, useEffect, useState } from "react";
import Paginations from "../../components/generic/Pagination/Pagination.jsx";
import { useDispatch, useSelector } from "react-redux";
import CustomTabing from "../../components/generic/Tabing/CustomTabing.jsx";
import { salesPartnerList } from "../../store/action/salesPartner.js";
import SalesPartnerTable from "./SalesPartnerTable.jsx";
import { fetchParterIdsList } from "../../store/action/auth.js";
import { debounce, formatDate } from "../../utils/utils";
import NewAutoComplete from "../../components/generic/NewAutoComplete/NewAutoComplete.jsx";
import DatePicker from "../../components/generic/DatePicker/DatePicker.jsx";

export default function SalesPartners() {
  let searchParams = {};
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const [selectedPartner, setSelectedPartner] = useState("");
  const [filterByDate, setFilterByDate] = useState({
    startDate: "",
    endDate: "",
  });
  // sales partnerList
  const { partnerList, partnerBucket, pagination, loading } = useSelector(
    (state) => state?.salesPartner,
  );
  const { partnerIds } = useSelector((state) => state.auth);

  // Debounce the search input
  const debounceSearch = useCallback(
    debounce((searchValue) => {
      if (searchValue.length >= 3) {
        setDebouncedSearch(searchValue);
      } else {
        setDebouncedSearch("");
      }
    }, 300),
    [],
  );

  const [currentPage, setCurrentPage] = useState(pagination?.curPage || 1);
  const [currentBucket, setcurrentBucket] = useState();
  const [debouncedSearch, setDebouncedSearch] = useState("");

  //  SEARCH LIST
  const handleSearch = (evt) => {
    const searchValue = evt.target.value;
    setSearch(searchValue);
    debounceSearch(searchValue);
  };
  const handleDateChange = (date) => {
    if (date && date.length == 2) {
      setFilterByDate({ startDate: date[0], endDate: date[1] });
    }

    if (date && date.length === 0) {
      setFilterByDate({ startDate: "", endDate: "" });
    }
  };

  const handleTabChange = (newValue) => {
    if (!loading) {
      setcurrentBucket(newValue);
      setCurrentPage(1);
    }
  };

  // page change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    if (!partnerIds) {
      dispatch(fetchParterIdsList());
    }
  }, [partnerIds]);

  // Trigger API call to fetch the partner list for the selected bucket
  useEffect(() => {
    if (currentBucket) {
      searchParams["curBucket"] = currentBucket;
    }

    if (currentPage) {
      searchParams["curPage"] = currentPage;
    }
    if (selectedPartner) {
      searchParams["partnerId"] = selectedPartner;
    }
    if (debouncedSearch) {
      searchParams["searchQuery"] = debouncedSearch;
    }

    if (filterByDate.startDate && filterByDate.endDate) {
      searchParams["startDate"] = formatDate(filterByDate.startDate);
      searchParams["endDate"] = formatDate(filterByDate.endDate);
    }

    dispatch(salesPartnerList(searchParams));
  }, [
    currentBucket,
    currentPage,
    selectedPartner,
    filterByDate,
    debouncedSearch,
  ]);

  return (
    <main className="main">
      <Header title="Partners " />
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <div>
          <div className="partners-filters-item">
            <Search value={search} onChange={handleSearch} />

            <Stack direction="row" spacing={2} alignItems="center">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label className="filter-label">Filters :</label>
              <Typography sx={{ width: "175px" }}>
                <NewAutoComplete
                  options={
                    partnerIds?.map((partnerData) => ({
                      label: partnerData.partnerId + " | " + partnerData.name,
                      value: partnerData.partnerId,
                    })) || []
                  }
                  placeholder="Select Partner"
                  className="select_dropdown"
                  onChange={(selectedPartner) => {
                    setSelectedPartner(selectedPartner);
                  }}
                  isMulti={false}
                  value={selectedPartner}
                  extraStyle={{ height: "40px" }}
                />
              </Typography>
              <div>
                <DatePicker
                  className="date-picker-multi"
                  placeholder="Date Range"
                  options={{
                    mode: "range",
                  }}
                  value={
                    filterByDate.startDate && filterByDate.endDate
                      ? [filterByDate.startDate, filterByDate.endDate]
                      : ""
                  }
                  onChange={handleDateChange}
                />
              </div>
            </Stack>
          </div>
          <CustomTabing
            value={partnerBucket?.curBucket}
            handleChange={handleTabChange}
            tabList={partnerBucket?.bucketList || []}
          />
          <SalesPartnerTable partnerList={partnerList} />
          <Paginations
            currentPage={currentPage}
            totalPage={pagination?.totalPage || 1}
            handlePageChange={handlePageChange}
          />
        </div>
      )}
    </main>
  );
}
