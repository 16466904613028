const initialState = {
  isFirstLoad: true,
  isLoading: false,
  optionsData: {},
  favouritePolicyDeals: [],
  policyDeals: {
    isLoading: false,
    declineDeals: [],
    specialDeals: [],
    marketDeals: [],
  },
};

const GET_OPTIONS_DATA_REQUEST = "GET_OPTIONS_DATA_REQUEST";
const GET_OPTIONS_DATA_SUCCESS = "GET_OPTIONS_DATA_SUCCESS";
const GET_OPTIONS_DATA_FAILURE = "GET_OPTIONS_DATA_FAILURE";

const GET_POLICY_DEALS_REQUEST = "GET_POLICY_DEALS_REQUEST";
const GET_POLICY_DEALS_SUCCESS = "GET_POLICY_DEALS_SUCCESS";
const GET_POLICY_DEALS_FAILURE = "GET_POLICY_DEALS_FAILURE";

const RESET_POLICY_DEALS = "RESET_POLICY_DEALS";
const RESET_OPTIONS_DATA = "RESET_OPTIONS_DATA";

export default function gridReducer(state = initialState, action) {
  switch (action.type) {
    case GET_OPTIONS_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GET_OPTIONS_DATA_SUCCESS: {
      let newOptionsData = action.data;
      if (action.searchKey === "makeSubstring" || action.searchKey === "modelSubstring") {
        newOptionsData['rtoData'] = state.optionsData['rtoData'];
        // don't clear old options
        newOptionsData['makes'] = Array.from(
          new Map(
            [
              ...(action?.data['makes'] || []),
              ...state.optionsData['makes'],
            ].map(item => [item.value, item])
          ).values()
        );

        // Combine old and new models, ensuring unique options based on 'value'
        newOptionsData['models'] = Array.from(
          new Map(
            [
              ...(action?.data['models'] || []),
              ...state.optionsData['models'],
            ].map(item => [item.value, item])
          ).values()
        );
      }
      if (action.searchKey === "rtoSubstring") {
        newOptionsData['makes'] = state.optionsData['makes'];
        newOptionsData['models'] = state.optionsData['models'];
        // don't clear old options for rto
        newOptionsData['rtoData'] = Array.from(
          new Map(
            [
              ...(action?.data['rtoData'] || []),
              ...state.optionsData['rtoData'],
            ].map(item => [item.value, item])
          ).values()
        );
      }
      return {
        ...state,
        optionsData: newOptionsData,
        isLoading: false,
      };
    }

    case GET_OPTIONS_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case GET_POLICY_DEALS_REQUEST:
      return {
        ...state,
        policyDeals: {
          ...state.policyDeals,
          isLoading: true,
        },
      };

    case GET_POLICY_DEALS_SUCCESS:
      return {
        ...state,
        isFirstLoad: false,
        policyDeals: {
          ...state.policyDeals,
          declineDeals: action.data.declineDeals,
          specialDeals: action.data.specialDeals,
          marketDeals: action.data.marketDeals,
          isLoading: false,
        },
      };

    case GET_POLICY_DEALS_FAILURE:
      return {
        ...state,
        policyDeals: {
          ...state.policyDeals,
          isLoading: false,
        },
      };

    case RESET_POLICY_DEALS:
      return {
        ...state,
        isFirstLoad: true,
        policyDeals: {
          ...initialState.policyDeals,
        },
      };

    case RESET_OPTIONS_DATA:
      return {
        ...state,
        optionsData: initialState.optionsData,
      };

    default:
      return state;
  }
}