import React from "react";
import LottiePlayer from "../../components/Lottie/LottiePlayer";
import car from "../../assets/Lottie/car.json";
import { Typography } from "@mui/material";
import SalesAgencyDetials from "./SalesAgencyDetials";
import Heading from "../../components/generic/Heading/Heading";

const SalesInspectionProgress = () => {
  return (
    <>
      <Heading text="Inspection Details" type="withborder" />
      <Typography
        component={"div"}
        sx={{
          backgroundColor: "white",
          width: "100%",
          height: "400px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: "16px",
          boxShadow: "0px 1px 6px 0px rgba(0, 0, 0, 0.25)",
          gap: "20px",
          marginTop: "20px",
        }}
      >
        {" "}
        <LottiePlayer width={"200px"} height={"200px"} image={car} />
        <Typography
          component={"div"}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "18px",
          }}
        >
          <Typography
            component={"span"}
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              color: "black",
              lineHeight: "24px",
            }}
          >
            Inspection In Progress!
          </Typography>{" "}
          <Typography
            component={"span"}
            sx={{
              fontSize: "12px",
              color: "Grey/40",
              lineHeight: "18px",
              fontWeight: "400",
            }}
          >
            Our Agency will shortly complete the inspection.
          </Typography>
        </Typography>
        {/* agency detials  */}
        <SalesAgencyDetials />
      </Typography>{" "}
    </>
  );
};

export default SalesInspectionProgress;
