import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { getSalesQuoteDetailAPI } from "../../store/action/salesQuote";
import "./style.css";
import { useState } from "react";
import Heading from "../../components/generic/Heading/Heading";
import { quoteRequestStateList } from "../../helpers/enums";
import SalesQuoteHeader from "../SalesQuoteRequest/SalesQuoteHeader";
import SalesVerticalLinearStepper from "./SalesVerticalLinearStepper";
import SalesSharedQuotes from "./SalesSharedQuotes";
import SalesVerifyDocs from "./SalesVerifyDocs";
import SalesCaseClosed from "./SalesCaseClosed";
import SalesReuploadDoc from "./SalesReuploadDoc";
// import SalesFillDetailsModel from "./SalesFillDetailsModel";
import SalesInspectionAwaited from "./SalesInspectionAwaited";
import SalesInspectionProgress from "./SalesInspectionProgress";
import SalesPaymentLinkPending from "./SalesPaymentLinkPending";
import KycDocumentUpload from "./KycDocumentUpload";
import KycVerification from "./KycVerification";
import SalesQuoteShared from "./SalesQuoteShared";
import SalesInspectionsDetails from "./SalesInspectionsDetails";
import { CircularProgress, Typography } from "@mui/material";

const SalesQuoteModal = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.salesQuote.quoteDetails);
  const loading = useSelector((state) => state.salesQuote.isLoading);

  const [quoteState, setQuoteState] = useState("");
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      dispatch(getSalesQuoteDetailAPI(id));
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    //rerender if data changes
    setQuoteState(data?.data?.quoteState);
    // eslint-disable-next-line
  }, [data]);

  const handleState = () => {
    switch (quoteState) {
      case quoteRequestStateList.DOCUMENT_VERIFICATION_STARTED:
        return <SalesVerifyDocs />;
      case quoteRequestStateList.DOCUMENT_REUPLOAD:
        return <SalesReuploadDoc />;
      case quoteRequestStateList.SHARE_QUOTE:
        return <SalesSharedQuotes />;
      case quoteRequestStateList.FILL_CUSTOMER_DETAILS:
        return <SalesInspectionsDetails />;
      // TODO
      case quoteRequestStateList.QUOTE_SHARED:
      case quoteRequestStateList.QUOTE_CHANGE_REQUEST:
      case quoteRequestStateList.QUOTE_ACCEPTED_SEND_FOR_APPROVAL:
      case quoteRequestStateList.QUOTE_ACCEPTED_WITH_INSPECTION_SEND_FOR_APPROVAL:
        // return <SalesQuoteShared />;
        return <></>;

      case quoteRequestStateList.PAYMENT_LINK_PENDING:
      case quoteRequestStateList.PAYMENT_LINK_SHARED:
      case quoteRequestStateList.PAYMENT_PROOF_TAKEN:
      case quoteRequestStateList.PAYMENT_DETAILS_APPROVED:
      case quoteRequestStateList.UPLOAD_POLICY_COPY:
        return <SalesPaymentLinkPending />;

      case quoteRequestStateList.INSPECTION_PORTAL_REQUEST_RAISE:
        return <SalesInspectionAwaited />;
      case quoteRequestStateList.INSPECTION_DETAILS_SHARED:
        return <SalesInspectionProgress />;

      case quoteRequestStateList.FILL_KYC_DOCUMENT:
        return <KycDocumentUpload />;
      case quoteRequestStateList.VERIFY_KYC_DOCUMENT:
        return <KycVerification />;
      case quoteRequestStateList.CASE_CLOSED:
        return <SalesCaseClosed />;
      default:
        return <SalesVerifyDocs />;
    }
  };

  const handleQuoteRender = () => {
    let statesList = [
      quoteRequestStateList.DOCUMENT_VERIFICATION_STARTED,
      quoteRequestStateList.DOCUMENT_REUPLOAD,
      quoteRequestStateList.SHARE_QUOTE,
      quoteRequestStateList.CASE_CLOSED,
    ];
    return !statesList.includes(quoteState);
  };
  return (
    <>
      {loading ? (
        <Typography
          component={"div"}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: "150px",
            left: "650px",
          }}
        >
          <CircularProgress />
        </Typography>
      ) : (
        <div>
          {" "}
          {/* Added this <div> as a single root container for the components */}
          <SalesQuoteHeader data={data} />
          <div className="quoteContainer">
            <div className="middleContainer">
              {handleQuoteRender() && <SalesQuoteShared />}
              {handleState()}
            </div>
            <div className="rightContainer">
              <Heading text={"Activity"} type={"withborder"} />
              <div className="rightChatSection">
                <SalesVerticalLinearStepper id={data?.data?.header?.ticketId} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SalesQuoteModal;
