import React, { useEffect, useState } from "react";
import Header from "../../components/generic/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import "flatpickr/dist/plugins/monthSelect/style.css";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect/index";
import DropDown from "../../components/generic/DropDown/DropDown";
import SalesManagerBookingTable from "./SalesManagerBookingTable";
import { salesManagerListAction } from "../../store/action/salesManagerBooking";
import Paginations from "../../components/generic/Pagination/Pagination";
import { insurerIdsAction } from "../../store/action/insurers";
import EmployeeBreadcrumbs from "../../components/EmployeeBreadcrumbs/EmployeeBreadcrumbs";
import NoDataFound from "../../components/generic/NoDataFound/NoDataFound";
import DatePicker from "../../components/generic/DatePicker/DatePicker";

const SalesManagerBookings = () => {
  const dispatch = useDispatch();
  const [filterByDate, setFilterByDate] = useState();
  const [businessType, setBusinessType] = useState("");
  const {
    salesManagerBookingList,
    pagination,
    loading,
    hierarchyList,
    hierarchy,
  } = useSelector((state) => state?.salesManagerBooking);
  const { insurerIdList } = useSelector((state) => state?.insurers);
  const [selectedInsurer, setSelectedInsurer] = useState();
  const [currentPage, setCurrentPage] = useState(pagination?.curPage || 1);
  const [selectedHierarchy, setSelectedHierarchy] = useState([]);

  const handleDateChange = (selectedDates) => {
    const selectedDate = selectedDates[0];
    setFilterByDate(selectedDate);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleRowClick = (employee) => {
    setSelectedHierarchy([{ employeeId: employee.employeeeId }]);
  };
  const handleBreadcrumbClick = (employee) => {
    setSelectedHierarchy([{ employeeId: employee.employeeId }]);
  };

  useEffect(() => {
    dispatch(insurerIdsAction());
  }, []);

  useEffect(() => {
    let searchParams = {};
    if (currentPage) {
      searchParams["curPage"] = currentPage;
    }
    if (businessType) {
      searchParams["businessType"] = businessType;
    }
    if (selectedInsurer) {
      searchParams["insurerId"] = selectedInsurer;
    }
    if (filterByDate) {
      searchParams["month"] = filterByDate.getMonth().toString();
      searchParams["year"] = filterByDate.getFullYear().toString();
    }
    if (hierarchy) {
      searchParams["hierarchy"] = hierarchy;
    }
    if (selectedHierarchy.length > 0) {
      searchParams["curManagerId"] = selectedHierarchy[0].employeeId; // Use employeeId from selectedHierarchy
    }
    dispatch(salesManagerListAction(searchParams));
  }, [
    currentPage,
    filterByDate,
    businessType,
    selectedInsurer,
    selectedHierarchy,
  ]);

  return (
    <div>
      <div className="Heading">
        <Header title={"Bookings"} />
        <Typography component={"div"} sx={{ paddingBottom: "20px" }}>
          <EmployeeBreadcrumbs
            hierarchyList={hierarchyList}
            selectedHierarchy={selectedHierarchy}
            handleBreadcrumbClick={handleBreadcrumbClick}
          />
        </Typography>
      </div>
      <div className="list-wrapper">
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          justifyContent="end"
          margin="10px 10px"
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <label htmlFor="insurer-dropdown" className="filter-label">
              Filters :
            </label>
            <DropDown
              placeholder="Select Insurer"
              value={
                insurerIdList.find((insurer) => insurer.key === selectedInsurer)
                  ?.value || ""
              }
              onChange={(e) => {
                const selected = insurerIdList.find(
                  (insurer) => insurer.value === e.target.value,
                );

                setSelectedInsurer(selected ? selected.key : "");
              }}
              data={[...insurerIdList.map((insurer) => insurer.value)]}
              width={160}
            />

            <DropDown
              placeholder="Select Business"
              value={businessType}
              onChange={(e) =>
                setBusinessType(
                  e.target.value === "No Business Type" ? "" : e.target.value,
                )
              }
              data={["NEW", "RENEWAL", "ROLLOVER", "PORT", "No Business Type"]}
              width={160}
            />

            <div>
              <DatePicker
                placeholder=" Month - Year"
                width={174}
                options={{
                  dateFormat: "m-Y",
                  maxDate: new Date(),
                  mode: "single",
                  // allowInput: true,
                  disableMobile: true,
                  plugins: [
                    new monthSelectPlugin({
                      shorthand: true,
                      dateFormat: "F Y",
                      altFormat: "F Y",
                    }),
                  ],
                }}
                style={{ width: "100%" }}
                onChange={handleDateChange}
                value={filterByDate}
              />
            </div>
          </Stack>
        </Stack>
        <div className="booking-table">
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <SalesManagerBookingTable
              salesManagerBookingList={salesManagerBookingList}
              onRowClick={handleRowClick}
            />
          )}
        </div>
      </div>
      {salesManagerBookingList.length === 0 ? (
        <div>
          <NoDataFound />
        </div>
      ) : (
        ""
      )}
      <Paginations
        currentPage={currentPage}
        totalPage={pagination?.totalPage || 1}
        handlePageChange={handlePageChange}
      />
    </div>
  );
};

export default SalesManagerBookings;
