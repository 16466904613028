import axios from "../api/axios";
import { getRequiredDocsDataURL, getSalesMappingListURL } from "../api";
import { message } from "../../components/generic/SnakeAlert/SnakeAlert";

export const SALES_MAPPING_LOADING = "SALES_MAPPING_LOADING";
export const GET_SALES_MAPPING_LIST = "GET_SALES_MAPPING_LIST";
export const GET_UPLOAD_DOCS_LIST = "GET_UPLOAD_DOCS_LIST";
export const RESET_SALES_MAPPING = "RESET_SALES_MAPPING";

export const getSalesMappingListAPI = (search) => async (dispatch) => {
  try {
    dispatch({ type: SALES_MAPPING_LOADING, data: true });
    let response = await axios.get(getSalesMappingListURL, {
      params: search,
    });
    if (response.status === 200 && response.data && response.data.data) {
      dispatch({ type: GET_SALES_MAPPING_LIST, data: response.data.data });
    }
  } catch (error) {
    throw error.message;
  } finally {
    dispatch({ type: SALES_MAPPING_LOADING, data: false });
  }
};
export const getRequiredDocsData = (mappingId) => async (dispatch) => {
  try {
    let response = await axios.get(getRequiredDocsDataURL, {
      params: { mappingId },
    });
    if (response.status === 200 && response.data && response.data.data) {
      dispatch({ type: GET_UPLOAD_DOCS_LIST, data: response.data.data });
    }
  } catch (error) {
    message.error(error.message);
  }
};
