import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import { Controller } from "react-hook-form";
import FileSelector from "../FileSelector/FileSelector";
import { Box, Divider, Typography } from "@mui/material";

const DocFields = ({
  errors,
  control,
  activeHeader,
  isNewVehicle,
  previousPolicyFlag,
  resetField,
}) => {
  const [docFields, setDocFields] = useState([]);

  const fileTypesToUpload = ["pdf", "jpeg", "jpg", "png"];

  const newVehicleDocFields = [
    {
      name: "INVOICE_COPY",
      label: "Invoice Copy",
      id: "fileInput_INVOICE_COPY",
      fileTypes: fileTypesToUpload,
    },
  ];

  const busDocFields = [
    {
      name: "PERMIT",
      label: "Permit Document",
      id: "fileInput_Permit",
      fileTypes: fileTypesToUpload,
      optional: true,
    },
  ];

  const oldVehicleDocFields = [
    {
      name: "RC",
      label: "RC Copy",
      id: "fileInput_RC",
      fileTypes: fileTypesToUpload,
      error: errors.RC,
    },
  ];

  const pyp = {
    name: "PYP",
    label: "PYP Copy",
    id: "fileInput_PYP",
    fileTypes: fileTypesToUpload,
  };

  const getDocFields = () => {
    let docFields = [];
    if (isNewVehicle) {
      resetField("RC", null);
      resetField("PYP", null);
      docFields = [...newVehicleDocFields, ...docFields];
    } else {
      resetField("INVOICE_COPY", "");
      if (previousPolicyFlag[0] === "Yes") {
        docFields = [pyp, ...docFields];
      } else if (previousPolicyFlag[0] === "No") {
        resetField("PYP", null);
        docFields = docFields.filter((docField) => docField.name !== "PYP");
      }
      docFields = [...oldVehicleDocFields, ...docFields];
    }

    if (activeHeader?.key === "BUS") {
      docFields = [...docFields, ...busDocFields];
    } else {
      resetField("PERMIT", null);
      docFields = docFields.filter((docField) => docField.name !== "PERMIT");
    }

    return docFields;
  };

  useEffect(() => {
    setDocFields(getDocFields());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeHeader, isNewVehicle, previousPolicyFlag]);

  return (
    <Fragment>
      <Box display={"flex"} flexDirection={"column"} gap={2}>
        <Divider>
          <Typography
            variant="body2"
            sx={{
              color: "#757575",
              fontWeight: "300",
              fontSize: "12px",
              textTransform: "uppercase",
            }}
          >
            Upload Docs
          </Typography>
        </Divider>
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={"10px"}
          marginBottom={"32px"}
        >
          {docFields.map((docField, index) => {
            return (
              <div
                className="doc_field_container"
                key={`docField_${index}`}
                style={{ marginTop: "10px" }}
              >
                <div className="doc_field">
                  <div>
                    <span className="doc_name"> {docField.label}</span>{" "}
                    <span className="text-danger">
                      {docField.optional ? "" : "*"}
                    </span>
                    &nbsp;
                    <span className="doc_type">
                      Only {docField.fileTypes.join(", ")}
                    </span>
                  </div>
                  <Controller
                    key={docField.id}
                    id={docField.id}
                    name={docField.name}
                    control={control}
                    render={({ field }) => (
                      <FileSelector
                        id={docField.id}
                        file={field.value}
                        field={field}
                        fileTypes={docField.fileTypes}
                        handleFileChange={(file) => field.onChange(file)}
                      />
                    )}
                  />
                </div>
                {errors[docField.name] && (
                  <div className="error_class text-start mb-5">
                    {errors[docField.name].message}
                  </div>
                )}
              </div>
            );
          })}
        </Box>
      </Box>
    </Fragment>
  );
};

export default DocFields;
