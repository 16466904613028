import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Avatar, Stack } from "@mui/material";
import { AvatarColors } from "../../assets/constant";

const SalesManagerPartnerTable = ({ salesManagerPartnersList, onRowClick }) => {
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#F2F2F2", height: "42px" }}>
              {["Reportees", "Partners"].map((header) => (
                <TableCell
                  key={header}
                  sx={{
                    fontWeight: "500",
                    lineHeight: "18px",
                    fontSize: "14px",
                    color: "#181818",
                  }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {salesManagerPartnersList.map((data, index) => (
              <TableRow
                className="row-hover"
                key={index} // FIXED: Added `key` prop
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
                onClick={() => onRowClick(data)} // Handle row click
              >
                <TableCell sx={{ height: "90px" }}>
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    sx={{ position: "relative" }}
                  >
                    <Avatar
                      className="avatorEffect "
                      sx={{
                        bgcolor: AvatarColors(
                          data.employeeName || data.employeeLogo,
                        ), // Generate color based on name or logo
                        width: "48px",
                        height: "48px",
                        fontWeight: "500",
                        fontSize: "16px",
                      }}
                    >
                      {data.employeeLogo}
                    </Avatar>
                    <span
                      style={{
                        width: "16px",
                        height: "16px",
                        backgroundColor: "#4FE61A",
                        borderRadius: "50%",
                        position: "absolute",
                        left: "22px",
                        bottom: "1px",
                      }}
                    ></span>
                    <div>
                      <span
                        style={{
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "black",
                          fontWeight: "600",
                        }}
                      >
                        {data.employeeName}
                      </span>
                      <br />
                      <span
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                          lineHeight: "18px",
                          color: "#0691FC",
                        }}
                      >
                        {data.employeeeId}
                      </span>
                    </div>
                  </Stack>
                </TableCell>

                {/* partner Cell */}
                <TableCell>
                  <div
                    style={{
                      width: "230px",
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      gap: "7px",
                    }}
                  >
                    {data.partnersData && data.partnersData.length > 0 ? (
                      data.partnersData.map((partner, idx) => (
                        <span
                          className="HoverKeyEffect"
                          key={idx}
                          style={{
                            backgroundColor: "#F2F2F2",
                            padding: "2px 4px",
                            borderRadius: "6px",
                            border: "1px solid #E6E6E6",
                            height: "22px",
                            textAlign: "center",
                            fontSize: "11px",
                            lineHeight: "16px",
                            fontWeight: "400",
                            marginBottom: "5px",
                            color: "#666666",
                          }}
                        >
                          {partner.key}
                          <span
                            style={{
                              fontSize: "12px",
                              lineHeight: "18px",
                              fontWeight: "500",
                              color: "#00223C",
                              marginLeft: "6px",
                            }}
                          >
                            {partner.value}
                          </span>
                        </span>
                      ))
                    ) : (
                      <span
                        style={{
                          fontSize: "12px",
                          color: "#888",
                          fontStyle: "italic",
                          fontWeight: "500",
                        }}
                      >
                        No Partner Found
                      </span>
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default SalesManagerPartnerTable;
